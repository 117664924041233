import clsx from 'clsx';
import React from 'react';

import cn from './style.module.sass';

export interface ITinaButton {
    type?: 'button' | 'icon';
    /**
     * Содержимое кнопки
     */
    label: string | JSX.Element;
    /**
     * Необязательный обработчик щелчков
     */
    onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
    /**
     * Дополнительный класс
     */
    customClass?: string;
    primary?: boolean;
    small?: boolean;
    margin?: boolean;
    grow?: boolean;
    open?: boolean;
    busy?: boolean;
    disabled?: boolean;
    buttonType?: React.ButtonHTMLAttributes<string>['type'];
}

const TinaButton: React.FC<ITinaButton> = ({
    label,
    onClick,
    customClass,
    busy,
    open,
    small,
    margin,
    grow,
    primary,
    disabled,
    type = 'button',
    buttonType = 'button',
}) => (
    <button
        /* eslint-disable-next-line react/button-has-type */
        type={buttonType}
        onClick={onClick}
        className={clsx(
            cn.btn,
            type === 'icon' && cn.buttonIcon,
            primary && cn.primary,
            small && cn.small,
            margin && cn.margin,
            grow && cn.grow,
            open && cn.open,
            busy && cn.busy,
            disabled && cn.disabled,
            customClass
        )}
    >
        {label}
    </button>
);

export default TinaButton;
