import clsx from 'clsx';
import React, { useState } from 'react';

import { DownloadIcon } from '@/components/UI/DownloadIcon';
import { FileIcon } from '@/components/UI/FileIcon';
import { FolderIcon } from '@/components/UI/FolderIcon';
import { LoadingDots } from '@/components/UI/icons/tina/LoadingDots';
import { TrashIcon } from '@/components/UI/icons/tina/TrashIcon';
import { VideoIcon } from '@/components/UI/icons/tina/VideoIcon';
import TinaButton from '@/components/UI/TinaButton';
import { IMediaItem } from '@/types/tina/media/item';
import { nextApiRequest } from '@/utils/request';

import cn from './style.module.sass';

interface IMediaItemProps {
    item: IMediaItem;
    onClick: (item: IMediaItem) => void;
    allowDelete?: boolean;
    shouldSelect?: (item: IMediaItem) => void | boolean;
    onSelect?: (item: IMediaItem) => void;
    onDelete?: (item: IMediaItem) => void;
}

function Icon({ type, src }: Pick<IMediaItem, 'type' | 'src'>) {
    if (src?.includes('.webm')) {
        return <VideoIcon />;
    }

    if (type === 'dir') {
        return <FolderIcon />;
    }

    return <FileIcon />;
}

export const MediaItem: React.FC<IMediaItemProps> = ({
    item,
    onClick,
    onSelect,
    onDelete: onDeleteHandler,
    allowDelete,
    shouldSelect,
}) => {
    const [isDownloading, setIsDownloading] = useState(false);

    const onDelete = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        onDeleteHandler(item);
    };

    const onItemClick = () => {
        onClick(item);
    };

    const onDownload = async () => {
        setIsDownloading(true);
        const response = await nextApiRequest.get(`/image/download?url=${item.src}`, {
            responseType: 'blob',
        });

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', item.name);
        link.click();
        setIsDownloading(false);
    };

    const downloadIcon = isDownloading ? <LoadingDots /> : <DownloadIcon />;

    return (
        <div role="presentation" className={clsx(cn.item, item.type === 'dir' && cn.folder)} onClick={onItemClick}>
            <div className={cn.preview}>
                {item.previewSrc ? (
                    <img src={item.previewSrc} alt={item.name} />
                ) : (
                    <Icon type={item.type} src={item.src} />
                )}
            </div>
            <div className={cn.name}>{item.name}</div>
            <div className={cn.buttons}>
                {shouldSelect && item.type === 'file' && <TinaButton label="Выбрать" onClick={() => onSelect(item)} />}
                {allowDelete && (
                    <TinaButton
                        small
                        type="icon"
                        onClick={onDelete}
                        label={(<TrashIcon />) as unknown as JSX.Element}
                    />
                )}
                {item.type === 'file' && (
                    <TinaButton
                        customClass={isDownloading && cn.loadingDots}
                        small
                        type="icon"
                        onClick={onDownload}
                        label={downloadIcon}
                    />
                )}
                <div id={`canvas-container${item.id}`} />
            </div>
        </div>
    );
};
