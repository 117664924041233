import { IData, IResponse } from '@/types/data';
import { nextApiRequest } from '@/utils/request';

export const updatePageValues = async (
    values: IData,
    path: string,
    cms: unknown,
    initialData: unknown,
    geoGroup?: string
) => {
    const geoPart = geoGroup ? `&geoGroup=${geoGroup}` : '';

    // const response: IResponse = await nextApiRequest(`/page?path=${path}&isAdmin=true${geoPart}`);
    //
    // const {
    //     data: { data: currentData },
    // } = response;
    //
    // if (JSON.stringify(currentData) !== JSON.stringify(initialData)) {
    //     (cms as { alerts: { error: (string) => void } })?.alerts.error(
    //         'К сожалению данные на странице изменились. Пожалуйста, перезагрузите страницу и попробуйте снова'
    //     );
    //     throw new Error('К сожалению данные на странице изменились');
    // }

    try {
        const {
            data: { errors, data: currentData },
        }: IResponse = await nextApiRequest.post('/update-page-info', {
            path,
            values,
            geoGroup,
        });

        if (errors?.length) {
            (cms as { alerts: { error: (string) => void } })?.alerts.error(errors[0].message);
            throw new Error();
        }

        document.dispatchEvent(
            new CustomEvent('save-click', {
                detail: {
                    label: 'save-click',
                },
            })
        );
        console.log('save-click');
    } catch (e) {
        console.error(e);
    }
};
