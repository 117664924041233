import React from 'react';

import { TinaSidebarAnchorLinks } from '@/components/blocks/sidebar/SidebarAnchorLinks/index.tina';
import { mockSidebarAnchorLinksBlockDataTina } from '@/data/blocks/sidebar/sidebarAnchorLinks';

export const sidebarAnchorLinksBlock = {
    Component: props => <TinaSidebarAnchorLinks {...props} />,
    template: {
        label: 'Блок ссылок-якорей',
        defaultItem: () => mockSidebarAnchorLinksBlockDataTina,
        fields: [
            {
                label: 'Список элементов',
                name: 'list',
                component: 'group-list',
                itemProps: (item: { linkName: string }, index: number) => ({
                    key: index,
                    label: item.linkName,
                }),
                defaultItem: () => mockSidebarAnchorLinksBlockDataTina.list[0],
                fields: [
                    {
                        component: 'text',
                        name: 'linkName',
                        label: 'Название ссылки',
                    },
                    {
                        component: 'text',
                        name: 'linkHref',
                        label: 'Ссылка',
                    },
                ],
            },
            {
                name: 'isRoollable',
                component: 'toggle',
                label: 'Эффект сворачивания',
            },
            {
                name: 'rollBlockName',
                component: 'text',
                label: 'Название сворачиваемого блока',
            },
        ],
    },
};
