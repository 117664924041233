import clsx from 'clsx';
import path from 'path';
import React from 'react';

import { LeftArrowIcon } from '@/components/UI/icons/tina/LeftArrowIcon';

import cn from './style.module.sass';

interface BreadcrumbProps {
    directory?: string;
    setDirectory: (directory: string) => void;
}

export const MediaBreadcrumbs: React.FC<BreadcrumbProps> = ({ directory = '', setDirectory }) => {
    const formattedDirectory = directory.replace(/^\/|\/$/g, '');

    const setInitialDirectory = () => {
        setDirectory('');
    };

    const setPrevDirectory = () => {
        let prevDir = path.dirname(formattedDirectory);

        if (prevDir === '.') {
            prevDir = '';
        }

        setDirectory(prevDir);
    };

    const setCurrentDirectory = (index: number, array: string[]) => {
        const currentDir = array.slice(0, index + 1).join('/');

        setDirectory(currentDir);
    };

    return (
        <div className={clsx(cn.wrapper, formattedDirectory !== '' && cn.showArrow)}>
            <span role="presentation" onClick={setPrevDirectory}>
                <LeftArrowIcon />
            </span>
            <button type="button" onClick={setInitialDirectory}>
                Media
            </button>
            {formattedDirectory &&
                formattedDirectory?.split('/').map((part, index, parts) => (
                    <button
                        key={index}
                        type="button"
                        onClick={() => {
                            setCurrentDirectory(index, parts);
                        }}
                    >
                        {part}
                    </button>
                ))}
        </div>
    );
};
