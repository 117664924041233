export const mockSidebarAuthorBlockData = {
    img: '/img-next/png/aboutPB/personQuote.png',
    personName: 'Дмитрий Руденко',
    personPosition: 'президент-председатель правления &laquo;Почта Банка&raquo;.',
};

export const mockSidebarAuthorBlockDataTina = {
    img: '',
    personName: 'Имя автора',
    personPosition: 'Позиция',
};
