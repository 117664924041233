export const mockSidebarDocsBlockData = {
    list: [
        {
            title: 'Действующие документы',
            button: {
                title: 'Недействующие документы',
                link: '#',
            },
            documents: [
                {
                    documentName: 'Сборник тарифов для физических лиц (действует с 09.07.2021)',
                    document: '#',
                    documentType: 'pdf',
                },
                {
                    documentName:
                        'Тарифы на совершение переводов денежных средств в целях оплаты услуг жилищно-коммунального хозяйства на сайте ГИС ЖКХ',
                    document: '#',
                    documentType: 'pdf',
                },
                {
                    documentName: 'Тарифы на совершение переводов денежных средств через Почта Банк Онлайн',
                    document: '#',
                    documentType: 'pdf',
                },
                {
                    documentName: 'Тарифы на совершение платежей и переводов в банкоматах/терминалах банка',
                    document: '#',
                    documentType: 'pdf',
                },
            ],
        },
        {
            title: 'Нормативы',
            documents: [
                {
                    documentName: 'Сборник тарифов для физических лиц (действует с 09.07.2021)',
                    document: '#',
                    documentType: 'pdf',
                },
                {
                    documentName:
                        'Тарифы на совершение переводов денежных средств в целях оплаты услуг жилищно-коммунального хозяйства на сайте ГИС ЖКХ',
                    document: '#',
                    documentType: 'pdf',
                },
            ],
        },
    ],
};

export const mockSidebarDocsBlockDataTina = {
    list: [
        {
            title: 'Заголовок',
            button: {
                title: 'Дополнительная ссылка',
                link: '#',
            },
            documents: [
                {
                    documentName: 'Заголовок',
                    document: '#',
                },
            ],
        },
        {
            title: 'Заголовок',
            button: {
                title: 'Дополнительная ссылка',
                link: '#',
            },
            documents: [
                {
                    documentName: 'Заголовок',
                    document: '#',
                },
            ],
        },
    ],
};
