import React from 'react';

import { TinaRSSSubscription } from '@/components/blocks/sidebar/RSSSubscription/index.tina';
import { mockRSSSubscriptionDataTina } from '@/data/blocks/sidebar/rssSubscription';

export const rssSubscriptionBlock = {
    Component: props => <TinaRSSSubscription {...props} />,
    template: {
        label: 'RSS-подписка',
        defaultItem: () => mockRSSSubscriptionDataTina,
        fields: [
            {
                name: 'title',
                component: 'text',
                label: 'Заголовок',
            },
            {
                name: 'description',
                component: 'textarea',
                label: 'Описание',
            },
            {
                name: 'successTitle',
                component: 'textarea',
                label: 'Оповещение в итоговом окне',
            },
        ],
    },
};
