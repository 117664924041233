import React from 'react';

export function ReorderIcon() {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="inherit" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.3012 6.23952L11.0607 10.4801L10 9.41943L14.2406 5.17886C14.9213 4.49816 16.0233 4.48258 16.7196 5.17886L20.9602 9.41943L19.8995 10.4801L15.6589 6.23952C15.5561 6.13671 15.4039 6.13689 15.3012 6.23952Z"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.6988 25.8732L19.9393 21.6326L21 22.6933L16.7594 26.9339C16.0787 27.6146 14.9767 27.6301 14.2804 26.9339L10.0398 22.6933L11.1005 21.6326L15.3411 25.8732C15.4439 25.976 15.5961 25.9758 15.6988 25.8732Z"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.6569 27.1127V17.799L16.1569 17.799V27.1127L14.6569 27.1127Z"
            />
            <path fillRule="evenodd" clipRule="evenodd" d="M14.6569 14.3137V5L16.1569 5V14.3137L14.6569 14.3137Z" />
        </svg>
    );
}
