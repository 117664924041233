import React from 'react';

import Breadcrumbs from '@/components/Breadcrumbs/index';
import { useTinaLibStore } from '@/hooks/useTina';
import { breadcrumbsConfig } from '@/tina/groups/breadcrumbs/index.tina';
import { IBreadcrumbs } from '@/types/breadcrumbs';

const TinaBreadcrumbs: React.FC<IBreadcrumbs> = props => {
    const { TinaInline } = useTinaLibStore();
    const { InlineGroup } = TinaInline;
    const { isActive } = props;

    if (!isActive) return null;

    return (
        <InlineGroup focusRing={{ offset: 0 }} insetControls name="breadcrumbs" fields={breadcrumbsConfig.fields}>
            <Breadcrumbs {...props} />
        </InlineGroup>
    );
};

export default TinaBreadcrumbs;
