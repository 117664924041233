import React, { ChangeEvent, forwardRef } from 'react';

import cn from './style.module.sass';

export interface ITinaInput {
    /**
     * Плейсхолдер
     */
    placeholder?: string;
    /**
     * Обработчик onChange
     */
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
    /**
     * Значение
     */
    value?: string | number;
    [name: string]: unknown;
}

const TinaInput: React.FC<ITinaInput> = forwardRef<HTMLInputElement, ITinaInput>(
    ({ placeholder, value, onChange, ...rest }, ref) => (
        <input
            ref={ref}
            className={cn.input}
            type="text"
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            {...rest}
        />
    )
);

export default TinaInput;
