import { observer } from 'mobx-react-lite';
import dynamic from 'next/dynamic';
import React from 'react';

// eslint-disable-next-line import/no-cycle
import DefaultLayout from '@/components/blocks/tabsBlock/layouts/DefaultLayout';
import { useAppStore } from '@/context/AppStoreContext';
import { ITabGroup } from '@/types/tabsBlock';

interface IProps {
    tabs: ITabGroup[];
}

const TinaLayout = dynamic(() => import('@/components/blocks/tabsBlock/layouts/TinaLayout'));

const TabsLayout: React.FC<IProps> = observer(({ tabs }) => {
    const [isTinaView] = useAppStore(store => store.isTinaView);

    if (isTinaView) return <TinaLayout tabs={tabs} />;

    return <DefaultLayout tabs={tabs} />;
});

export default TabsLayout;
