import React from 'react';

import { TinaSidebarDocs } from '@/components/blocks/sidebar/SidebarDocs/index.tina';
import { mockSidebarDocsBlockDataTina } from '@/data/blocks/sidebar/sidebarDocs';

export const sidebarDocsBlock = {
    Component: props => <TinaSidebarDocs {...props} />,
    template: {
        label: 'Документы',
        defaultItem: () => mockSidebarDocsBlockDataTina,
        fields: [
            {
                name: 'text',
                component: 'text',
                label: 'Заголовок',
            },
            {
                name: 'link',
                component: 'text',
                label: 'Ссылка',
            },
            {
                label: 'Список элементов',
                name: 'list',
                component: 'group-list',
                itemProps: (item: { title: string }, index: number) => ({
                    key: index,
                    label: item.title,
                }),
                defaultItem: () => mockSidebarDocsBlockDataTina.list[0],
                fields: [
                    {
                        component: 'text',
                        name: 'title',
                        label: 'Заголовок',
                    },
                    {
                        label: 'Список элементов',
                        name: 'documents',
                        component: 'group-list',
                        itemProps: (item: { documentName: string }, index: number) => ({
                            key: index,
                            label: item.documentName,
                        }),
                        defaultItem: () => mockSidebarDocsBlockDataTina.list[0].documents[0],
                        fields: [
                            {
                                component: 'text',
                                name: 'documentName',
                                label: 'Заголовок',
                            },
                            {
                                component: 'text',
                                name: 'document',
                                label: 'Ссылка',
                            },
                            {
                                component: 'text',
                                name: 'permanentDocument',
                                label: 'Ссылка для редиректа',
                            },
                        ],
                    },
                    {
                        name: 'button',
                        component: 'group',
                        label: 'Дополнительная ссылка',
                        fields: [
                            {
                                name: 'link',
                                component: 'text',
                                label: 'Ссылка',
                            },
                            {
                                name: 'title',
                                component: 'text',
                                label: 'Заголовок',
                            },
                        ],
                    },
                ],
            },
        ],
    },
};
