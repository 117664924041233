import { IHeader } from '@/types/header';

export const mockHeaderData: IHeader = {
    headerCollapsedMenu: [
        {
            title: 'Кредиты',
            sections: [
                {
                    title: 'ВСЕ КРЕДИТЫ',
                    link: 'https://www.pochtabank.ru/service/loans_1',
                    isMain: true,
                    withBorder: true,
                    type: 'links',
                    links: [
                        {
                            label: 'Кредит до 3,9%',
                            href: '/service/cashcredit',
                            typeMark: 'isMarked',
                        },
                        {
                            label: 'Рефинансирование кредитов',
                            href: 'https://www.pochtabank.ru/service/refinans',
                            typeMark: 'isNoMarked',
                        },
                        {
                            label: 'Кредит «Почтовый Экспресс»',
                            href: 'https://www.pochtabank.ru/service/loans_1',
                            typeMark: 'isNoMarked',
                        },
                        {
                            label: 'Кредиты для пенсионеров',
                            href: 'https://www.pochtabank.ru/service/pension',
                            typeMark: 'isNoMarked',
                        },
                        {
                            label: 'Кредит на строительство',
                            href: 'https://www.pochtabank.ru/service/bistr_doma',
                            typeMark: 'isNoMarked',
                        },
                        {
                            label: 'Покупки в кредит',
                            href: 'https://www.pochtabank.ru/service/credit_goods',
                            typeMark: 'isNoMarked',
                        },
                        {
                            label: 'Кредит на образование',
                            href: 'https://www.pochtabank.ru/service/education',
                            typeMark: 'isNoMarked',
                        },
                    ],
                },
                {
                    title: 'Удобные опции',
                    isMain: false,
                    withBorder: true,
                    type: 'links',
                    links: [
                        {
                            label: 'Онлайн-заявка на кредит',
                            href: 'https://www.pochtabank.ru/service/cashcredit#credit-issue-form-container',
                            typeMark: 'isNoMarked',
                        },
                        {
                            label: 'Бесплатная доставка',
                            href: 'https://www.pochtabank.ru/service/cashcredit#benefits',
                            typeMark: 'isNoMarked',
                        },
                        {
                            label: 'Cпособы погашения кредита',
                            href: 'https://www.pochtabank.ru/service/loans_1',
                            typeMark: 'isNoMarked',
                        },
                        {
                            label: 'Вопросы и ответы по кредиту наличными для действующих клиентов',
                            href: 'https://www.pochtabank.ru/service/loans_1',
                            typeMark: 'isNoMarked',
                        },
                    ],
                },
                {
                    title: 'Специальные условия',
                    isMain: false,
                    withBorder: false,
                    type: 'card',
                    card: {
                        title: 'Кредит',
                        desc: 'до <span>3,9%</span> годовых',
                        subDesc: 'решение за 1 мин',
                        href: 'https://www.pochtabank.ru/service/cashcredit',
                        button: 'Подробнее',
                        buttonType: 'white',
                        img: '/img-next/png/cards/card-decor-1.png',
                        isPWA: false,
                    },
                },
            ],
        },

        {
            title: 'Карты',
            sections: [
                {
                    title: 'ВСЕ КРЕДИТНЫЕ КАРТЫ',
                    isMain: true,
                    withBorder: true,
                    type: 'links',
                    links: [
                        {
                            label: 'Вездедоход',
                            href: 'https://www.pochtabank.ru/service/cards/vddohod',
                            typeMark: 'isMarked',
                        },
                        {
                            label: 'Зеленый мир',
                            href: 'https://www.pochtabank.ru/',
                            typeMark: 'isNoMarked',
                        },
                        {
                            label: 'Все кредитные карты',
                            href: 'https://www.pochtabank.ru/service/cards',
                            typeMark: 'isNoMarked',
                        },
                    ],
                },
                {
                    title: 'ВСЕ ДЕБЕТОВЫЕ КАРТЫ',
                    isMain: true,
                    withBorder: true,
                    type: 'links',
                    links: [
                        {
                            label: 'Вездедоход',
                            href: 'https://www.pochtabank.ru/service/debetcards/vezdedohod',
                            typeMark: 'isNoMarked',
                        },
                        {
                            label: 'Пятерочка',
                            href: 'https://www.pochtabank.ru/service/debetcards/5ka-mir',
                            typeMark: 'isNoMarked',
                        },
                        {
                            label: 'Магнит',
                            href: 'https://www.pochtabank.ru/service/debetcards/magnit',
                            typeMark: 'isNoMarked',
                        },
                        {
                            label: 'Зеленый мир',
                            href: 'https://www.pochtabank.ru/',
                            typeMark: 'isNoMarked',
                        },
                        {
                            label: 'Детская карта',
                            href: 'https://www.pochtabank.ru/service/children',
                            typeMark: 'isNoMarked',
                        },
                        {
                            label: 'Карта Геймера',
                            href: 'https://my.pochtabank.ru/cyberlab?_ga=2.85470426.236256034.1622196773-1701933016.1617267725',
                            typeMark: 'isNoMarked',
                        },
                        {
                            label: 'Для покупок',
                            href: 'https://www.pochtabank.ru/service/debetcards/visa_platinum',
                            typeMark: 'isNoMarked',
                        },
                        {
                            label: 'Карта Мир',
                            href: 'https://www.pochtabank.ru/service/debetcards/mir',
                            typeMark: 'isNoMarked',
                        },
                    ],
                },
                {
                    title: '',
                    isMain: false,
                    withBorder: false,
                    type: 'cardImg',
                    cardImg: {
                        title: 'Карта «Вездедоход»',
                        src: '/img-next/png/cards/vezdedohodCv.png',
                        href: 'https://www.pochtabank.ru/service/cards/vddohod',
                        label: 'Подробнее',
                    },
                },
            ],
        },

        {
            title: 'Вклады и инвестиции',
            sections: [
                {
                    title: 'ВСЕ ВКЛАДЫ',
                    isMain: true,
                    withBorder: true,
                    type: 'links',
                    links: [
                        {
                            label: '«Пенсионный +»',
                            href: 'https://www.pochtabank.ru/service/deposits/pension',
                            typeMark: 'isNoMarked',
                        },
                        {
                            label: '«Умный доход»',
                            href: 'https://www.pochtabank.ru/service/deposits/smartprofit_deposit',
                            typeMark: 'isNoMarked',
                        },
                        {
                            label: '«Капитальный»',
                            href: 'https://www.pochtabank.ru/service/deposits/capital',
                            typeMark: 'isNoMarked',
                        },
                        {
                            label: '«Доходный»',
                            href: 'https://www.pochtabank.ru/service/deposits/dohodny',
                            typeMark: 'isNoMarked',
                        },
                        {
                            label: '«Накопительный»',
                            href: 'https://www.pochtabank.ru/service/deposits/nakopitelny',
                            typeMark: 'isNoMarked',
                        },
                    ],
                },
                {
                    title: 'ВСЕ ИНВЕСТИЦИИ',
                    isMain: true,
                    withBorder: true,
                    type: 'links',
                    links: [
                        {
                            label: 'Вложение в золото',
                            href: 'https://www.pochtabank.ru/service/pif-gold',
                            typeMark: 'isNoMarked',
                        },
                        {
                            label: 'Инвестиционное страхование жизни',
                            href: 'https://www.pochtabank.ru/service/investment/iszh',
                            typeMark: 'isNoMarked',
                        },
                        {
                            label: 'ПИФы',
                            href: 'https://www.pochtabank.ru/service/pif',
                            typeMark: 'isNoMarked',
                        },
                        {
                            label: 'ОФЗ-Н',
                            href: 'https://www.pochtabank.ru/service/investment/ofzn',
                            typeMark: 'isNoMarked',
                        },
                    ],
                },
                {
                    title: 'Специальные условия',
                    isMain: false,
                    withBorder: false,
                    type: 'card',
                    card: {
                        title: 'Вклад Капитальный',
                        desc: 'до <span>4,4</span> %',
                        href: 'https://www.pochtabank.ru/service/deposits/capital',
                        button: 'Подробнее',
                        buttonType: 'transparent',
                        img: '/img-next/png/cards/card-decor-2.png',
                        isPWA: true,
                    },
                },
            ],
        },

        {
            title: 'Пенсионерам',
            sections: [
                {
                    title: 'ПЕНСИОНЕРАМ',
                    isMain: true,
                    withBorder: false,
                    type: 'links',
                    links: [
                        {
                            label: 'Пенсионерам',
                            href: 'https://www.pochtabank.ru/service/pensioneram',
                            typeMark: 'isNoMarked',
                        },
                        {
                            label: 'Военным пенсионерам',
                            href: 'https://www.pochtabank.ru/service/voennym_pensioneram',
                            typeMark: 'isNoMarked',
                        },
                    ],
                },
            ],
        },

        {
            title: 'other',
            sections: [
                {
                    title: 'Cервисы и услуги',
                    isMain: false,
                    withBorder: false,
                    type: 'links',
                    links: [
                        {
                            label: 'Перевод с карты на карту',
                            href: 'https://www.pochtabank.ru/p2p',
                            typeMark: 'isNoMarked',
                        },
                        {
                            label: 'Мультибонус',
                            href: 'https://www.pochtabank.ru/multibonus',
                            typeMark: 'isNoMarked',
                        },
                        {
                            label: 'Подари лес другу',
                            href: 'https://www.pochtabank.ru/posadiderevo',
                            typeMark: 'isNoMarked',
                        },
                        {
                            label: 'Единая биометрическая система',
                            href: 'https://www.pochtabank.ru/service/ebs',
                            typeMark: 'isNoMarked',
                        },
                        {
                            label: 'Подтверждение учетной записи через Госуслуги',
                            href: 'https://www.pochtabank.ru/service/gosuslugi',
                            typeMark: 'isNoMarked',
                        },
                    ],
                },
            ],
        },
    ],
    headerTopMenu: [
        {
            href: 'https://www.pochtabank.ru/',
            name: 'Частным лицам',
        },
        {
            href: 'https://www.pochtabank.ru/corp/service',
            name: 'Бизнесу',
        },
        {
            href: 'https://www.pochtabank.ru/about',
            name: 'О банке',
        },
    ],
    headerMainMenu: [
        {
            href: 'https://www.pochtabank.ru/service/loans_1',
            name: 'Кредиты',
        },
        {
            href: 'https://www.pochtabank.ru/service/cards',
            name: 'Карты',
        },
        {
            href: 'https://www.pochtabank.ru/service/deposits',
            name: 'Вклады и инвестиции',
        },
        {
            href: 'https://www.pochtabank.ru/service/pensioneram',
            name: 'Пенсионерам',
        },
    ],
    pwaMenu: [
        {
            title: 'Кредит',
            icon: {
                light: {
                    default: '/img-next/svg/footer-menu/credit.svg',
                    hover: '/img-next/svg/footer-menu/credit-hover.svg',
                },
                dark: {
                    default: '/img-next/svg/footer-menu/credit-dark.svg',
                    hover: '/img-next/svg/footer-menu/credit-dark-hover.svg',
                },
            },
            sections: [
                {
                    title: 'Кредит от 3,9%',
                    isMarked: true,
                    href: 'https://www.pochtabank.ru/service/cashcredit#credit-issue-form-container',
                },
                {
                    title: 'Рефинансирование',
                    href: 'https://www.pochtabank.ru/service/refinans',
                },
                {
                    title: 'Кредит на строительство',
                    href: 'https://www.pochtabank.ru/service/bistr_doma',
                },
                {
                    title: 'Все кредиты',
                    href: 'https://www.pochtabank.ru/service/loans_1',
                },
            ],
        },
        {
            title: 'Карты',
            icon: {
                light: {
                    default: '/img-next/svg/footer-menu/cards.svg',
                    hover: '/img-next/svg/footer-menu/cards-hover.svg',
                },
                dark: {
                    default: '/img-next/svg/footer-menu/cards-dark.svg',
                    hover: '/img-next/svg/footer-menu/cards-dark-hover.svg',
                },
            },
            sections: [
                {
                    title: 'Все дебетовые карты',
                    href: 'https://www.pochtabank.ru/service/debetcards',
                },
                {
                    title: 'Все кредитные карты',
                    href: 'https://www.pochtabank.ru/service/cards',
                },
                {
                    title: 'Карта «Элемент120»',
                    href: 'https://www.pochtabank.ru/service/cards',
                    isMarked: true,
                },
                {
                    title: 'Карта «Вездедоход»',
                    href: 'https://www.pochtabank.ru/service/cards/vddohod',
                },
                {
                    title: 'Почта Банк. Младший',
                    href: 'https://www.pochtabank.ru/service/children',
                },
            ],
            card: {
                title: '0% на 120 дней',
                desc: 'До 1 500 000 ₽ • 120 дней без процентов.<br/>Кэшбэк до 12% с программой Шанс',
                href: 'https://www.pochtabank.ru/service/cards/vddohod',
                button: 'Получить',
                buttonType: 'transparent',
                img: '/img-next/png/cards/card-decor-2.png',
                isPWA: true,
            },
        },
        {
            title: 'Вклады',
            icon: {
                light: {
                    default: '/img-next/svg/footer-menu/wallet.svg',
                    hover: '/img-next/svg/footer-menu/wallet-hover.svg',
                },
                dark: {
                    default: '/img-next/svg/footer-menu/wallet-dark.svg',
                    hover: '/img-next/svg/footer-menu/wallet-dark-hover.svg',
                },
            },
            sections: [
                {
                    title: '«Пенсионный +»',
                    subtitle: 'до 5%',
                    href: 'https://www.pochtabank.ru/service/deposits/pension',
                    isMarked: false,
                },
                {
                    title: '«Умный доход»',
                    subtitle: 'до 6.75%',
                    href: 'https://www.pochtabank.ru/service/deposits/smartprofit_deposit',
                    isMarked: false,
                },
                {
                    title: '«Капитальный»',
                    subtitle: 'до 4.75%',
                    href: 'https://www.pochtabank.ru/service/deposits/capital',
                    isMarked: false,
                },
                {
                    title: '«Доходный»',
                    subtitle: 'до 4.2%',
                    href: 'https://www.pochtabank.ru/service/deposits/dohodny',
                    isMarked: false,
                },
                {
                    title: '«Накопительный»',
                    subtitle: 'до 4.1%',
                    href: 'https://www.pochtabank.ru/service/deposits/nakopitelny',
                    isMarked: false,
                },
            ],
        },
        {
            title: 'Пенсионерам',
            icon: {
                light: {
                    default: '/img-next/svg/footer-menu/payments.svg',
                    hover: '/img-next/svg/footer-menu/payments-hover.svg',
                },
                dark: {
                    default: '/img-next/svg/footer-menu/payments-dark.svg',
                    hover: '/img-next/svg/footer-menu/payments-dark-hover.svg',
                },
            },
            sections: [
                {
                    title: 'Перевести пенсию',
                    href: 'https://my.pochtabank.ru/pension',
                },
                {
                    title: 'Консьерж-сервис',
                    href: 'https://www.pochtabank.ru/service/concierge-for-older',
                },
                {
                    title: 'Мое здоровье',
                    href: 'https://www.pochtabank.ru/service/my-health',
                },
                {
                    title: 'Все сервисы для пенсионеров',
                    href: 'https://www.pochtabank.ru/service/pensioneram',
                },
                {
                    title: 'Пенсионерам ',
                    href: 'https://www.pochtabank.ru/service/pensioneram',
                },
            ],
        },
        {
            title: 'Меню',
            icon: {
                light: {
                    default: '/img-next/svg/footer-menu/menu.svg',
                    hover: '/img-next/svg/footer-menu/menu-hover.svg',
                },
                dark: {
                    default: '/img-next/svg/footer-menu/menu-dark.svg',
                    hover: '/img-next/svg/footer-menu/menu-dark-hover.svg',
                },
            },
            toggles: [
                {
                    title: 'Частным лицам',
                    code: 'private',
                },
                {
                    title: 'Бизнесу',
                    code: 'business',
                },
            ],
            sections: [
                {
                    title: 'Офисы и банкоматы',
                    href: 'https://www.pochtabank.ru/map',
                    toggleCode: 'private',
                },
                {
                    title: 'Перевод с карты на карту',
                    href: 'https://www.pochtabank.ru/p2p',
                    toggleCode: 'private',
                },
                {
                    title: 'Мультибонус',
                    href: 'https://www.pochtabank.ru/multibonus',
                    toggleCode: 'private',
                },
                {
                    title: 'Подари лес другу',
                    href: 'https://www.pochtabank.ru/posadiderevo',
                    toggleCode: 'private',
                },
                {
                    title: 'Единая биометрическая система',
                    href: 'https://www.pochtabank.ru/service/ebs',
                    toggleCode: 'private',
                },
                {
                    title: 'Подтверждение учетной записи через Госуслуги',
                    href: 'https://my.pochtabank.ru/login',
                    toggleCode: 'private',
                },
                {
                    title: 'РКО',
                    toggleCode: 'business',
                    links: [
                        {
                            label: 'Расчетный счет для бизнеса',
                            href: 'https://www.pochtabank.ru/corp/rko',
                        },
                        { label: 'Отчетность онлайн', href: 'http://otchet.pochtabank.ru/' },
                    ],
                },
                {
                    title: 'Кредиты',
                    toggleCode: 'business',
                    links: [
                        {
                            label: 'Кредит на бизнес',
                            href: '#',
                        },
                        {
                            label: 'Онлайн-кредитование',
                            href: 'https://www.pochtabank.ru/corp/online_credit',
                        },
                    ],
                },
                {
                    title: 'РКО',
                    toggleCode: 'business',
                    links: [
                        {
                            label: 'Торговый эквайринг',
                            href: 'https://www.pochtabank.ru/corp/trade-acquiring',
                        },
                        {
                            label: 'Интернет-эквайринг',
                            href: 'https://www.pochtabank.ru/corp/internet_acquiring',
                        },
                    ],
                },
                {
                    title: 'Карты',
                    toggleCode: 'business',
                    links: [
                        {
                            label: 'Корпоративная карта',
                            href: 'https://www.pochtabank.ru/corp/corpcard',
                        },
                    ],
                },
            ],
        },
    ],
    hiddenHeaderMenu: [
        { label: 'Кредит наличными', href: 'https://www.pochtabank.ru/service/loans_1' },
        { label: 'Кредитная карта', href: 'https://www.pochtabank.ru/service/cards' },
        { label: 'Кредит на строительство', href: 'https://www.pochtabank.ru/service/bistr_doma' },
        { label: 'Перевод пенсии', href: 'https://www.pochtabank.ru/service/pensioneram' },
        { label: 'Сберегательный счет', href: 'https://www.pochtabank.ru/service/deposits' },
    ],
};

export const mockHeaderDataTina: IHeader = {
    headerCollapsedMenu: [
        {
            title: 'Заголовок',
            sections: [
                {
                    title: 'Заголовок',
                    link: '#',
                    isMain: true,
                    withBorder: true,
                    type: 'links',
                    links: [
                        {
                            label: 'Заголовок',
                            href: '#',
                            typeMark: 'isMarked',
                        },
                    ],
                },
            ],
        },

        {
            title: 'other',
            sections: [
                {
                    title: 'Заголовок',
                    isMain: false,
                    withBorder: false,
                    type: 'links',
                    links: [
                        {
                            label: 'Заголовок',
                            href: '#',
                            typeMark: 'isMarked',
                        },
                    ],
                },
            ],
        },
    ],
    headerTopMenu: [
        {
            href: '#',
            name: 'Заголовок',
        },
    ],
    headerMainMenu: [
        {
            href: '#',
            name: 'Заголовок',
        },
    ],
    pwaMenu: [
        {
            title: 'Заголовок',
            icon: {
                light: {
                    default: '',
                    hover: '',
                },
                dark: {
                    default: '',
                    hover: '',
                },
            },
            toggles: [
                {
                    title: 'Заголовок',
                    code: 'business',
                },
            ],
            sections: [
                {
                    title: 'Заголовок',
                    links: [
                        {
                            label: 'Заголовок',
                            href: '#',
                            typeMark: 'isMarked',
                        },
                    ],
                },
            ],
        },
    ],
    hiddenHeaderMenu: [
        { label: 'Заголовок', href: '#' },
        { label: 'Заголовок', href: '#' },
    ],
};
