export const mockSocialsBlockData = {
    section: null,
    title: 'Пресс-служба',
    list: [
        {
            title: 'pr@pochtabank.ru',
            link: 'mailto:pr@pochtabank.ru',
            image: '/img-next/svg/social/news/mail.svg',
        },
        {
            title: '8 (800) 550 0770',
            link: 'tel:88005500770',
            image: '/img-next/svg/social/news/phone.svg',
            gtag: {
                action: 'test',
                category: 'test',
                label: 'test',
            },
        },
        {
            title: 'Фейсбук',
            image: '/img-next/svg/social/news/fb.svg',
            link: '#',
        },
        {
            title: 'Яндекс.Дзен',
            image: '/img-next/svg/social/news/zen.svg',
            link: '#',
            gtag: {
                action: 'test',
                category: 'test',
                label: 'test',
            },
        },
    ],
};
export const mockSocialsBlockDataTina = {
    title: 'Пресс-служба',
    section: null,
    list: [{ title: 'Заголовок', link: '#', image: '' }],
};
