export const mockAllExchangeRatesBlockData = {
    name: 'Все курсы валют',
    list: [
        {
            title: 'USD',
            desc: 'Доллар США',
            scale: 1,
            buy: {
                value: '72.1410',
                flag: true,
            },
            sale: {
                value: '75.8406',
                flag: true,
            },
        },
        {
            title: 'EUR',
            desc: 'Евро',
            scale: 1,
            buy: {
                value: '84.8812',
                flag: true,
            },
            sale: {
                value: '89.2340',
                flag: true,
            },
        },
        {
            title: 'AUD',
            desc: 'Австралийский доллар',
            scale: 1,
            buy: {
                value: '52.3239',
                flag: true,
            },
            sale: {
                value: '55.0071',
                flag: true,
            },
        },
        {
            title: 'AZN',
            desc: 'Азербайджанский манат',
            scale: 1,
            buy: {
                value: '42.4609',
                flag: false,
            },
            sale: {
                value: '44.6383',
                flag: false,
            },
        },
        {
            title: 'AMD',
            desc: 'Армянский драм',
            scale: 100,
            buy: {
                value: '14.6524',
                flag: false,
            },
            sale: {
                value: '15.4038',
                flag: false,
            },
        },
        {
            title: 'BYN',
            desc: 'Белорусский рубль',
            scale: 1,
            buy: {
                value: '28.7095',
                flag: false,
            },
            sale: {
                value: '30.1817',
                flag: false,
            },
        },
        {
            title: 'BGN',
            desc: 'Болгарский лев',
            scale: 1,
            buy: {
                value: '43.3722',
                flag: false,
            },
            sale: {
                value: '45.5964',
                flag: false,
            },
        },
        {
            title: 'BRL',
            desc: 'Бразильский реал',
            scale: 100,
            buy: {
                value: '13.8387',
                flag: false,
            },
            sale: {
                value: '14.5483',
                flag: false,
            },
        },
        {
            title: 'HUF',
            desc: 'Венгерский форинт',
            scale: 100,
            buy: {
                value: '24.3403',
                flag: false,
            },
            sale: {
                value: '25.5885',
                flag: false,
            },
        },
        {
            title: 'KRW',
            desc: 'Вон Республики Корея',
            scale: 1000,
            buy: {
                value: '61.6330',
                flag: false,
            },
            sale: {
                value: '64.7936',
                flag: false,
            },
        },
        {
            title: 'DKK',
            desc: 'Датская крона',
            scale: 1,
            buy: {
                value: '11.4081',
                flag: false,
            },
            sale: {
                value: '11.9931',
                flag: false,
            },
        },
        {
            title: 'INR',
            desc: 'Индийская рупия',
            scale: 100,
            buy: {
                value: '97.1859',
                flag: false,
            },
            sale: {
                value: '102.1697',
                flag: false,
            },
        },
        {
            title: 'KZT',
            desc: 'Казахстанский тенге',
            scale: 100,
            buy: {
                value: '16.9067',
                flag: false,
            },
            sale: {
                value: '17.7737',
                flag: false,
            },
        },
        {
            title: 'CAD',
            desc: 'Канадский доллар',
            scale: 1,
            buy: {
                value: '57.1867',
                flag: false,
            },
            sale: {
                value: '60.1193',
                flag: false,
            },
        },
        {
            title: 'KGS',
            desc: 'Киргизский сом',
            scale: 100,
            buy: {
                value: '85.1487',
                flag: false,
            },
            sale: {
                value: '89.5153',
                flag: false,
            },
        },
        {
            title: 'CNY',
            desc: 'Китайский юань',
            scale: 1,
            buy: {
                value: '11.1263',
                flag: false,
            },
            sale: {
                value: '11.6969',
                flag: false,
            },
        },
        {
            title: 'MDL',
            desc: 'Молдавский лей',
            scale: 10,
            buy: {
                value: '40.7691',
                flag: false,
            },
            sale: {
                value: '42.8599',
                flag: false,
            },
        },
        {
            title: 'TMT',
            desc: 'Новый туркменский манат',
            scale: 1,
            buy: {
                value: '20.6412',
                flag: false,
            },
            sale: {
                value: '21.6998',
                flag: false,
            },
        },
        {
            title: 'NOK',
            desc: 'Норвежская крона',
            scale: 10,
            buy: {
                value: '81.8260',
                flag: false,
            },
            sale: {
                value: '86.0222',
                flag: false,
            },
        },
        {
            title: 'PLN',
            desc: 'Польский злотый',
            scale: 1,
            buy: {
                value: '18.5509',
                flag: false,
            },
            sale: {
                value: '19.5023',
                flag: false,
            },
        },
        {
            title: 'RON',
            desc: 'Румынский лей',
            scale: 1,
            buy: {
                value: '17.2035',
                flag: false,
            },
            sale: {
                value: '18.0857',
                flag: false,
            },
        },
        {
            title: 'SGD',
            desc: 'Сингапурский доллар',
            scale: 1,
            buy: {
                value: '53.3232',
                flag: false,
            },
            sale: {
                value: '56.0578',
                flag: false,
            },
        },
        {
            title: 'TJS',
            desc: 'Таджикский сомони',
            scale: 10,
            buy: {
                value: '63.2261',
                flag: false,
            },
            sale: {
                value: '66.4685',
                flag: false,
            },
        },
        {
            title: 'TRY',
            desc: 'Турецкая лира',
            scale: 10,
            buy: {
                value: '86.3045',
                flag: false,
            },
            sale: {
                value: '90.7303',
                flag: false,
            },
        },
        {
            title: 'UZS',
            desc: 'Узбекский сум',
            scale: 10000,
            buy: {
                value: '67.7126',
                flag: false,
            },
            sale: {
                value: '71.1850',
                flag: false,
            },
        },
        {
            title: 'UAH',
            desc: 'Украинская гривна',
            scale: 10,
            buy: {
                value: '26.9407',
                flag: false,
            },
            sale: {
                value: '28.3223',
                flag: false,
            },
        },
        {
            title: 'GBP',
            desc: 'Фунт стерлингов Соединенного королевства',
            scale: 1,
            buy: {
                value: '99.1362',
                flag: false,
            },
            sale: {
                value: '104.2202',
                flag: false,
            },
        },
        {
            title: 'CZK',
            desc: 'Чешская крона',
            scale: 10,
            buy: {
                value: '33.1606',
                flag: false,
            },
            sale: {
                value: '34.8612',
                flag: false,
            },
        },
        {
            title: 'SEK',
            desc: 'Шведская крона',
            scale: 10,
            buy: {
                value: '82.8722',
                flag: false,
            },
            sale: {
                value: '87.1220',
                flag: false,
            },
        },
        {
            title: 'CHF',
            desc: 'Швейцарский франк',
            scale: 1,
            buy: {
                value: '78.6879',
                flag: false,
            },
            sale: {
                value: '82.7231',
                flag: false,
            },
        },
        {
            title: 'ZAR',
            desc: 'Южноафриканский рэнд',
            scale: 10,
            buy: {
                value: '48.2368',
                flag: false,
            },
            sale: {
                value: '50.7104',
                flag: false,
            },
        },
        {
            title: 'JPY',
            desc: 'Японская иена',
            scale: 100,
            buy: {
                value: '65.4786',
                flag: false,
            },
            sale: {
                value: '68.8364',
                flag: false,
            },
        },
    ],
};

export const mockAllExchangeRatesBlockDataTina = {
    section: null,
    name: 'Заголовок',
    list: [
        {
            title: 'Название',
            desc: 'Описание',
            scale: 1,
            buy: {
                value: 'Значение',
                flag: true,
            },
            sale: {
                value: 'Значение',
                flag: false,
            },
        },
        {
            title: 'Название',
            desc: 'Описание',
            scale: 1,
            buy: {
                value: 'Значение',
                flag: true,
            },
            sale: {
                value: 'Значение',
                flag: false,
            },
        },
        {
            title: 'Название',
            desc: 'Описание',
            scale: 1,
            buy: {
                value: 'Значение',
                flag: true,
            },
            sale: {
                value: 'Значение',
                flag: false,
            },
        },
        {
            title: 'Название',
            desc: 'Описание',
            scale: 1,
            buy: {
                value: 'Значение',
                flag: true,
            },
            sale: {
                value: 'Значение',
                flag: false,
            },
        },
        {
            title: 'Название',
            desc: 'Описание',
            scale: 1,
            buy: {
                value: 'Значение',
                flag: true,
            },
            sale: {
                value: 'Значение',
                flag: false,
            },
        },
    ],
};
