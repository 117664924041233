import React, { ChangeEvent, useState } from 'react';

import TinaButton from '@/components/UI/TinaButton';
import TinaInput from '@/components/UI/TinaInput';

import cn from './style.module.sass';

interface IProps {
    searchHandler: (value?: string, isInitial?: boolean) => void;
    resetSearchHandler: () => void;
    initialValue?: string;
}

const Search: React.FC<IProps> = ({ searchHandler, resetSearchHandler, initialValue }) => {
    const [searchValue, setSearchValue] = useState<string>(initialValue || '');

    const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchValue(e.target.value);
    };

    const onSearch = () => {
        searchHandler(searchValue, true);
    };

    const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            if (!searchValue) {
                resetSearchHandler();
                return;
            }
            searchHandler(searchValue, true);
        }
    };

    return (
        <div className={cn.wrapper}>
            <div className={cn.inputWrapper}>
                <TinaInput placeholder="Найти..." value={searchValue} onChange={onInputChange} onKeyDown={onKeyDown} />
            </div>
            <div className={cn.buttonWrapper}>
                <TinaButton label="Поиск" onClick={onSearch} />
                {initialValue && <TinaButton label="Сброс" onClick={resetSearchHandler} customClass={cn.resetButton} />}
            </div>
        </div>
    );
};

export default Search;
