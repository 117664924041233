export const gaParamsConfig = {
    name: 'gaParams',
    label: 'Параметры аналитики',
    component: 'group',
    fields: [
        {
            component: 'text',
            name: 'pagePrefix',
            label: 'Префикс для целей текущей страницы',
        },
        {
            name: 'gtag',
            label: 'Дополнительные параметры',
            component: 'group',
            fields: [
                {
                    component: 'text',
                    name: 'pageType',
                    label: 'pageType',
                },
                {
                    component: 'text',
                    name: 'portalName',
                    label: 'portalName',
                },
                {
                    component: 'text',
                    name: 'categoryName',
                    label: 'categoryName',
                },
            ],
        },
    ],
};
