import { FullscreenModal, Modal, ModalBody, ModalHeader } from '@tinacms/react-modals';
import React, { useEffect, useState } from 'react';
import { useCMS } from 'tinacms';

import { IMediaRequest, MediaPicker } from '@/tina/plugins/mediaManager/MediaManager';

export const MediaManagerSidebar: React.FC = () => {
    const cms = useCMS();

    const [request, setRequest] = useState<IMediaRequest | undefined>();

    useEffect(
        () =>
            cms.events.subscribe('customMedia:open', ({ type, ...rest }) => {
                setRequest(rest);
            }),
        []
    );

    const close = () => setRequest(undefined);

    if (!request) return null;
    return (
        <Modal>
            <FullscreenModal>
                <ModalHeader close={close}>Медиа Менеджер</ModalHeader>
                <ModalBody>
                    <MediaPicker {...request} close={close} />
                </ModalBody>
            </FullscreenModal>
        </Modal>
    );
};
