export const metaConfig = {
    name: 'meta',
    label: 'Мета данные',
    component: 'group',
    fields: [
        {
            name: 'sectionTitle',
            component: 'group',
            label: 'Заголовок секции',
            fields: [
                {
                    name: 'text',
                    component: 'text',
                    label: 'Текст',
                },
                {
                    name: 'isTitle',
                    component: 'toggle',
                    label: 'SEO заголовок',
                },
            ],
        },
        {
            name: 'linkCanonical',
            component: 'text',
            label: 'Каноническая ссылка для страницы',
        },
        {
            name: 'title',
            component: 'text',
            label: 'Заголовок',
        },
        {
            name: 'description',
            component: 'textarea',
            label: 'Описание',
        },
        {
            name: 'keywords',
            component: 'textarea',
            label: 'Ключевые слова',
        },
        {
            name: 'ogTitle',
            component: 'text',
            label: 'OpenGraph: Заголовок',
        },
        {
            name: 'ogDescription',
            component: 'textarea',
            label: 'OpenGraph: Описание',
        },
        {
            name: 'ogImage',
            component: 'image',
            label: 'OpenGraph: Изображение',
            parse: (media: { src: string }) => media.src,
        },
    ],
};
