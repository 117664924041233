export const breadcrumbsConfig = {
    name: 'breadcrumbs',
    label: 'Хлебные крошки',
    component: 'group',
    fields: [
        {
            name: 'isActive',
            component: 'toggle',
            label: 'Выключить/Включить хлебные крошки',
        },
        {
            component: 'select',
            name: 'color',
            label: 'Цвет',
            options: [
                {
                    value: 'grey',
                    label: 'Серый',
                },
                {
                    value: 'white',
                    label: 'Белый',
                },
            ],
        },
    ],
};
