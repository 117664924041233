import React from 'react';

import { TinaNewsSubscription } from '@/components/blocks/sidebar/NewsSubscription/index.tina';
import { mockNewsSubscriptionDataTina } from '@/data/blocks/sidebar/newsSubscription';

export const newsSubscriptionBlock = {
    Component: props => <TinaNewsSubscription {...props} />,
    template: {
        label: 'Новостная подписка',
        defaultItem: () => mockNewsSubscriptionDataTina,
        fields: [
            {
                name: 'title',
                component: 'text',
                label: 'Заголовок',
            },
            {
                name: 'description',
                component: 'textarea',
                label: 'Описание',
            },
            {
                name: 'successTitle',
                component: 'textarea',
                label: 'Оповещение в итоговом окне',
            },
            {
                name: 'info',
                component: 'group',
                label: 'Дополнительная информация',
                fields: [
                    {
                        name: 'title',
                        component: 'text',
                        label: 'Заголовок',
                    },
                    {
                        name: 'desc',
                        component: 'textarea',
                        label: 'Описание',
                    },
                    {
                        name: 'phone',
                        component: 'text',
                        label: 'Телефон',
                    },
                    {
                        name: 'phoneLink',
                        component: 'text',
                        label: 'Телефонная ссылка',
                    },
                    {
                        name: 'mail',
                        component: 'text',
                        label: 'Эл. почта',
                    },
                ],
            },
        ],
    },
};
