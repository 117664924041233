import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

import SubscriptionModal from '@/components/SubscriptionModal';
import TextField from '@/components/TextField';
import Button from '@/components/UI/Button';
import Input from '@/components/UI/Input';
import { IBXResponseForms } from '@/types/requests';
import { getFormDataFromHookForm } from '@/utils/forms/getFormDataFromHookForm';
import { getFormErrors } from '@/utils/forms/getFormErrors';
import { apiRequest } from '@/utils/request';

import cn from './style.module.sass';

export interface INewsSubscription {
    _template: 'newsSubscription';
    title: string;
    description: string;
    successTitle: string;
}

const NewsSubscription: React.FC<INewsSubscription> = ({ title, description, successTitle }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitSuccessful, isSubmitting },
    } = useForm();

    const closeModalHandler = () => {
        setIsModalOpen(false);
    };

    const openModalHandler = () => {
        setIsModalOpen(true);
    };

    const onSubmit = async finalData => {
        const formData = getFormDataFromHookForm(finalData);

        try {
            const response = await apiRequest.post<IBXResponseForms<{ message: string }>>(
                '/iblock/News/subscribe',
                formData
            );

            if (response?.data?.errors.length > 0) {
                throw new Error(response?.data?.errors[0].message);
            }
        } catch (e) {
            console.error(e);
        }
        setIsModalOpen(true);
    };

    const [isError, errorText] = getFormErrors(errors, 'email');

    return (
        <div className="sidebarSection">
            <div className={cn.wrapper}>
                <SubscriptionModal
                    title={successTitle}
                    handleClose={closeModalHandler}
                    handleOpen={openModalHandler}
                    isOpen={isModalOpen}
                    isFail={!isSubmitSuccessful}
                />
                <form className={cn.form} onSubmit={handleSubmit(onSubmit)} noValidate>
                    <TextField text={title} name="title" isTextArea className={cn.title} />
                    <div className={cn.input}>
                        <Input
                            size="small"
                            isError={isError}
                            errorText={errorText}
                            type="text"
                            label="E-mail"
                            {...register('email', {
                                required: 'Заполните поле E-mail',
                                pattern: {
                                    value: /\S+@\S+\.\S+/,
                                    message: 'E-mail введен некорректно',
                                },
                            })}
                        />
                    </div>
                    <TextField text={description} name="description" isTextArea className={cn.description} />
                    <Button
                        variant="btnBlue"
                        buttonType="submit"
                        type="button"
                        label="Подписаться"
                        disable={isSubmitting}
                        customClass={cn.button}
                        animation={false}
                    />
                </form>
            </div>
        </div>
    );
};

export default NewsSubscription;
