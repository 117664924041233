import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { FieldPlugin } from 'tinacms';

import { getParentField } from '@/tina/utils/getParentField';
import { ITag } from '@/types/tags';
import { CustomFieldConfig } from '@/types/tina/fields';
import { apiRequest } from '@/utils/request';

import cn from './style.module.sass';

interface ISelectValue {
    value: string;
    label: string;
}

export const TagListField = observer(({ form, input, field }: CustomFieldConfig) => {
    const [options, setOptions] = useState<ISelectValue[] | string>(null);
    const [isRequestGoing, setIsRequestGoing] = useState(false);

    const parentField = getParentField(input.name, form);
    const template = parentField._template;

    const formatToSelectValues = (values: ITag[]) => {
        if (!values) return '';

        return values?.reduce((acc: ISelectValue[], item) => [...acc, { label: item.value, value: item.xmlId }], []);
    };

    const formatToTinaValues = (values: ISelectValue[]) =>
        values?.reduce((acc: ITag[], item) => [...acc, { xmlId: item.value, value: item.label }], []);

    const getTagList = async () => {
        setIsRequestGoing(true);

        const response: { data: { data: ITag[] } } = await apiRequest(`/page/block/tags?block=${template}`);
        const {
            data: { data: fetchedTags },
        } = response;

        const newTags = formatToSelectValues(fetchedTags);

        setOptions(newTags);
        setIsRequestGoing(false);
    };

    const onChange = values => {
        const tags = formatToTinaValues(values);
        input.onChange(tags);
    };

    useEffect(() => {
        getTagList().catch(console.error);
    }, []);

    return (
        <div className={cn.wrapper}>
            <label htmlFor={input.name}>{field.label}</label>
            {!isRequestGoing && (
                <div className={cn.selectWrapper}>
                    <Select
                        options={options as ISelectValue[]}
                        isMulti
                        onChange={onChange}
                        className={cn.select}
                        classNamePrefix="react-select"
                        noOptionsMessage={() => 'Нет доступных элементов'}
                        defaultValue={formatToSelectValues(input.value as ITag[]) as ISelectValue[]}
                        placeholder="Выберите элемент..."
                    />
                </div>
            )}
            {isRequestGoing && <div className={cn.preloader} />}
        </div>
    );
});

export const TagListFieldPlugin: FieldPlugin = {
    __type: 'field',
    name: 'tagList',
    Component: TagListField,
};

// Пример использования
//
// fields: [
//     {
//         name: 'title',
//         component: 'text',
//         label: 'Заголовок',
//     },
//     {
//         name: 'tagList',
//         component: 'tagList',
//         label: 'Заголовок',
//     },
