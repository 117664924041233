import React, { useState } from 'react';

import SubscriptionModal from '@/components/SubscriptionModal';
import TextField from '@/components/TextField';
import Button from '@/components/UI/Button';

import cn from './style.module.sass';

export interface IRSSSubscription {
    _template: 'rssSubscription';
    title: string;
    description: string;
    successTitle: string;
}

const RSSSubscription: React.FC<IRSSSubscription> = ({ title, description, successTitle }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const closeModalHandler = () => {
        setIsModalOpen(false);
    };

    const openModalHandler = () => {
        setIsModalOpen(true);
    };

    return (
        <div className="sidebarSection">
            <div className={cn.wrapper}>
                <SubscriptionModal
                    title={successTitle}
                    isOpen={isModalOpen}
                    handleClose={closeModalHandler}
                    handleOpen={openModalHandler}
                />
                <div className={cn.form}>
                    <TextField text={title} name="title" isTextArea className={cn.title} />
                    <TextField text={description} name="description" isTextArea className={cn.description} />

                    <Button
                        variant="btnBlue"
                        buttonType="submit"
                        type="link"
                        href="/bxapi/iblock/News/rss"
                        label="Получить"
                        customClass={cn.button}
                        animation={false}
                    />
                </div>
            </div>
        </div>
    );
};

export default RSSSubscription;
