import { observer } from 'mobx-react-lite';
import React, { ChangeEvent, useEffect, useState } from 'react';

import { LoadingDots } from '@/components/UI/icons/tina/LoadingDots';
import TinaButton from '@/components/UI/TinaButton';
import TinaInput from '@/components/UI/TinaInput';
import { useTinaLibStore } from '@/hooks/useTina';
import { nextApiRequest } from '@/utils/request';

import cn from './style.module.sass';

const AddFolder: React.FC = observer(() => {
    const { Tina } = useTinaLibStore();
    const { useCMS } = Tina;
    const [mediaDirectory, setMediaDirectory] = useState('');

    const cms = useCMS();

    const [dirname, setDirname] = useState<string>('');
    const [loading, setLoading] = useState(false);

    const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (!/[^A-Za-z0-9 _]+/g.test(e.target.value)) {
            setDirname(e.target.value);
        }
    };

    const onFolderAdd = async () => {
        if (!dirname) {
            cms.alerts.error('Название не должно быть пустым.');
            return;
        }

        if (dirname.slice(0, 1) === ' ' || dirname.slice(0, 1) === '_') {
            cms.alerts.error('Название не должно начинаться с пробела или нижнего подчеркивания.');
            return;
        }

        const parsedDirName = dirname.split(' ').join('_');

        const getCorrectPath = () => {
            if (!mediaDirectory) return '/';
            if (mediaDirectory.slice(0, 1) !== '/') return `/${mediaDirectory}/`;
            return `${mediaDirectory}/`;
        };

        try {
            setLoading(true);
            await nextApiRequest.post('/store/directory/add', {
                name: parsedDirName,
                path: getCorrectPath(),
            });
            setDirname(null);
            setLoading(false);
            cms.events.dispatch({ type: 'customMedia:upload:success' });
        } catch (error) {
            setLoading(false);
            cms.events.dispatch({ type: 'customMedia:upload:failure' });
        }
    };

    useEffect(
        () =>
            cms.events.subscribe('customMedia:list:start', ({ directory: newDirectory }) => {
                setMediaDirectory(newDirectory);
            }),
        [cms.events, setMediaDirectory]
    );

    const buttonLabel: JSX.Element | string = loading ? <LoadingDots /> : 'Добавить';

    return (
        <div className={cn.addFolder}>
            <div className={cn.inputWrapper}>
                <TinaInput placeholder="Название папки" value={dirname} onChange={onInputChange} />
            </div>
            <div className={cn.buttonWrapper}>
                <TinaButton label={buttonLabel} onClick={onFolderAdd} />
            </div>
        </div>
    );
});

export default AddFolder;
