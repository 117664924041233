import { Form } from '@tinacms/forms';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Controller, Resolver, useForm } from 'react-hook-form';
import Select from 'react-select';
import { TinaCMS } from 'tinacms/build/tina-cms';

import { MediaIcon } from '@/components/UI/icons/tina/MediaIcon';
import TinaButton from '@/components/UI/TinaButton';
import TinaInput from '@/components/UI/TinaInput';
import { useAppStore } from '@/context/AppStoreContext';
import { useGeoStore } from '@/context/GeoStoreContext';
import { useValidationSchema } from '@/hooks/useValidationSchema';
import { ICity } from '@/types/city/city';
import { IData } from '@/types/data';
import { IGeoGroups } from '@/types/geoGroups';
import { IGeoList } from '@/types/geoList';
import { IMacroRegion } from '@/types/macroRegion/macroRegion';
import { IBXResponse } from '@/types/requests';
import { ISelectElement } from '@/types/select';
import { apiRequest } from '@/utils/request';

import cn from './style.module.sass';

interface IProps {
    form: Form;
    geoList?: IGeoList[];
    cms?: TinaCMS;
}

interface IFinalData {
    name: string;
    regions: ISelectElement<number>[];
    macroregions: ISelectElement<number>[];
}

interface ICityWithId extends ICity {
    id: number | string;
}

const CityCreator: React.FC<IProps> = ({ form, geoList, cms }) => {
    const [path] = useAppStore(store => store.path);
    const { setGeoGroups, geoGroups } = useGeoStore();

    const selectRef = useRef(null);
    const selectMacroRef = useRef(null);
    const timeout = useRef<ReturnType<typeof setTimeout>>(null);

    const selectedRegions = useMemo(() => geoList.flatMap(item => item.list), [geoList]);

    const [selectValue, setSelectValue] = useState<ISelectElement<string>[]>(null);
    const [selectInputValue, setSelectInputValue] = useState<string>(null);
    const [selectOptions, setSelectOptions] = useState<ISelectElement<string>[]>([]);
    const [isOptionsLoading, setIsOptionsLoading] = useState(false);

    const [selectMacroValue, setSelectMacroValue] = useState<ISelectElement<string>[]>(null);
    const [selectInputMacroValue, setSelectInputMacroValue] = useState<string>(null);
    const [selectMacroOptions, setSelectMacroOptions] = useState<ISelectElement<string>[]>([]);
    const [isOptionsMacroLoading, setIsOptionsMacroLoading] = useState(false);

    const [useSecondaryResolver, setUseSecondaryResolver] = useState(false);

    const onInputChange = (value: string) => {
        setSelectInputValue(value);
        clearTimeout(timeout.current);

        if (!value) {
            setSelectOptions([]);
            setIsOptionsLoading(false);
            return;
        }

        setIsOptionsLoading(true);
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        timeout.current = setTimeout(async () => {
            const response: IBXResponse<ICityWithId[]> = await apiRequest(`/city/list?q=${value}`);
            const citiesList = response.data.data;
            const newOptions = citiesList
                .filter(item => !selectedRegions.includes(+item.id))
                .map(city => ({ label: city.city, value: city.id.toString() }));

            setSelectOptions(newOptions);
            setIsOptionsLoading(false);
            setUseSecondaryResolver(false);
        }, 1000);
    };

    const onInputMacroChange = (value: string) => {
        setSelectInputMacroValue(value);
        clearTimeout(timeout.current);

        if (!value) {
            setSelectMacroOptions([]);
            setIsOptionsMacroLoading(false);
            return;
        }

        setIsOptionsMacroLoading(true);
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        timeout.current = setTimeout(async () => {
            const response: IBXResponse<IMacroRegion[]> = await apiRequest(`/macroregion/list?q=${value}`);
            const macroRegList = response.data.data;
            const newOptions = macroRegList.map(reg => ({
                label: reg.ufName,
                value: reg.id.toString(),
            }));

            setSelectMacroOptions(newOptions);
            setIsOptionsMacroLoading(false);
            setUseSecondaryResolver(true);
        }, 1000);
    };

    const formResolver = useValidationSchema([
        { name: 'name', type: 'required', required: true },
        { name: 'regions', type: 'array', required: true },
        { name: 'macroregions', type: 'arrayOfStringsOptional', required: true },
    ]);

    const mySecondaryFormResolver = useValidationSchema([
        { name: 'name', type: 'required', required: true },
        { name: 'regions', type: 'arrayOfStringsOptional', required: true },
        { name: 'macroregions', type: 'array', required: true },
    ]);

    const currentFormResolver: Resolver<any> = !useSecondaryResolver ? formResolver : mySecondaryFormResolver;

    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting },
        control,
        reset,
    } = useForm({ resolver: currentFormResolver, reValidateMode: 'onSubmit' });

    const onSubmit = async (finalData: IFinalData) => {
        try {
            const response: IBXResponse<IGeoGroups> = await apiRequest.post('/page/geo', {
                pagePath: path,
                groupName: finalData.name,
                citiesId: finalData?.regions?.map(item => +item.value),
                macroregionsId: finalData?.macroregions?.map(item => +item.value),
            });

            if (response.data.errors.length > 0) {
                throw new Error(response.data.errors[0].message);
            }

            const formValues: IData = form.values as IData;

            form.updateValues({
                ...formValues,
                geoGroups: [response.data.data],
            });

            setGeoGroups([response.data.data]);
            cms.alerts.success('Принадлежность добавлена');

            reset();
        } catch (error) {
            if (error instanceof Error) {
                cms.alerts.error(error.message);
            } else {
                console.error(error);
                cms.alerts.error('Произошла ошибка. Попробуйте изменить данные');
            }
        }
    };

    // Выводим ошибки
    useEffect(() => {
        if (errors?.name) cms.alerts.error((errors.name as { message: string }).message);
        if (errors?.regions) cms.alerts.error((errors.regions as { message: string }).message);
        if (errors?.macroregions) cms.alerts.error((errors.macroregions as { message: string }).message);
    }, [errors]);

    // Чистим значения, при добавлении гео элемента
    useEffect(() => {
        reset(null);
        (selectRef.current as { select: { clearValue: () => void } })?.select?.clearValue();
        (selectMacroRef.current as { select: { clearValue: () => void } })?.select?.clearValue();
    }, [reset, geoGroups, geoList]);

    const getNoOptionsMessage = () => {
        if (selectInputValue) return 'По вашему запросу ничего не найдено';
        return 'Введите название города';
    };

    const getNoOptionsMacroMessage = () => {
        if (selectInputMacroValue) return 'По вашему запросу ничего не найдено';
        return 'Введите название региона';
    };

    return (
        <form className={cn.wrapper} onSubmit={handleSubmit(onSubmit)}>
            <div className={cn.field}>
                <label htmlFor="name">Заголовок</label>
                <TinaInput {...register('name')} />
            </div>
            <div className={cn.field}>
                <label htmlFor="regions">Выбор списка городов</label>
                <div className={cn.selectWrapper}>
                    <Controller
                        control={control}
                        name="regions"
                        render={({ field: { onChange } }) => (
                            <Select
                                value={selectValue}
                                ref={selectRef}
                                className={cn.select}
                                options={selectOptions}
                                isMulti
                                onInputChange={onInputChange}
                                classNamePrefix="react-select"
                                noOptionsMessage={getNoOptionsMessage}
                                placeholder="Выберите элемент..."
                                isLoading={isOptionsLoading}
                                onChange={(values: ISelectElement<string>[]) => {
                                    setSelectValue(values);
                                    onChange(values);
                                }}
                            />
                        )}
                    />
                </div>
            </div>

            <div className={cn.field}>
                <label htmlFor="macroregions">Выбор списка макрорегионов</label>
                <div className={cn.selectWrapper}>
                    <Controller
                        control={control}
                        name="macroregions"
                        render={({ field: { onChange } }) => (
                            <Select
                                value={selectMacroValue}
                                ref={selectMacroRef}
                                className={cn.select}
                                options={selectMacroOptions}
                                isMulti
                                onInputChange={onInputMacroChange}
                                classNamePrefix="react-select"
                                noOptionsMessage={getNoOptionsMacroMessage}
                                placeholder="Выберите элемент..."
                                isLoading={isOptionsMacroLoading}
                                onChange={(values: ISelectElement<string>[]) => {
                                    setSelectMacroValue(values);
                                    onChange(values);
                                }}
                            />
                        )}
                    />
                </div>
            </div>

            <div className={cn.button}>
                <TinaButton label="Создать" buttonType="submit" disabled={isSubmitting} />
            </div>
        </form>
    );
};

export class CityCreatorPlugin {
    __type = 'screen';

    name = 'Создание новой принадлежности к регионам';

    Component = () => <CityCreator form={this.form} geoList={this.geoList} cms={this.cms} />;

    Icon = MediaIcon;

    layout = 'popup';

    public form: Form;

    public geoList: IGeoList[];

    public cms: TinaCMS;

    updateGeoList = (geoList: IGeoList[]) => {
        this.geoList = geoList;
    };

    constructor(form: Form, geoList?: IGeoList[], cms?: TinaCMS) {
        this.form = form;
        this.geoList = geoList;
        this.cms = cms;
    }
}
