export const mockRSSSubscriptionData = {
    title: 'RSS-подписка',
    description: 'Новости и события банка – г.Москва',
    successTitle: 'Вы оформили RSS-подписку',
};

export const mockRSSSubscriptionDataTina = {
    title: 'Заголовок',
    description: 'Описание',
    successTitle: 'Финальный заголовок при отправке',
};
