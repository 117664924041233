import { InputProps, wrapFieldsWithMeta } from '@tinacms/fields';
import clsx from 'clsx';
import * as React from 'react';
import { useContext, useEffect, useState } from 'react';

import { LoadingDots } from '@/components/UI/icons/tina/LoadingDots';
import { TrashIcon } from '@/components/UI/icons/tina/TrashIcon';
import MediaStoreContext from '@/context/MediaStoreContext';
import { useTinaLibStore } from '@/hooks/useTina';
import { useTinaPreviewSrc } from '@/hooks/useTinaPreviewSrc';
import { MediaFieldWrapper } from '@/tina/plugins/mediaManager/MediaFieldWrapper';
import { IMediaItem } from '@/types/tina/media/item';
import { IMediaStore } from '@/types/tina/media/store';
import { getQueryParam } from '@/utils/getQueryParam';
import { nextApiRequest } from '@/utils/request';

import cn from './style.module.sass';

interface ImageUploadProps {
    onDrop: (acceptedFiles: any[]) => void;
    onClear?: () => void;
    onClick?: () => void;
    value?: string;
    previewSrc?: string;
    loading?: boolean;
    title?: string;
}

export const ImageUpload: React.FC<ImageUploadProps> = ({
    onDrop,
    onClick,
    value,
    previewSrc,
    loading,
    title,
    onClear,
}) => {
    if (loading) {
        return (
            <MediaFieldWrapper onClick={onClick} onDrop={onDrop} accept="image/*">
                <div className={cn.styledImageContainer}>
                    <div className={cn.imgLoaderWrapper}>
                        <LoadingDots />
                    </div>
                </div>
            </MediaFieldWrapper>
        );
    }

    if (value) {
        return (
            <>
                <div className={cn.wrapper}>
                    <MediaFieldWrapper onClick={onClick} onDrop={onDrop} accept="image/*">
                        <div className={cn.styledImageContainer}>
                            <img
                                alt={title}
                                title={title}
                                className={clsx(
                                    cn.styledImage,
                                    previewSrc?.includes('png') && cn.styledImagePng,
                                    previewSrc?.includes('svg') && cn.styledImageSvg
                                )}
                                src={previewSrc}
                            />
                        </div>
                    </MediaFieldWrapper>
                    <div onClick={onClear} role="presentation" className={cn.deleteButton}>
                        <TrashIcon />
                    </div>
                </div>

                <div className={cn.filename}>{title || <LoadingDots />}</div>
            </>
        );
    }

    return (
        <MediaFieldWrapper onClick={onClick} onDrop={onDrop} accept="image/*">
            <div className={cn.styledImageContainer}>
                <div className={cn.imgPlaceholder}>
                    Drag 'n' drop a file here,
                    <br />
                    or click to select a file
                </div>
            </div>
        </MediaFieldWrapper>
    );
};

interface ImageProps {
    path: string;
    previewSrc?: IMediaStore['previewSrc'];
    uploadDir?: string;
}

export const ImageField = wrapFieldsWithMeta<InputProps, ImageProps>(props => {
    const { persist } = useContext(MediaStoreContext);
    const { Tina } = useTinaLibStore();

    const [title, setTitle] = useState('');

    const { useCMS } = Tina;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const { form, field } = props;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    const { name, value } = props.input as { name: string; value: string };

    const cms = useCMS();

    const [isImgUploading, setIsImgUploading] = useState(false);

    const [src, srcIsLoading] = useTinaPreviewSrc(
        value,
        name,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
        form.getState().values,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        field.previewSrc
    );

    const onChange = (media?: IMediaItem | IMediaItem[]) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
        if (media) props.input.onChange(media);
    };

    const onClear = () => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
        props.input.onChange('');
        setTitle('');
    };

    const onDrop = async ([file]: File[]) => {
        setIsImgUploading(true);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
        const directory = props.field.uploadDir;
        const [media] = await persist([
            {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                directory,
                file,
            },
        ]);

        if (media) {
            try {
                onChange(media);
            } catch (error) {
                console.error('Ошибка при попытке загрузить изображение: ', error);
            } finally {
                setIsImgUploading(false);
            }
        }
    };

    const onClick = () => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
        const directory = props.field.uploadDir;

        cms.events.dispatch({
            type: 'customMedia:open',
            allowDelete: true,
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            directory,
            onSelect: onChange,
            extension: 'image',
        });
    };

    const setImageName = async () => {
        try {
            const id = getQueryParam(value, 'id');
            const response = await nextApiRequest.get<{ data: IMediaItem; errors: unknown[] }>(
                `/store/file/get/source?id=${id}`
            );

            if (response?.data?.errors?.length) throw response.data.errors;

            setTitle(response.data.data.name);
        } catch (errors: unknown) {
            console.error({ errors });
            setTitle('Не удалось получить название файла');
        }
    };

    useEffect(() => {
        setImageName().catch(console.error);
    }, [value]);

    return (
        <ImageUpload
            value={value}
            previewSrc={src}
            loading={isImgUploading || srcIsLoading}
            onClick={onClick}
            onDrop={onDrop}
            onClear={onClear}
            title={title}
        />
    );
});

export const ImageFieldPlugin = {
    __type: 'field',
    name: 'image',
    Component: ImageField,
    parse: (value?: string) => value || '',
};
