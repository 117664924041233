import { AxiosError } from 'axios';
import React, { useState } from 'react';
import { TinaCMS } from 'tinacms';

import { LoadingDots } from '@/components/UI/icons/tina/LoadingDots';
import TinaButton from '@/components/UI/TinaButton';
import { nextApiRequest } from '@/utils/request';

import cn from './style.module.sass';

interface ICityWidgetComponentProps {
    path: string;
    cms?: TinaCMS;
}

const ClearCacheComponent: React.FC<ICityWidgetComponentProps> = ({ path, cms }) => {
    const [isRequestGoing, setIsRequestGoing] = useState(false);

    const onClick = async () => {
        setIsRequestGoing(true);
        try {
            const response: {
                data: { message: string };
            } = await nextApiRequest.get('/cache/delete/auth', { params: { path, type: 'single' } });

            cms?.alerts.success(response.data.message);
        } catch (e) {
            const err = e as AxiosError<{ message: string }>;
            cms?.alerts.error(err.message);
        }
        setIsRequestGoing(false);
    };

    return (
        <div className={cn.selectWrapper}>
            <TinaButton
                onClick={onClick}
                label={isRequestGoing ? <LoadingDots /> : 'Очистить кэш страницы'}
                disabled={isRequestGoing}
            />
        </div>
    );
};

export class ClearCacheWidget {
    __type = 'toolbar:widget';

    name = 'clearCache';

    weight = 6;

    public path: string;

    public cms: TinaCMS;

    constructor(path: string, cms: TinaCMS) {
        this.path = path;
        this.cms = cms;
    }

    component: React.FC = () => <ClearCacheComponent path={this.path} cms={this.cms} />;
}
