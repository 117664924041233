import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useRef, useState } from 'react';

import Button from '@/components/UI/Button';
import { ScrollLink } from '@/components/UI/ScrollLink';
import { useAppStore } from '@/context/AppStoreContext';
import { useLayoutStore } from '@/context/LayoutStoreContext';
import { useWindowScroll } from '@/hooks/useWindowScroll';
import { IProductPanel } from '@/types/pageParams';
import { event } from '@/components/shared/utilities/analytics/metrics';

const ProductPanel: React.FC<IProductPanel> = observer(
    ({ menuTop, button, isActive = false, isHeaderDisabled, isHeaderStatic }) => {
        const [isTinaView] = useAppStore(store => store.isTinaView);
        const { setHeaderSticky, isExternalFrameView, promoHeight } = useLayoutStore();
        const scroll = useWindowScroll(0);
        const [isSticky, setSticky] = useState<boolean>(false);

        const panelRef = useRef<HTMLDivElement>(null);

        const scrollOffset = 50;

        useEffect(() => {
            // Если панель включена, то не фиксирована, в ином случае ориентируемся на isHeaderStatic
            const value = !isActive && !isHeaderStatic;
            setHeaderSticky(value);
        }, [isActive, isHeaderDisabled, isHeaderStatic]);

        useEffect(() => {
            // Если панель включена, то не фиксирована, в ином случае ориентируемся на isHeaderStatic
            const value = !isActive && !isHeaderStatic && !isExternalFrameView;
            setHeaderSticky(value);
        }, [isActive, isHeaderDisabled, isHeaderStatic, isExternalFrameView]);

        useEffect(() => {
            const togglePanel = () => {
                const headerHeight = 144;
                const scrollHeight = promoHeight || headerHeight;

                setSticky(scroll > scrollHeight && !isExternalFrameView);
            };

            togglePanel();
        }, [promoHeight, scroll, isExternalFrameView]);

        // eslint-disable-next-line no-unsafe-optional-chaining
        const index = button?.link?.indexOf('#') + 1;
        const buttonLinkHash = button?.link?.slice(index);

        if (!isActive) return null;
        return (
            <div
                className={clsx(
                    'productPanel',
                    isSticky && 'productPanel--sticky',
                    isTinaView && isSticky && 'productPanel--stickyTina'
                )}
                ref={panelRef}
            >
                <div className="productPanelTop">
                    <div className="productPanelWrap">
                        <div className="productPanelGrid">
                            <div className="productPanelItem productPanelItemMenu">
                                <div className="productPanelMenu">
                                    {menuTop?.map(({ name, link, gtag }) => (
                                        <div className="productPanelMenuItem" key={name} role="presentation">
                                            <ScrollLink
                                                to={link}
                                                offset={-scrollOffset}
                                                activeClass="menuLinkActive"
                                                className="productPanelMenuLink"
                                                onClick={() => {
                                                    if (gtag?.action) event(gtag);
                                                }}
                                            >
                                                {name}
                                            </ScrollLink>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            {buttonLinkHash && button?.name && (
                                <div className="productPanelItem productPanelItemBtn">
                                    <ScrollLink
                                        to={buttonLinkHash}
                                        offset={-scrollOffset}
                                        activeClass="menuLinkActive"
                                        className="btnBlue"
                                    >
                                        <Button
                                            variant="btnBlue"
                                            type="button"
                                            customClass="productPanelButton"
                                            label={button?.name}
                                            animation={false}
                                        />
                                    </ScrollLink>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
);

export default ProductPanel;
