export const mockPageParamsData = {
    productPanel: {
        isActive: true,
        isNoAnimation: false,
        menuTop: [
            {
                name: 'О кредите',
                link: 'promo',
            },
            {
                name: 'Рассчитать кредит',
                link: 'calculation',
            },
            {
                name: 'Как получить кредит',
                link: 'instruction',
            },
            {
                name: 'Тарифы и документы',
                link: 'tariff',
            },
        ],
        button: {
            name: 'Оформить кредит',
            link: '#',
        },
    },
};
