import { mockHeaderDataTina } from '@/data/header';
import { gtagSingleItem, gtagSingleItemCustom } from '@/tina/utils/getGTAGConfig';

export const headerConfig = {
    name: 'header',
    label: 'Шапка',
    component: 'group',
    fields: [
        {
            name: 'logoLink',
            component: 'text',
            label: 'Ссылка в логотипе',
        },
        {
            label: 'Кнопка: Моб. Версия.',
            name: 'mobileButton',
            component: 'group',
            fields: [
                {
                    name: 'text',
                    component: 'text',
                    label: 'Заголовок',
                },
                {
                    name: 'link',
                    component: 'text',
                    label: 'Ссылка',
                },
            ],
        },
        {
            name: 'headerTopMenu',
            component: 'group-list',
            label: 'Верхнее меню',
            itemProps: (item: { name: string }, index) => ({
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                key: index,
                label: item.name,
            }),
            defaultItem: () => mockHeaderDataTina.headerTopMenu[0],
            fields: [
                {
                    name: 'name',
                    component: 'text',
                    label: 'Заголовок',
                },
                {
                    name: 'href',
                    component: 'text',
                    label: 'Ссылка',
                },
                { ...gtagSingleItem },
            ],
        },
        {
            name: 'hiddenHeaderMenu',
            component: 'group-list',
            label: 'Скрытое меню',
            itemProps: (item: { label: string }, index) => ({
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                key: index,
                label: item.label,
            }),
            defaultItem: () => mockHeaderDataTina.hiddenHeaderMenu[0],
            fields: [
                {
                    name: 'label',
                    component: 'text',
                    label: 'Заголовок',
                },
                {
                    name: 'href',
                    component: 'text',
                    label: 'Ссылка',
                },
                { ...gtagSingleItem },
            ],
        },
        {
            name: 'headerMainMenu',
            component: 'group-list',
            label: 'Основное меню',
            itemProps: (item: { name: string }, index) => ({
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                key: index,
                label: item.name,
            }),
            defaultItem: () => mockHeaderDataTina.headerMainMenu[0],
            fields: [
                {
                    name: 'isBusiness',
                    component: 'toggle',
                    label: 'Пункт для бизнес страницы',
                },
                {
                    name: 'code',
                    component: 'text',
                    label: 'Код привязки к выпадающему меню',
                    description: 'При отстуствии будет использоваться Заголовок',
                },
                {
                    name: 'name',
                    component: 'text',
                    label: 'Заголовок',
                },
                {
                    name: 'href',
                    component: 'text',
                    label: 'Ссылка',
                },
                { ...gtagSingleItem },
            ],
        },
        {
            name: 'headerCollapsedMenu',
            component: 'group-list',
            label: 'Выпадающее меню',
            itemProps: (item: { title: string }, index) => ({
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                key: index,
                label: item.title,
            }),
            defaultItem: () => mockHeaderDataTina.headerCollapsedMenu[0],
            fields: [
                {
                    name: 'title',
                    component: 'text',
                    label: 'Заголовок',
                    description: 'Привязка к элементу основного меню. Тип "other" зарезирвирован для "троеточей"',
                },
                {
                    name: 'sections',
                    component: 'group-list',
                    label: 'Секции меню',
                    itemProps: (item: { title: string }, index) => ({
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                        key: index,
                        label: item?.title || 'Секция меню',
                    }),
                    defaultItem: () => mockHeaderDataTina.headerCollapsedMenu[0].sections[0],
                    fields: [
                        {
                            name: 'title',
                            component: 'text',
                            label: 'Заголовок',
                        },
                        {
                            name: 'link',
                            component: 'text',
                            label: 'Ссылка',
                        },
                        {
                            name: 'isMain',
                            component: 'toggle',
                            label: 'Побочный/Главный',
                        },
                        {
                            name: 'withBorder',
                            component: 'toggle',
                            label: 'Граница',
                        },
                        {
                            name: 'type',
                            component: 'select',
                            label: 'Тип блока',
                            options: [
                                {
                                    value: 'links',
                                    label: 'Ссылки',
                                },
                                {
                                    value: 'card',
                                    label: 'Карточка',
                                },
                                {
                                    value: 'cardImg',
                                    label: 'Карточка с картинкой',
                                },
                            ],
                        },
                        {
                            name: 'links',
                            component: 'group-list',
                            label: 'Список ссылок',
                            itemProps: (item: { label: string }, index) => ({
                                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                                key: index,
                                label: item.label,
                            }),
                            defaultItem: () => mockHeaderDataTina.headerCollapsedMenu[0].sections[0].links[0],
                            fields: [
                                {
                                    name: 'label',
                                    component: 'text',
                                    label: 'Заголовок',
                                },
                                {
                                    name: 'href',
                                    component: 'text',
                                    label: 'Ссылка',
                                },
                                {
                                    name: 'typeMark',
                                    component: 'select',
                                    label: 'Специальное предложение',
                                    options: [
                                        {
                                            value: 'isNoMarked',
                                            label: 'Без иконки',
                                        },
                                        {
                                            value: 'isMarked',
                                            label: 'Молния',
                                        },
                                        {
                                            value: 'isMarkedFire',
                                            label: 'Популярное',
                                        },
                                        {
                                            value: 'isMarkedNew',
                                            label: 'Новое',
                                        },
                                    ],
                                },
                                { ...gtagSingleItem },
                            ],
                        },
                        {
                            name: 'card',
                            component: 'group',
                            label: 'Карточка',
                            fields: [
                                {
                                    name: 'title',
                                    component: 'text',
                                    label: 'Заголовок',
                                },
                                {
                                    name: 'desc',
                                    component: 'textarea',
                                    label: 'Описание',
                                },
                                {
                                    name: 'subDesc',
                                    component: 'textarea',
                                    label: 'Дополнительное описание',
                                },
                                {
                                    name: 'href',
                                    component: 'text',
                                    label: 'Ссылка',
                                },
                                {
                                    name: 'button',
                                    component: 'text',
                                    label: 'Текст на кнопке',
                                },
                                {
                                    name: 'buttonType',
                                    component: 'select',
                                    label: 'Текст на кнопке',
                                    options: [
                                        {
                                            label: 'Белая',
                                            value: 'white',
                                        },
                                        {
                                            label: 'Прозрачная',
                                            type: 'transparent',
                                        },
                                    ],
                                },
                                {
                                    name: 'img',
                                    component: 'image',
                                    label: 'Картинка',
                                    parse: (media: { src: string }) => media.src,
                                },
                                {
                                    name: 'isPWA',
                                    component: 'toggle',
                                    label: 'Отобразить в мобильной версии',
                                },
                                { ...gtagSingleItem },
                            ],
                        },
                        {
                            name: 'cardImg',
                            component: 'group',
                            label: 'Карточка с картинкой',
                            fields: [
                                {
                                    name: 'title',
                                    component: 'text',
                                    label: 'Заголовок',
                                },
                                {
                                    name: 'href',
                                    component: 'text',
                                    label: 'Ссылка',
                                },
                                {
                                    name: 'label',
                                    component: 'text',
                                    label: 'Текст на кнопке',
                                },
                                {
                                    name: 'src',
                                    component: 'image',
                                    label: 'Картинка',
                                    parse: (media: { src: string }) => media.src,
                                },
                                { ...gtagSingleItem },
                            ],
                        },
                    ],
                },
            ],
        },
        {
            name: 'pwaMenu',
            component: 'group-list',
            label: 'PWA-меню',
            itemProps: (item: { title: string }, index: number) => ({
                key: index,
                label: item.title,
            }),
            defaultItem: () => mockHeaderDataTina.pwaMenu[0],
            fields: [
                {
                    name: 'title',
                    component: 'text',
                    label: 'Заголовок',
                },
                {
                    name: 'iconTitle',
                    component: 'text',
                    description: 'По умолчанию используется обычный заголовок',
                    label: 'Заголовок на иконке',
                },
                {
                    name: 'icon',
                    component: 'group',
                    label: 'Иконка',
                    fields: [
                        {
                            name: 'light',
                            component: 'group',
                            label: 'Светлая тема',
                            fields: [
                                {
                                    name: 'default',
                                    component: 'image',
                                    label: 'Картинка',
                                    parse: (media: { src: string }) => media.src,
                                },
                                {
                                    name: 'hover',
                                    component: 'image',
                                    label: 'Картинка при наведении',
                                    parse: (media: { src: string }) => media.src,
                                },
                            ],
                        },
                        {
                            name: 'dark',
                            component: 'group',
                            label: 'Темная тема',
                            fields: [
                                {
                                    name: 'default',
                                    component: 'image',
                                    label: 'Картинка',
                                    parse: (media: { src: string }) => media.src,
                                },
                                {
                                    name: 'hover',
                                    component: 'image',
                                    label: 'Картинка при наведении',
                                    parse: (media: { src: string }) => media.src,
                                },
                            ],
                        },
                    ],
                },
                {
                    name: 'toggles',
                    component: 'group-list',
                    label: 'Элементы табов',
                    itemProps: (item: { title: string }, index: number) => ({
                        key: index,
                        label: item.title,
                    }),
                    defaultItem: () => mockHeaderDataTina.pwaMenu[0].toggles[0],
                    fields: [
                        {
                            name: 'title',
                            component: 'text',
                            label: 'Заголовок',
                        },
                        {
                            name: 'code',
                            component: 'text',
                            label: 'Уникальный код',
                        },
                    ],
                },
                {
                    name: 'sections',
                    component: 'group-list',
                    label: 'Элементы PWA-меню',
                    itemProps: (item: { title: string }, index: number) => ({
                        key: index,
                        label: item.title,
                    }),
                    defaultItem: () => mockHeaderDataTina.pwaMenu[0].sections[0],
                    fields: [
                        {
                            name: 'title',
                            component: 'text',
                            label: 'Заголовок',
                        },
                        {
                            name: 'href',
                            component: 'text',
                            label: 'Ссылка',
                        },
                        {
                            name: 'subtitle',
                            component: 'text',
                            label: 'Описание',
                        },
                        {
                            name: 'toggleCode',
                            component: 'text',
                            label: 'Ключ привязки к табам',
                        },
                        {
                            name: 'typeMark',
                            component: 'select',
                            label: 'Специальное предложение',
                            options: [
                                {
                                    value: 'isNoMarked',
                                    label: 'Без иконки',
                                },
                                {
                                    value: 'isMarked',
                                    label: 'Молния',
                                },
                                {
                                    value: 'isMarkedFire',
                                    label: 'Популярное',
                                },
                                {
                                    value: 'isMarkedNew',
                                    label: 'Новое',
                                },
                            ],
                        },
                        {
                            name: 'links',
                            component: 'group-list',
                            label: 'Список ссылок',
                            itemProps: (item: { label: string }, index: number) => ({
                                key: index,
                                label: item.label,
                            }),
                            defaultItem: () => mockHeaderDataTina.pwaMenu[0].sections[0].links[0],
                            fields: [
                                {
                                    name: 'label',
                                    component: 'text',
                                    label: 'Заголовок',
                                },
                                {
                                    name: 'href',
                                    component: 'text',
                                    label: 'Ссылка',
                                },
                                {
                                    name: 'typeMark',
                                    component: 'select',
                                    label: 'Специальное предложение',
                                    options: [
                                        {
                                            value: 'isNoMarked',
                                            label: 'Без иконки',
                                        },
                                        {
                                            value: 'isMarked',
                                            label: 'Молния',
                                        },
                                        {
                                            value: 'isMarkedFire',
                                            label: 'Популярное',
                                        },
                                        {
                                            value: 'isMarkedNew',
                                            label: 'Новое',
                                        },
                                    ],
                                },
                                { ...gtagSingleItem },
                            ],
                        },
                        { ...gtagSingleItem },
                    ],
                },
                {
                    name: 'card',
                    component: 'group',
                    label: 'Карточка',
                    fields: [
                        {
                            name: 'title',
                            component: 'text',
                            label: 'Заголовок',
                        },
                        {
                            name: 'desc',
                            component: 'textarea',
                            label: 'Описание',
                        },
                        {
                            name: 'href',
                            component: 'text',
                            label: 'Ссылка',
                        },
                        {
                            name: 'button',
                            component: 'text',
                            label: 'Текст на кнопке',
                        },
                        {
                            name: 'buttonType',
                            component: 'select',
                            label: 'Текст на кнопке',
                            options: [
                                {
                                    label: 'Белая',
                                    value: 'white',
                                },
                                {
                                    label: 'Прозрачная',
                                    type: 'transparent',
                                },
                            ],
                        },
                        {
                            name: 'img',
                            component: 'image',
                            label: 'Картинка',
                            parse: (media: { src: string }) => media.src,
                        },
                        {
                            name: 'isPWA',
                            component: 'toggle',
                            label: 'Выключить/Включить',
                        },
                        { ...gtagSingleItem },
                    ],
                },
            ],
        },
        {
            name: 'gtag',
            component: 'group',
            label: 'Общие цели шапки',
            fields: [
                { ...gtagSingleItemCustom('logo', 'Логотип') },
                { ...gtagSingleItemCustom('yet', 'Дополнительное меню') },
                {
                    name: 'city',
                    component: 'group',
                    label: 'Город',
                    fields: [
                        { ...gtagSingleItemCustom('open', 'Открытие') },
                        { ...gtagSingleItemCustom('choose', 'Выбор') },
                    ],
                },
                { ...gtagSingleItemCustom('client', 'Стать клиентом') },
                { ...gtagSingleItemCustom('bank', 'Интернет банк') },
                { ...gtagSingleItemCustom('theme', 'Смена темы') },
                { ...gtagSingleItemCustom('map', 'Отделения и банкоматы') },
                { ...gtagSingleItemCustom('oldSite', 'Перейти на старый сайт') },
            ],
        },
        {
            name: 'headerSocial',
            component: 'group',
            label: 'Информация для соц. сети',
            defaultItem: () => ({
                image: '/img-next/svg/social/tg.svg',
                text: 'Мы в Telegram',
                link: '#',
            }),
            fields: [
                {
                    name: 'text',
                    component: 'text',
                    label: 'Текст',
                },
                {
                    name: 'link',
                    component: 'text',
                    label: 'Ссылка',
                },
                {
                    name: 'image',
                    component: 'image',
                    label: 'Картинка',
                    parse: (media: { src: string }) => media.src,
                },
                { ...gtagSingleItem },
            ],
        },
    ],
};
