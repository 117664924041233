import clsx from 'clsx';
import React from 'react';

import cn from './style.module.sass';

export function LoadingDots({ customClass }: { customClass?: string }) {
    return (
        <div className={clsx(cn.dots, customClass)}>
            <div className={cn.dot} />
            <div className={cn.dot} />
            <div className={cn.dot} />
        </div>
    );
}
