import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import path from 'path';
import React, { useContext, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';

import AddFolder from '@/components/AddFolder';
import { LoadingDots } from '@/components/UI/icons/tina/LoadingDots';
import { MediaIcon } from '@/components/UI/icons/tina/MediaIcon';
import TinaButton from '@/components/UI/TinaButton';
import MediaStoreContext from '@/context/MediaStoreContext';
import { useTinaLibStore } from '@/hooks/useTina';
import { CustomPaginatorComponent } from '@/tina/plugins/CustomPagination';
import { MediaBreadcrumbs } from '@/tina/plugins/mediaManager/Breadcrumbs';
import { MediaItem } from '@/tina/plugins/mediaManager/Item';
import Search from '@/tina/plugins/mediaManager/Search';
import { TExtensions } from '@/types/tina/media/extensions';
import { IMediaItem } from '@/types/tina/media/item';
import { IMediaList } from '@/types/tina/media/list';
import { IMediaStore } from '@/types/tina/media/store';

import cn from './style.module.sass';

export interface IMediaRequest {
    directory?: string;
    onSelect?(media: IMediaItem): void;
    close?(): void;
    allowDelete?: boolean;
    extension?: TExtensions;
}

type MediaListState = 'loading' | 'loaded' | 'error';

export const MediaPicker: React.FC<IMediaRequest> = observer(
    ({ allowDelete = true, onSelect, close, extension, ...props }) => {
        const { persist, delete: deleteItem, list: getItemsList, search } = useContext<IMediaStore>(MediaStoreContext);
        const { Tina } = useTinaLibStore();
        const { useCMS } = Tina;
        const cms = useCMS();

        const [listState, setListState] = useState<MediaListState>('loading');
        const [directory, setDirectory] = useState<string | undefined>(props.directory);
        const [offset, setOffset] = useState(0);
        const [uploading, setUploading] = useState(false);
        const [searchEnable, setSearchEnable] = useState(false);
        const [searchValue, setSearchValue] = useState<string>('');
        const [list, setList] = useState<IMediaList>({
            limit: 10,
            offset,
            items: [],
            totalCount: 0,
            nextOffset: undefined,
        });

        const loadMedia = () => {
            setListState('loading');
            getItemsList({ offset, limit: 10, directory, extension })
                .then(fetchedList => {
                    setList(fetchedList);
                    setListState('loaded');
                })
                .catch(e => {
                    console.error(e);
                    setListState('error');
                });
        };

        const onClickMediaItem = (item: IMediaItem) => {
            if (item.type === 'dir') {
                setDirectory(path.join(item.path, item.name));
                setSearchEnable(false);
                setSearchValue('');
                setOffset(0);
            }
        };

        const deleteMediaItem = (item: IMediaItem) => {
            if (allowDelete) {
                if (window.confirm('Вы точно хотите удалить этот файл?'))
                    deleteItem(item, searchEnable).catch(console.error);
            }
        };

        const selectMediaItem = (item: IMediaItem) => {
            if (onSelect) {
                onSelect(item);
                if (close) close();
            }
        };

        const searchHandler = async (value?: string, isInitial?: boolean) => {
            if (!value || value === '0') return;

            setListState('loading');
            setSearchEnable(true);

            if (value) setSearchValue(value);

            try {
                const fetchedList = await search(value || searchValue, {
                    offset: isInitial ? 0 : offset,
                    limit: 10,
                    extension,
                });

                if (isInitial) setOffset(0);
                setList(fetchedList);
                setListState('loaded');
            } catch (e) {
                console.error(e);
                setListState('error');
            }
        };

        const resetSearchHandler = () => {
            setSearchValue('');
            setOffset(0);
            setSearchEnable(false);
        };

        const { getRootProps, getInputProps, isDragActive } = useDropzone({
            accept: 'image/*, video/webm',
            multiple: true,
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onDrop: async files => {
                if (searchEnable) return;

                try {
                    setUploading(true);
                    await persist(
                        files.map(file => ({
                            directory: directory || '/',
                            file,
                        }))
                    );
                } catch {
                    // TODO: Events get dispatched already. Does anything else need to happen?
                }
                setUploading(false);
            },
        });

        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const { onClick, ...rootProps } = getRootProps();

        useEffect(() => {
            if (searchEnable) {
                searchHandler(searchValue).catch(console.error);
                return cms.events.subscribe(['customMedia:search:delete:success'], () => {
                    searchHandler(searchValue).catch(console.error);
                });
            }

            loadMedia();
            return cms.events.subscribe(['customMedia:upload:success', 'customMedia:delete:success'], loadMedia);
        }, [offset, directory]);

        useEffect(() => {
            const body = document?.body;
            body.style.overflow = 'hidden';

            return () => {
                body.style.overflow = 'auto';
            };
        }, []);

        if (listState === 'loading' || uploading) {
            return (
                <div className={cn.loadingList}>
                    <LoadingDots />
                </div>
            );
        }

        if (listState === 'error') {
            return <div className={cn.empty}>Ошибка получения данных, перезагрузите страницу и попробуйте снова</div>;
        }

        const addButtonLabel: JSX.Element | string = uploading ? <LoadingDots /> : 'Загрузить';

        return (
            <div className={cn.wrapper}>
                {!searchEnable && (
                    <div className={cn.top}>
                        <MediaBreadcrumbs directory={directory} setDirectory={setDirectory} />
                        <div className={cn.folder}>
                            <AddFolder />
                        </div>
                        <TinaButton
                            primary
                            busy={uploading}
                            /* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */
                            onClick={onClick}
                            label={addButtonLabel}
                        />
                    </div>
                )}
                <div
                    {...rootProps}
                    className={clsx(cn.container, isDragActive && cn.dragging, searchEnable && cn.containerNoTop)}
                >
                    <input {...getInputProps()} />

                    {listState === 'loaded' && list.items.length === 0 && (
                        <div className={cn.empty}>
                            {searchEnable ? 'Поиск не дал результатов' : 'Перенесите файлы сюда'}
                        </div>
                    )}

                    {list.items.map((item: IMediaItem) => (
                        <MediaItem
                            key={item.id}
                            item={item}
                            allowDelete={allowDelete}
                            shouldSelect={onSelect}
                            onClick={onClickMediaItem}
                            onSelect={selectMediaItem}
                            onDelete={deleteMediaItem}
                        />
                    ))}
                </div>
                <CustomPaginatorComponent list={list} setOffset={setOffset} />
                <Search
                    searchHandler={searchHandler}
                    resetSearchHandler={resetSearchHandler}
                    initialValue={searchValue}
                />
            </div>
        );
    }
);

export const MediaManagerPlugin = {
    __type: 'screen',
    name: 'Медиа Менеджер',
    Component: MediaPicker,
    Icon: MediaIcon,
    props: {
        allowDelete: true,
    },
    layout: 'fullscreen',
};
