import React from 'react';

import Footer from '@/components/layout/Footer/Footer/index';
import { useTinaLibStore } from '@/hooks/useTina';
import { footerConfig } from '@/tina/groups/footer/index.tina';
import { IFooter } from '@/types/footer';

const TinaFooter: React.FC<
    IFooter & { isDisabled?: boolean; liteActiveCode?: string; isFlocktory?: boolean }
> = props => {
    const { TinaInline } = useTinaLibStore();
    const { InlineGroup } = TinaInline;

    return (
        <InlineGroup focusRing={{ offset: 0 }} insetControls name="footer" fields={footerConfig.fields}>
            <Footer {...props} />
        </InlineGroup>
    );
};

export default TinaFooter;
