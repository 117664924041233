interface IParentField {
    _template?: string;
    [name: string]: unknown;
}

// Предназначен для нахождения ближайшего родителя, который имеет то или иное поле. По дефолту ищет ближайший блок
// с _template
export const getParentField = (
    fieldName: string,
    form: { getState: () => { values: { [name: string]: unknown } } },
    filterParam = '_template'
): IParentField => {
    const { values } = form.getState();
    const fieldNameParts = fieldName.split('.');

    let parentField = null;

    for (let i = fieldNameParts.length; i !== 0; i -= 1) {
        const field = fieldNameParts.reduce((acc, part) => acc[part], values) as IParentField;

        if (field?.[filterParam]) {
            parentField = field;
            break;
        }
        fieldNameParts.splice(-1);
    }

    return parentField as IParentField;
};
