export const mockNewsSubscriptionData = {
    title: 'Подписаться на новости',
    description: 'Нажимая кнопку «Подписаться», вы даете согласие на обработку персональных данных',
    successTitle: 'Вы подписались на новости',
};

export const mockNewsSubscriptionDataTina = {
    title: 'Заголовок',
    description: 'Описание',
    successTitle: 'Финальный заголовок при отправке',
};
