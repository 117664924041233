import HtmlEditor from '@/tina/plugins/htmlEditor';
import { gtagSingleItem } from '@/tina/utils/getGTAGConfig';

export const footerConfig = {
    name: 'footer',
    label: 'Подвал',
    component: 'group',
    fields: [
        {
            name: 'lite',
            component: 'group-list',
            label: 'Упрощенный подвал.',
            itemProps: (item: { code: string }, index: number) => ({
                key: index,
                label: item.code,
            }),
            defaultItem: () => ({ code: '', text: 'Текст' }),
            fields: [
                {
                    name: 'code',
                    component: 'text',
                    label: 'Идентификатор футера',
                },
                {
                    name: 'text',
                    component: HtmlEditor,
                    label: 'Текст',
                },
                {
                    name: 'textCollapsible',
                    component: HtmlEditor,
                    label: 'Текст. Выпадающий блок',
                },
            ],
        },
        {
            name: 'products',
            component: 'group-list',
            label: 'Продуктовая область.',
            itemProps: (item: { text: string }, index) => ({
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                key: index,
                label: item.text,
            }),
            defaultItem: () => ({ link: 'Ссылка', text: 'Заголовок' }),
            fields: [
                {
                    name: 'link',
                    component: 'text',
                    label: 'Ссылка',
                },
                {
                    name: 'text',
                    component: 'text',
                    label: 'Текст',
                },
                { ...gtagSingleItem },
            ],
        },
        {
            name: 'users',
            component: 'group-list',
            label: 'Пользовательская область',
            itemProps: (item: { text: string }, index) => ({
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                key: index,
                label: item.text,
            }),
            defaultItem: () => ({ link: 'Ссылка', text: 'Заголовок' }),
            fields: [
                {
                    name: 'link',
                    component: 'text',
                    label: 'Ссылка',
                },
                {
                    name: 'text',
                    component: 'text',
                    label: 'Текст',
                },
                { ...gtagSingleItem },
            ],
        },
        {
            name: 'menu',
            component: 'group-list',
            label: 'Меню сайта.',
            itemProps: (item: { title: string }, index: number) => ({
                key: index,
                label: item.title,
            }),
            defaultItem: () => ({
                title: 'Заголовок',
                list: [
                    {
                        link: '#',
                        text: 'Заголовок',
                    },
                ],
            }),
            fields: [
                {
                    name: 'title',
                    component: 'text',
                    label: 'Заголовок',
                },
                {
                    name: 'list',
                    component: 'group-list',
                    label: 'Элементы меню.',
                    itemProps: (item: { text: string }, index: number) => ({
                        key: index,
                        label: item.text,
                    }),
                    defaultItem: () => ({
                        link: '#',
                        text: 'Заголовок',
                    }),
                    fields: [
                        {
                            name: 'link',
                            component: 'text',
                            label: 'Ссылка',
                        },
                        {
                            name: 'text',
                            component: 'text',
                            label: 'Текст',
                        },
                        { ...gtagSingleItem },
                    ],
                },
            ],
        },
        {
            name: 'phoneText',
            component: 'text',
            label: 'Текст поля "Мобильный телефон"',
        },
        {
            name: 'phone',
            component: 'text',
            label: 'Мобильный телефон',
        },
        {
            name: 'isMobileAppsActive',
            component: 'toggle',
            label: 'Включение/отключение ссылок на мобильные приложения',
        },
        {
            name: 'copyright',
            component: 'textarea',
            label: 'Текст копирайта',
        },
        {
            name: 'isSearchActive',
            component: 'toggle',
            label: 'Включение/отключение строки поиска',
        },
        {
            name: 'isSocialActive',
            component: 'toggle',
            label: 'Включение/отключение соцсетей (vk, facebook, instagram, OK, twitter)',
        },
        {
            name: 'store',
            label: 'Приложения',
            component: 'group',
            fields: [
                {
                    name: 'name',
                    component: 'text',
                    label: 'Название',
                },
                {
                    name: 'desc',
                    component: 'text',
                    label: 'Описание',
                },
                {
                    name: 'link',
                    component: 'text',
                    label: 'Ссылка',
                },
                {
                    name: 'image',
                    component: 'image',
                    label: 'Картинка',
                    parse: (media: { src: string }) => media.src,
                },
                { ...gtagSingleItem },
            ],
        },
        {
            name: 'social',
            component: 'group-list',
            label: 'Соц. сети.',
            itemProps: (item: { name: string }, index) => ({
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                key: index,
                label: item.name,
            }),
            defaultItem: () => ({
                image: '/img-next/svg/social/vk.svg',
                name: 'VK',
                link: '#',
            }),
            fields: [
                {
                    name: 'name',
                    component: 'text',
                    label: 'Текст',
                },
                {
                    name: 'link',
                    component: 'text',
                    label: 'Ссылка',
                },
                {
                    name: 'image',
                    component: 'image',
                    label: 'Картинка',
                    parse: (media: { src: string }) => media.src,
                },
                { ...gtagSingleItem },
            ],
        },
        {
            name: 'logoLink',
            component: 'text',
            label: 'Ссылка в логотипе',
        },
    ],
};
