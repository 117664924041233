import { observer } from 'mobx-react-lite';
import React from 'react';
import ReactPaginate from 'react-paginate';

import { IMediaList } from '@/types/tina/media/list';

import cn from './style.module.sass';

interface ICustomPaginatorComponent {
    list: IMediaList;
    setOffset: (offset: number) => void;
}

export const CustomPaginatorComponent: React.FC<ICustomPaginatorComponent> = observer(({ list, setOffset }) => {
    const numPerPage: number = list.limit;
    const currentPage: number = Math.floor(list.offset / numPerPage);
    const numPages: number = Math.ceil(list.totalCount / numPerPage);

    const onPageChange = ({ selected }) => {
        setOffset(numPerPage * selected);
    };

    if (numPages <= 1) return null;

    return (
        <div className={cn.wrapper}>
            <div className={cn.paginationWrapper}>
                <ReactPaginate
                    previousLabel=""
                    nextLabel=""
                    nextClassName={cn.navigation}
                    previousClassName={cn.navigation}
                    containerClassName={cn.pagination}
                    pageCount={numPages}
                    forcePage={currentPage}
                    activeClassName={cn.itemActive}
                    pageClassName={cn.item}
                    onPageChange={onPageChange}
                    renderOnZeroPageCount={null}
                />
            </div>
        </div>
    );
});
