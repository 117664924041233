import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/layout/DefaultLayout/index.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/layout/TinaLayout/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/UI/Preloader/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TinaLibProvider"] */ "/app/src/hooks/useTina.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AppProvider"] */ "/app/src/providers/AppProvider.tsx");
