import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';

import AnimatedComponent from '@/components/Animation';
import CustomSlider from '@/components/CustomSlider';
import Button from '@/components/UI/Button';
import CustomSelect from '@/components/UI/CustomSelect';
import TabContext from '@/context/TabContext';
import { ITab, ITabsInner } from '@/types/tabsBlock';
import { event } from '@/components/shared/utilities/analytics/metrics';

import Tab from '../Tab';
import cn from './style.module.sass';

const Tabs: React.FC<ITabsInner> = observer(
    ({ items, archive, type = 'main', isMobileOnly, className, button, isCentered }) => {
        const { setActiveTabHandler } = useContext(TabContext);

        if (items?.length === 1) return null;

        const getTitle = (item: ITab) => {
            if (typeof item === 'string') return item;
            return item.title;
        };

        const handleClick = (item: ITab) => {
            if (item?.gtag?.action) {
                event(item?.gtag);
            }
        };

        const getSelectItems = () =>
            items?.map((item, index) => ({
                value: index,
                label: item.title,
            }));

        const onSelectChangeHandler = (item: { value: number; label: string }) => {
            setActiveTabHandler(item.value);
        };

        const onArchiveClick = () => {
            if (archive?.gtag) event(archive?.gtag);
        };

        if (type === 'main') {
            return (
                <AnimatedComponent className={clsx(cn.filter, cn.filterMobileOnly)} classNameActive={cn.animationInit}>
                    <CustomSlider
                        className={clsx(cn.filterGrid, cn.filterGripMobileOnly, isCentered && cn.filterGridCentered)}
                    >
                        <div className={clsx(cn.filterEl, cn.filterElLeft)}>
                            {items?.map((tab, index) => (
                                <Tab {...tab} key={index} index={index} type={type} />
                            ))}
                        </div>
                        {archive?.title && (
                            <div className={clsx(cn.filterEl, cn.filterElRight)}>
                                <a href={archive.link} className={cn.filterLinkArchive} onClick={onArchiveClick}>
                                    {archive.title}
                                </a>
                            </div>
                        )}
                    </CustomSlider>
                </AnimatedComponent>
            );
        }

        if (type === 'select') {
            return (
                <div className={cn.select}>
                    <div className={clsx(cn.selectItem, cn.itemSelect)}>
                        <CustomSelect
                            options={getSelectItems()}
                            onChangeHandler={onSelectChangeHandler}
                            placeholder="Выбрать..."
                        />
                    </div>
                    {button?.title && (
                        <div className={clsx(cn.selectItem, cn.itemBtn)}>
                            <Button
                                variant="btnBlue"
                                type="button"
                                label={button?.title}
                                href={button?.link}
                                customClass="full"
                                size="large"
                            />
                        </div>
                    )}
                </div>
            );
        }

        return (
            <div className={clsx(cn.wrapper, isMobileOnly && cn.mobile)}>
                <div className={clsx(cn.tabs, className)}>
                    <CustomSlider className={cn.list}>
                        {items?.map((item: ITab, index: number) => (
                            <Tab
                                title={getTitle(item)}
                                key={index}
                                index={index}
                                isMobileOnly={isMobileOnly}
                                type={type}
                                handleClick={() => handleClick(item)}
                            />
                        ))}
                    </CustomSlider>
                </div>
            </div>
        );
    }
);

export default Tabs;
