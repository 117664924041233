import React from 'react';

import Document, { IDocument } from '@/components/Document';
import TextField from '@/components/TextField';

import cn from './style.module.sass';

interface ISidebarDocsElement {
    title: string;
    documents: IDocument[];
    button: {
        title: string;
        link: string;
    };
    isNotTitle?: boolean;
}

export interface ISidebarDocs {
    _template?: 'sidebarDocs';
    list: ISidebarDocsElement[];
}

const SidebarDocs: React.FC<ISidebarDocs> = ({ list }) => (
    <div className="sidebarSection">
        <div className={cn.wrapper}>
            {list?.map((item, index) => (
                <div className={cn.item} key={index}>
                    <TextField text={item?.title} name={`list.${index}.title`} isTextArea className={cn.title} />
                    <div className={cn.documents}>
                        {item?.documents?.map((document, documentIndex) => (
                            <Document {...document} key={documentIndex} isSecondary />
                        ))}
                    </div>
                    {item?.button?.title && (
                        <a href={item?.button?.link} className={cn.link}>
                            {item.button.title}
                        </a>
                    )}
                </div>
            ))}
        </div>
    </div>
);

export default SidebarDocs;
