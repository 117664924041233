import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { useId } from 'react';
import Select, { components } from 'react-select';

import AnimatedComponent from '@/components/Animation';
import { useTheme } from '@/context/ThemeProvider';
import { ISelectElement } from '@/types/select';

import cn from './style.module.sass';

export interface IOptions {
    [key: string]: any;
}

export interface ISelect {
    options: IOptions[];
    placeholder?: string;
    onChangeHandler?: (item: IOptions) => void;
    isError?: boolean;
    errorText?: string;
    customClass?: string;
    size?: 'default' | 'small';
    isDisabled?: boolean;
    value?: ISelectElement<string>;
    defaultValue?: ISelectElement<string>;
    showArrow?: boolean;
    isPrivateBank?: boolean;
    isPrivateBankTabs?: boolean;
    inModal?: boolean;
    customOptionFlag?: boolean;
    withPortal?: boolean;
    [name: string]: unknown;
}

const CustomSelect: React.FC<ISelect> = observer(
    ({
        options,
        placeholder,
        onChangeHandler,
        isError,
        errorText,
        customClass,
        isDisabled,
        size,
        value,
        defaultValue,
        showArrow = true,
        isPrivateBank = false,
        isPrivateBankTabs = false,
        inModal = false,
        withPortal = true,
        field,
        customOptionFlag = false,
        ...rest
    }) => {
        const { theme } = useTheme();

        let menuPortal: HTMLElement;

        if (typeof document !== 'undefined' && typeof window !== 'undefined') {
            menuPortal = inModal ? document?.querySelector('#modalWrapper') : document?.querySelector('div#wrapper');
        }

        const menuPortalTarget = typeof window !== 'undefined' && menuPortal ? menuPortal : '';

        const id = useId();

        const Option = props => (
            <div className={cn.wrapperOption}>
                <components.Option {...props}>
                    {/* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,react/destructuring-assignment */}
                    <span>{props.value}</span>
                    <br />
                    <span
                        className={cn.optionDesc}
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access,react/destructuring-assignment
                        dangerouslySetInnerHTML={{ __html: props.data.desc }}
                    />
                </components.Option>
            </div>
        );

        return (
            <>
                <div
                    className={clsx(
                        cn.wrapper,
                        theme === 'dark' && cn.dark,
                        size === 'small' && cn.small,
                        isError && cn.wrapperError,
                        customClass,
                        isPrivateBank && cn.private,
                        isPrivateBankTabs && cn.privateTabs
                    )}
                >
                    <Select
                        /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
                        // @ts-ignore
                        key={defaultValue}
                        defaultValue={defaultValue}
                        options={options}
                        disabled={isDisabled}
                        onChange={onChangeHandler}
                        className={clsx(cn.input, !isError && cn.valid, showArrow && cn.showArrow)}
                        placeholder={placeholder}
                        menuPortalTarget={withPortal ? (menuPortalTarget as HTMLElement) : null}
                        classNamePrefix="select-filters"
                        value={value}
                        styles={{ menuPortal: base => ({ ...base, zIndex: 1100 }) }}
                        id={id}
                        instanceId={id}
                        components={customOptionFlag ? { Option } : null}
                        {...rest}
                    />
                </div>
                {isError && (
                    <AnimatedComponent
                        key={errorText}
                        className={clsx(cn.error, isPrivateBank && cn.private)}
                        classNameActive={cn.errorAnimate}
                    >
                        {errorText}
                    </AnimatedComponent>
                )}
            </>
        );
    }
);

export default CustomSelect;
