/* eslint-disable */
// @ts-nocheck

import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';

import { FieldPlugin } from 'tinacms';
import cn from './style.module.sass';
import { apiRequest } from '@/utils/request';
import { observer } from 'mobx-react-lite';
import TinaInput from '@/components/UI/TinaInput';
import { get } from 'lodash';
import { ImageField } from '@/tina/plugins/ImageField';

type Category = { value: number; label: string };

export const PboCategoryField = observer((props: any) => {
    const { form, tinaForm, input, field, meta } = props;
    const parentName = field.name.split('.').slice(0, -1).join('.');
    const blockName = field.name.split('.').at(-1);

    const getCurrentFieldConfig = () => {
        const index = field.name?.match(/.\d./gm)?.[0]?.split('.')[1];
        const path = `values.blocks[${index}]`;
        return get(tinaForm, path);
    };

    const getInitialCategoryName = () => {
        const config = getCurrentFieldConfig();
        return config?.pboCategory?.name;
    };

    const getInitialCategoryOption = () => {
        const config = getCurrentFieldConfig();

        if (config?.pboCategory?.id) {
            return config?.pboCategory?.id;
        }

        if (config?.pboCategory?.isNew) {
            return 'new';
        }

        return 'none';
    };

    const [categories, setCategories] = useState<Category[]>([]);
    const [value, setValue] = useState(getInitialCategoryOption);
    const [categoryName, setCategoryName] = useState(getInitialCategoryName);
    const [isLoading, setIsLoading] = useState(false);

    const getCategoryData = (selectedOption: string, categoryId: number = null, categoryName: string = null) => {
        let id = null;
        let name = null;
        let isNew = false;

        switch (selectedOption) {
            case 'new':
                isNew = true;
                name = categoryName;
                break;
            case 'none':
                break;
            default:
                name = categoryName;
                id = categoryId;
        }
        return {
            id,
            name,
            isNew,
        };
    };

    const updateCategory = (option: string) => {
        const { isNew, id, name } = getCategoryData(option, option, categoryName);

        form.change(`${parentName}.pboCategory.isNew`, isNew);
        form.change(`${parentName}.pboCategory.id`, id);
        form.change(`${parentName}.pboCategory.name`, name);
    };

    const onSelect = async (e: ChangeEvent<HTMLInputElement>) => {
        const selectedOption = e.target.value;
        setValue(selectedOption);
        updateCategory(selectedOption);
    };

    const getCategoryList = async () => {
        try {
            setIsLoading(true);
            const {
                data: { data: options },
            } = await apiRequest(`/faqpbo/categories`);
            setCategories(options);
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    const onCategoryNameChange = async (e: ChangeEvent<HTMLInputElement>) => {
        const parentName = field.name.split('.').slice(0, -1).join('.');
        const name = `${parentName}.pboCategory.name`;
        const value = e.target.value;

        setCategoryName(value);
        form.change(name, value);
    };

    useEffect(() => {
        getCategoryList().catch();
    }, []);

    return (
        <>
            <div className={cn.wrapper}>
                <label htmlFor={input.name}>{field.label}</label>
                <div className={cn.select}>
                    <select {...input} value={value} onChange={onSelect}>
                        <option value="new" selected={value === 'new'}>
                            Создать новую категорию
                        </option>
                        <option value="none" selected={value === 'none'}>
                            Элементы без категории
                        </option>
                        {categories?.map((option, index) => {
                            return (
                                <option
                                    key={`${index} - ${option.value}`}
                                    value={option.value}
                                    selected={value === option.value}
                                >
                                    {option.label}
                                </option>
                            );
                        })}
                    </select>
                </div>
                {isLoading && <div className={cn.preloader} />}
            </div>
            {value === 'new' && (
                <div className={cn.wrapper}>
                    <label htmlFor={input.name}>Название новой категории</label>
                    <div className={cn.select}>
                        <TinaInput onChange={onCategoryNameChange} value={categoryName} />
                    </div>
                    <div className={cn.select}>
                        <ImageField
                            tinaForm={tinaForm}
                            form={form}
                            meta={meta}
                            field={field}
                            input={{ ...input, value: getCurrentFieldConfig()?.[blockName]?.src }}
                        />
                    </div>
                </div>
            )}
        </>
    );
});

export const PboCategoryFieldPlugin: FieldPlugin = {
    __type: 'field',
    name: 'pboCategory',
    Component: PboCategoryField,
};
