'use client';

import { ModalProvider } from '@tinacms/react-modals';
import axios from 'axios';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import dynamic from 'next/dynamic';
import { usePathname, useRouter } from 'next/navigation';
import React, { useEffect, useMemo } from 'react';

import { InlineLayout } from '@/components/layout/TinaLayout/inline';
import { useAppStore } from '@/context/AppStoreContext';
import MediaStoreContext from '@/context/MediaStoreContext';
import { useTheme } from '@/context/ThemeProvider';
import Feature from '@/domain/feature/Feature';
import MediaStore from '@/domain/mediaStore/MediaStore';
import { useTinaLibStore } from '@/hooks/useTina';
import { BlocksFieldPlugin } from '@/tina/plugins/BlocksField';
import { ClearAllCacheWidget } from '@/tina/plugins/ClearAllCachePlugin';
import { ClearCacheWidget } from '@/tina/plugins/ClearCachePlugin';
import { ConditionalFieldPlugin } from '@/tina/plugins/ConditionalField';
import { ImageFieldPlugin } from '@/tina/plugins/ImageField';
import { InfoBlockFieldPlugin } from '@/tina/plugins/InfoBlockField';
import { MediaManagerPlugin } from '@/tina/plugins/mediaManager/MediaManager';
import { MediaManagerSidebar } from '@/tina/plugins/mediaManager/Sidebar';
import { PboCategoryFieldPlugin } from '@/tina/plugins/PboCategoryField';
import { SectionFieldPlugin } from '@/tina/plugins/SectionField';
import { TagElementFieldPlugin } from '@/tina/plugins/TagElementField';
import { TagListFieldPlugin } from '@/tina/plugins/TagListField';
import { TimeFieldPlugin } from '@/tina/plugins/TimePicker';
import { WebmFieldPlugin } from '@/tina/plugins/WebmField';
import { IData } from '@/types/data';
import { IMediaStore } from '@/types/tina/media/store';

import cn from './style.module.sass';

interface IProps {
    data: IData;
    children?: React.ReactNode;
}

const GoToAdminButton = dynamic(() => import('@/components/GoToAdminButton'), { ssr: false });

const TinaLayout: React.FC<IProps> = observer(({ data, children }) => {
    const asPath = usePathname();
    const isNewEditor = Feature.isFeatureEnabled('isNewEditor');

    const { Tina, ReactTinacmsEditor, DateFieldPlugin } = useTinaLibStore();
    const { TinaCMS, TinaProvider } = Tina;
    const [path] = useAppStore(store => store.path);
    const { theme, setInitialTheme } = useTheme();
    const { meta, schemaData, id } = data;

    const router = useRouter();

    // Инициализация инстанса TinaCMS.
    const cms = useMemo(
        () =>
            new TinaCMS({
                sidebar: {
                    position: 'overlay',
                },
                toolbar: true,
                enabled: true,
                plugins: [
                    ConditionalFieldPlugin,
                    SectionFieldPlugin,
                    PboCategoryFieldPlugin,
                    InfoBlockFieldPlugin,
                    TagListFieldPlugin,
                    TagElementFieldPlugin,
                    DateFieldPlugin,
                    WebmFieldPlugin,
                    TimeFieldPlugin,
                ],
            }),
        []
    );

    if (!isNewEditor && ReactTinacmsEditor) {
        const { HtmlFieldPlugin } = ReactTinacmsEditor;
        cms.plugins.add(HtmlFieldPlugin);
    }

    // Замена MediaStore на кастомный вариант
    cms.plugins.remove({
        __type: 'screen',
        name: 'Media Manager',
    });
    cms.plugins.add(MediaManagerPlugin);

    // Замена поля image на кастомный вариант
    cms.plugins.remove({
        __type: 'field',
        name: 'image',
    });
    cms.plugins.add(ImageFieldPlugin);

    // Замена поля blocks на кастомный вариант
    cms.plugins.remove({
        __type: 'field',
        name: 'blocks',
    });

    cms.plugins.add(BlocksFieldPlugin);

    if (Feature.isFeatureEnabled('enableCacheWidget')) {
        // Виджет очистки кэша страницы
        const ClearCacheWidgetInstance = useMemo(() => new ClearCacheWidget(path, cms), []);
        cms.plugins.add(ClearCacheWidgetInstance);
    }

    if (Feature.isFeatureEnabled('enableAllCacheWidget')) {
        // Виджет очистки всего кэша
        const ClearAllCacheWidgetInstance = useMemo(() => new ClearAllCacheWidget(path, cms), []);
        cms.plugins.add(ClearAllCacheWidgetInstance);
    }

    // Инициализация инстанса MediaStore
    const MediaStoreInstance = useMemo(() => new MediaStore(cms.events as unknown as IMediaStore['events']), []);

    const onLogOut = () => {
        axios
            .get('/api/reset-preview')
            .then(() => {
                window.location.reload();
            })
            .catch(error => {
                throw new Error(error);
            });
    };

    const onSave = () => {
        console.log('router', router);

        router.refresh();

        setTimeout(() => {
            window.location.reload();
        }, 1000);

        window.location.reload();
    };

    useEffect(() => {
        cms.events.subscribe('cms:disable', onLogOut);
    }, [cms.events]);

    useEffect(() => {
        setInitialTheme(data?.pageParams?.darkTheme, asPath);
    }, [data?.pageParams?.darkTheme, asPath]);

    // возможно это стоит отрефакторить, когда сюда смержится ветка по блокировке с помощью редиса
    useEffect(() => {
        if (typeof window !== 'undefined') {
            document.addEventListener('save-click', onSave);
        }

        return () => {
            if (typeof window !== 'undefined') {
                document.removeEventListener('save-click', onSave);
            }
        };
    }, []);

    return (
        <>
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/*  @ts-ignore */}
            <ModalProvider>
                {/* <CustomHead meta={meta} schemaData={schemaData} /> */}
                <div
                    className={clsx(
                        cn.wrapper,
                        'wrapper',
                        theme === 'dark' && 'dark',
                        theme === 'dark' && cn.dark,
                        'tinaView'
                    )}
                    id="wrapper"
                >
                    <MediaStoreContext.Provider value={MediaStoreInstance}>
                        <TinaProvider cms={cms}>
                            <InlineLayout data={data} />
                            {children}
                            {/* Костыль, тянущийся из библиотеки, который позволяет открыть хранилище через компоненты */}
                            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                            {/*  @ts-ignore */}
                            <ModalProvider>
                                <MediaManagerSidebar />
                            </ModalProvider>
                        </TinaProvider>
                    </MediaStoreContext.Provider>
                </div>
            </ModalProvider>
            <GoToAdminButton id={id} />
        </>
    );
});

export default TinaLayout;
