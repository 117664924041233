/* eslint-disable */
// @ts-nocheck
// @todo убрать коменты выше. Подумать над тем, как убрать строогое стравнение с полем "Создать новый элемент"

import React, { useEffect, useState } from 'react';

import { FieldPlugin } from 'tinacms';
import cn from './style.module.sass';
import { apiRequest } from '@/utils/request';
import { getParentField } from '@/tina/utils/getParentField';

export const InfoBlockField = ({ form, tinaForm, input, field }: any) => {
    const [options, setOptions] = useState(null);
    const [isRequestGoing, setIsRequestGoing] = useState(false);

    const template = getParentField(input.name, form)._template;

    const onChange = async e => {
        input.onChange({
            ...e,
            target: {
                ...e.target,
                value: e.target.value ? e.target.value : null,
            },
        });
    };

    const getInfoBlocks = async () => {
        setIsRequestGoing(true);

        const {
            data: { data: fetchedInfoBlocks },
        } = await apiRequest(`/iblocks`);

        const options = fetchedInfoBlocks.reduce((acc, item) => {
            return [
                ...acc,
                {
                    label: item.name,
                    value: item.apiCode,
                },
            ];
        }, []);

        setOptions(options);
        setIsRequestGoing(false);
    };

    useEffect(() => {
        getInfoBlocks(template).catch();
    }, []);

    return (
        <div className={cn.wrapper}>
            <label htmlFor={input.name}>{field.label}</label>
            <div className={cn.select}>
                <select {...input} onChange={onChange}>
                    <option value={null}>Не выбран</option>
                    {options &&
                        options.map((option, index) => {
                            return (
                                <option key={index} value={option.value}>
                                    {option.label}
                                </option>
                            );
                        })}
                </select>
            </div>
            {isRequestGoing && <div className={cn.preloader} />}
        </div>
    );
};

export const InfoBlockFieldPlugin: FieldPlugin = {
    __type: 'field',
    name: 'infoBlock',
    Component: InfoBlockField,
};

// Пример использования
//
// fields: [
//     {
//         name: 'title',
//         component: 'text',
//         label: 'Заголовок',
//     },
//     {
//          name: 'infoBlock',
//          component: 'infoBlock',
//          required: true,
//          label: 'Инфоблок',
//      },
// ];
