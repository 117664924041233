export const mockPopularExchangeRatesBlockData = {
    name: 'Популярные курсы валют',
    desc: 'Курсы валют на 27.08.21 ',
    list: [
        {
            title: 'USD',
            desc: 'Доллар США',
            buy: {
                value: '72.141',
                flag: true,
                difference: '0.02',
            },
            sale: {
                value: '75.841',
                flag: true,
                difference: '0.02',
            },
        },
        {
            title: 'EUR',
            desc: 'Евро',
            buy: {
                value: '84.881',
                flag: true,
                difference: '0.04',
            },
            sale: {
                value: '89.234',
                flag: true,
                difference: '0.06',
            },
        },
    ],
};

export const mockPopularExchangeRatesBlockDataTina = {
    section: null,
    name: 'Заголовок',
    desc: 'Описание',
    list: [
        {
            title: 'Название',
            desc: 'Описание',
            buy: {
                value: 'Значение',
                flag: true,
                difference: 'Значение',
            },
            sale: {
                value: 'Значение',
                flag: true,
                difference: 'Значение',
            },
        },
        {
            title: 'Название',
            desc: 'Описание',
            buy: {
                value: 'Значение',
                flag: true,
                difference: 'Значение',
            },
            sale: {
                value: 'Значение',
                flag: true,
                difference: 'Значение',
            },
        },
        {
            title: 'Название',
            desc: 'Описание',
            buy: {
                value: 'Значение',
                flag: true,
                difference: 'Значение',
            },
            sale: {
                value: 'Значение',
                flag: true,
                difference: 'Значение',
            },
        },
        {
            title: 'Название',
            desc: 'Описание',
            buy: {
                value: 'Значение',
                flag: true,
                difference: 'Значение',
            },
            sale: {
                value: 'Значение',
                flag: true,
                difference: 'Значение',
            },
        },
    ],
};
