import React from 'react';

import { TinaSidebarButton } from '@/components/blocks/sidebar/SidebarButton/index.tina';
import { mockSidebarButtonBlockDataTina } from '@/data/blocks/sidebar/sidebarButton';

export const sidebarButtonBlock = {
    Component: props => <TinaSidebarButton {...props} />,
    template: {
        label: 'Кнопка',
        defaultItem: () => mockSidebarButtonBlockDataTina,
        fields: [
            {
                name: 'text',
                component: 'text',
                label: 'Заголовок',
            },
            {
                name: 'link',
                component: 'text',
                label: 'Ссылка',
            },
        ],
    },
};
