import clsx from 'clsx';
import { usePathname, useRouter } from 'next/navigation';
import React, { useEffect, useMemo, useState } from 'react';

// eslint-disable-next-line import/no-cycle
import TabsLayout from '@/components/blocks/tabsBlock/layouts/TabsLayout';
import { withBlockLinks } from '@/components/HOC/withBlockLinks';
import Tabs from '@/components/tabs/Tabs';
import TextField from '@/components/TextField';
import TabContext from '@/context/TabContext';
import { ITab, ITabs } from '@/types/tabsBlock';

import cn from './style.module.sass';

type SimpleParamObject = Record<string, string>;

function getSearchParams(searchString: string): SimpleParamObject {
    const result = {};

    new URLSearchParams(searchString).forEach((value, key) => {
        result[key] = value;
    });

    return result;
}

function convertSearchParams(obj: SimpleParamObject): string {
    const result = '?';

    const str = Object.entries(obj)
        .map(([key, value]) => `${key}=${value}`)
        .join('&');

    return result + str;
}

const TabsBlock: React.FC<ITabs> = ({ tabs, title, archive, type = 'main', button, isCentered = false }) => {
    const router = useRouter();
    const [activeTab, setActiveTab] = useState<number>(0);

    const pathname = usePathname();

    const setActiveTabHandler = (tab: number) => {
        let searchParams: SimpleParamObject = {};

        try {
            const { location } = document;
            searchParams = getSearchParams(location.search);
        } catch (error) {
            if (error instanceof Error) {
                console.error(`Tabs error: `, error.message);
            }
        }

        const getParam = tabs[tab]?.getParamTitle;

        if (searchParams?.tab !== undefined && searchParams?.tab === getParam) {
            return;
        }

        if (getParam?.length > 0) {
            searchParams.tab = getParam;

            const param = convertSearchParams(searchParams);

            router.push(`${pathname}${param}`);
            setActiveTab(tab);
        } else {
            setActiveTab(tab);
        }
    };

    const isFullWidthActiveTab = tabs?.find((tab, index) => index === activeTab);
    const isFullWidthCondition = isFullWidthActiveTab?.isFullWidth;

    const mappedTabs = useMemo(
        () =>
            tabs?.map(tab => ({
                gtag: tab?.gtag,
                title: tab?.tabTitle,
                getParamTitle: tab?.getParamTitle,
            })) as ITab[],
        [tabs]
    );

    useEffect(() => {
        try {
            const { location } = document;
            const tab = tabs?.findIndex(
                item => item?.getParamTitle && item?.getParamTitle === new URL(location?.href).searchParams.get('tab')
            );

            const currentTab = tab >= 0 ? tab : 0;

            if (currentTab !== activeTab) {
                setActiveTab(currentTab);
            }
        } catch (error) {
            if (error instanceof Error) {
                console.error(`Tabs error: `, error.message);
            }
        }
    }, []);

    return (
        <div className={clsx(cn.tabs, 'section')}>
            <TabContext.Provider value={{ activeTab, setActiveTabHandler }}>
                <div className={cn.fullWrapper}>
                    <div className={clsx(cn.wrapper, type === 'secondary' && cn.wrapperSecondary, 'tabs')}>
                        {title && <TextField text={title} name="title" htmlValue={title} isHTML className={cn.title} />}
                        <div className={clsx(cn.tabsWrapper, type === 'main' && cn.tabsWrapperMain)}>
                            {/* @todo */}
                            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                            {/* @ts-expect-error */}
                            <Tabs
                                items={mappedTabs}
                                type={type}
                                isCentered={isCentered}
                                archive={archive}
                                button={button}
                            />
                        </div>
                    </div>
                </div>
                <div className={clsx(cn.fullWrapper, isFullWidthCondition && cn.fullWidthWrapper)}>
                    <div className={clsx(cn.wrapper, type === 'secondary' && cn.wrapperSecondary, 'tabs')}>
                        <TabsLayout tabs={tabs} />
                    </div>
                </div>
            </TabContext.Provider>
        </div>
    );
};

export default withBlockLinks(TabsBlock);
