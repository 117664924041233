import React from 'react';

import { TinaSidebarAuthor } from '@/components/blocks/sidebar/SidebarAuthor/index.tina';
import { mockSidebarAuthorBlockDataTina } from '@/data/blocks/sidebar/sidebarAuthor';

export const sidebarAuthorBlock = {
    Component: props => <TinaSidebarAuthor {...props} />,
    template: {
        label: 'Автор статьи',
        defaultItem: () => mockSidebarAuthorBlockDataTina,
        fields: [
            {
                name: 'personName',
                component: 'text',
                label: 'Имя автора',
            },
            {
                name: 'personPosition',
                component: 'text',
                label: 'Позиция',
            },
            {
                name: 'img',
                component: 'image',
                label: 'Фото автора',
                parse: (media: { src: string }) => media.src,
            },
        ],
    },
};
