import React from 'react';

import SidebarAnchor from '@/components/SidebarAnchor';
import { ISidebarAnchorLinksProps } from '@/components/SidebarAnchor/ComponentVariants/iSidebarAnchorLinks';

export interface ISidebarAnchorLinks extends ISidebarAnchorLinksProps {
    _template?: 'sidebarAnchorLinks';
}

const SidebarAnchorLinks: React.FC<ISidebarAnchorLinks> = ({
    list,
    rollableBlockName,
    isRollable = false,
    checked = false,
}) => {
    if (!list || list?.length <= 0) return null;
    return (
        <SidebarAnchor list={list} rollableBlockName={rollableBlockName} isRollable={isRollable} checked={checked} />
    );
};

export default SidebarAnchorLinks;
