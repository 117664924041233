import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';

import TabContext from '@/context/TabContext';
import { TTabsType } from '@/types/tabsBlock';

import cn from './style.module.sass';

interface IProps {
    title: string;
    index: number;
    isMobileOnly?: boolean;
    handleClick?: () => void;
    type?: TTabsType;
}

const Tab: React.FC<IProps> = observer(({ title, index, isMobileOnly, handleClick, type }) => {
    const { activeTab, setActiveTabHandler } = useContext(TabContext);

    const setCurrentTabActive = () => {
        setActiveTabHandler(index);
    };

    const onClick = () => {
        setCurrentTabActive();
        if (handleClick) handleClick();
    };

    const isCurrentTabActive = activeTab === index;

    if (type === 'main') {
        return (
            <button
                type="button"
                className={clsx(cn.filterItem, cn.filterItemMobileOnly, isCurrentTabActive && cn.active)}
                onClick={onClick}
            >
                <span className={cn.filterLink}>{title}</span>
            </button>
        );
    }

    return (
        <div
            onClick={onClick}
            role="presentation"
            className={clsx(cn.tab, isMobileOnly && cn.mobile, isCurrentTabActive && cn.active)}
        >
            <div className={cn.inner}>{title}</div>
        </div>
    );
});

export default Tab;
