import * as React from 'react';
import { ReactNode } from 'react';
import { useDropzone } from 'react-dropzone';

import cn from '@/tina/plugins/ImageField/style.module.sass';

interface IMediaFieldWrapper {
    onDrop: (acceptedFiles: any[]) => void;
    onClear?: () => void;
    onClick?: () => void;
    accept: string;
    children: ReactNode;
}

export const MediaFieldWrapper: React.FC<IMediaFieldWrapper> = ({ onDrop, onClick, accept, children, ...rest }) => {
    const { getRootProps, getInputProps } = useDropzone({
        accept,
        onDrop,
        noClick: !!onClick,
    });

    return (
        <div {...getRootProps} role="presentation" className={cn.dropArea} onClick={onClick}>
            <input {...getInputProps()} />
            {children}
        </div>
    );
};
