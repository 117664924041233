import { useContext, useEffect, useState } from 'react';

import MediaStoreContext from '@/context/MediaStoreContext';
import { IMediaStore } from '@/types/tina/media/store';

export const useTinaPreviewSrc = (
    value: string,
    fieldName: string,
    formValues: any,
    getPreviewSrc?: IMediaStore['previewSrc']
): [string, boolean] => {
    const { previewSrc } = useContext(MediaStoreContext);

    const getSrc = getPreviewSrc || previewSrc;
    const [src, setSrc] = useState('');
    const [loading, setLoading] = useState(true);

    const asyncGetSrc = async () => {
        try {
            const newSrc: string = await getSrc(value, fieldName, formValues);
            setSrc(newSrc);
            setLoading(false);
        } catch (e) {
            console.error(e);
        }
    };

    useEffect(() => {
        asyncGetSrc().catch(console.error);
    }, [value]);

    return [src, loading];
};
