/* eslint-disable */
// @ts-nocheck
// @todo убрать коменты выше. Подумать над тем, как убрать строогое стравнение с полем "Создать новый раздел"

import React, { useEffect, useMemo, useState } from 'react';

import { FieldPlugin } from 'tinacms';
import cn from './style.module.sass';
import { apiRequest } from '@/utils/request';
import { mockDataConfig } from '@/tina/config/mock.config';
import { getParentField } from '@/tina/utils/getParentField';
import { observer } from 'mobx-react-lite';
import { useStore } from '@/hooks/useStore';
import TinaInput from '@/components/UI/TinaInput';
import { get } from 'lodash';
import { useAppStore } from '@/context/AppStoreContext';

export const SectionField = observer((props: any) => {
    const { form, tinaForm, input, field } = props;

    const initialSectionName = useMemo(() => {
        const index = field.name?.match(/.\d./gm)?.[0]?.split('.')[1];
        const path = `values.blocks[${index}].sectionName`;
        return get(tinaForm, path) || '';
    }, [tinaForm]);

    const [options, setOptions] = useState(null);
    const [value, setValue] = useState(() => input.value ?? 0);
    const [sectionName, setSectionName] = useState(initialSectionName);
    const [isRequestGoing, setIsRequestGoing] = useState(false);
    const [path] = useAppStore(store => store.path);

    const { withInfoBlock } = field;
    const parentField = getParentField(input.name, form);
    const template = parentField._template;

    const getData = async selectedOption => {
        try {
            if (['Создать новый раздел', '0'].includes(selectedOption)) {
                return mockDataConfig?.[template];
            }

            const {
                data: { data: fetchedData },
            } = await apiRequest(`/page/block?path=${path}&block=${template}&section=${selectedOption}`);

            return fetchedData;
        } catch (e) {
            console.error(e);
        }
    };

    const getInfoBlockSections = async (apiCode: string) => {
        const {
            data: { data: fetchedData },
        } = await apiRequest(`iblock/${apiCode}/sections`);

        const options = fetchedData.reduce((acc, item) => {
            return [
                ...acc,
                {
                    label: item.name,
                    value: item.id,
                },
            ];
        }, []);

        setOptions(options);
    };

    const onChange = async e => {
        const selectedOption = e.target.value;

        setIsRequestGoing(true);

        setValue(e.target.value);

        if (withInfoBlock) {
            input.onChange({
                ...e,
                target: {
                    ...e.target,
                    value: e.target.value ? e.target.value : null,
                },
            });
        }

        if (!withInfoBlock) {
            const fetchedData = await getData(selectedOption);
            const keys = Object.keys(fetchedData);
            const values = Object.values(fetchedData);
            const parentName = field.name.split('.').slice(0, -1).join('.');

            for (let i = 0; i < keys.length; i += 1) {
                const name = parentName + '.' + keys[i];
                const value = values[i];
                form.change(name, value);
            }

            form.change(parentName + '.isNewBlock', false);

            if (selectedOption === 'Создать новый раздел') {
                form.change(parentName + '.section', null);
                form.change(parentName + '.isNewBlock', true);
            }
        }

        setIsRequestGoing(false);
    };

    const getSectionsList = async (template: string) => {
        const {
            data: { data: fetchedOptions },
        } = await apiRequest(`/page/block/sections?path=${path}&block=${template}`);

        const options = fetchedOptions.reduce((acc, item) => {
            return [
                ...acc,
                {
                    label: item.name,
                    value: item.id,
                },
            ];
        }, []);

        setOptions(options);
    };

    const onSectionNameChange = async e => {
        const parentName = field.name.split('.').slice(0, -1).join('.');
        const name = parentName + '.' + 'sectionName';
        const value = e.target.value;

        setSectionName(value);
        form.change(name, value);

        input.onChange(null);
    };

    useEffect(() => {
        if (!withInfoBlock) getSectionsList(template).catch();
    }, []);

    useEffect(() => {
        if (!withInfoBlock) return;

        if (parentField?.apiCode) {
            getInfoBlockSections(parentField?.apiCode).catch(console.error);
            return;
        }

        setOptions(null);
    }, [parentField?.apiCode]);

    return (
        <>
            <div className={cn.wrapper}>
                <label htmlFor={input.name}>{field.label}</label>
                <div className={cn.select}>
                    <select {...input} value={value} onChange={onChange}>
                        {!withInfoBlock && <option value={null}>Создать новый раздел</option>}
                        <option value="0">Элементы без раздела</option>
                        {options &&
                            options.map((option, index) => {
                                return (
                                    <option key={`${index} - ${option.value}`} value={option.value}>
                                        {option.label}
                                    </option>
                                );
                            })}
                    </select>
                </div>
                {isRequestGoing && <div className={cn.preloader} />}
            </div>
            {!withInfoBlock && !input?.value && input?.value !== 0 && (
                <div className={cn.wrapper}>
                    <label className={cn.labelSecondary} htmlFor={input.name}>
                        Название нового раздела
                    </label>
                    <div className={cn.select}>
                        <TinaInput onChange={onSectionNameChange} value={sectionName} />
                    </div>
                </div>
            )}
        </>
    );
});

export const SectionFieldPlugin: FieldPlugin = {
    __type: 'field',
    name: 'section',
    Component: SectionField,
};

// Пример использования
//
// fields: [
//     {
//         name: 'title',
//         component: 'text',
//         label: 'Заголовок',
//     },
//     {
//          name: 'section',
//          component: 'section',
//          required: true,
//          label: 'Инфоблок',
//      },
// ];
