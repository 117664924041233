import clsx from 'clsx';
import React from 'react';

import CustomImage from '@/components/CustomImage';
import TextField from '@/components/TextField';

import cn from './style.module.sass';

export interface ISidebarAuthor {
    _template?: 'sidebarAuthor';
    personName: string;
    personPosition?: string;
    img?: string;
}

const SidebarAuthor: React.FC<ISidebarAuthor> = ({ personName, personPosition, img }) => (
    <div className={clsx(cn.wrapper, 'sidebarSection')}>
        <div className={clsx(cn.innerWrapper)}>
            {img && (
                <div className={cn.imageWrapper}>
                    <div className={cn.image}>
                        <CustomImage src={img} alt={personName} mockWidth="96" mockHeight="96" fill />
                    </div>
                </div>
            )}

            <div className={cn.info}>
                <div className={cn.desc}>Автор статьи</div>
                <TextField text={personName} name="personName" className={cn.name} />
                {personPosition && <TextField text={personPosition} name="personPosition" className={cn.position} />}
            </div>
        </div>
    </div>
);

export default SidebarAuthor;
