import { observer } from 'mobx-react-lite';
import React from 'react';

import cn from './style.module.sass';

export interface ISidebarButton {
    _template: 'sidebarButton';
    text: string;
    link: string;
}

const SidebarButton: React.FC<ISidebarButton> = observer(({ text, link }) => (
    <div className="sidebarSection">
        <div className={cn.wrapper}>
            <a href={link} className={cn.button}>
                {text}
            </a>
        </div>
    </div>
));

export default SidebarButton;
