import React from 'react';

export function AddIcon() {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="inherit" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.9524 4.89689L14.9524 26.8016H16.7461L16.7461 4.89689H14.9524Z" />
            <path d="M4.8969 16.7461H26.8016L26.8016 14.9523H4.89689L4.8969 16.7461Z" />
        </svg>
    );
}
