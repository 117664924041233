import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo } from 'react';
import Select from 'react-select';
import { FieldPlugin } from 'tinacms';

import { getParentField } from '@/tina/utils/getParentField';
import { ITag } from '@/types/tags';
import { CustomFieldConfig } from '@/types/tina/fields';

import cn from './style.module.sass';

interface ISelectValue {
    value: string;
    label: string;
}

export const TagElementField = observer(({ form, input, field }: CustomFieldConfig) => {
    const parentField = getParentField(input.name, form, field.parentName);
    const options = parentField?.[field.parentName] as ITag[];
    // Для Детальной страницы статьи поле должно быть с единичным выбором
    const isMulti = parentField && 'isMulti' in parentField ? !!parentField.isMulti : true;
    const initialFormat = useMemo(() => {
        if (!options?.length || !input.value) return '';

        return options?.reduce((acc: ISelectValue[], item) => {
            if ((input.value as string[]).includes(item.xmlId)) {
                return [...acc, { label: item.value, value: item.xmlId }];
            }
            return acc;
        }, []);
    }, [options, input.value]);

    const selectValues = useMemo(() => {
        if (!options?.length) return undefined;

        return options?.reduce((acc: ISelectValue[], item) => [...acc, { label: item.value, value: item.xmlId }], []);
    }, [options]);

    const formatToTinaValues = (values: ISelectValue[]) => {
        const safeValues = Array.isArray(values) ? values : [values];
        return safeValues?.reduce((acc: ITag[], item) => [...acc, item.value], []);
    };
    const parseValue = () => {
        if (!options?.length) return input.value;

        return (input.value as string[]).reduce((acc: string[], item) => {
            if (options.some(option => option.xmlId === item)) return [...acc, item];
            return acc;
        }, []);
    };

    const onChange = values => {
        const tags = formatToTinaValues(values);
        input.onChange(tags);
    };

    useEffect(() => {
        if ((input.value as string[])?.length) input.onChange(parseValue());
    }, [options]);

    return (
        <div className={cn.wrapper}>
            <label htmlFor={input.name}>{field.label}</label>
            <div className={cn.selectWrapper}>
                <Select
                    options={selectValues}
                    isMulti={isMulti}
                    onChange={onChange}
                    className={cn.select}
                    classNamePrefix="react-select"
                    noOptionsMessage={() => 'Нет доступных элементов'}
                    placeholder="Выберите элемент..."
                    defaultValue={initialFormat as ISelectValue[]}
                />
            </div>
        </div>
    );
});

export const TagElementFieldPlugin: FieldPlugin = {
    __type: 'field',
    name: 'tagElement',
    Component: TagElementField,
};

// Пример использования. Компонент используется в дуэте с TagListField. Важным параметром привязки является parentName
// который означаем name в поле tagList. tagList не должен быть вложен в группу отдельно от tagElement
//
// fields: [
//     {
//         name: 'title',
//         component: 'text',
//         label: 'Заголовок',
//     },
//     {
//         name: 'tagList',
//         component: 'tagList',
//         label: 'Заголовок',
//     },
//     {
//         label: 'Список',
//         name: 'list',
//         component: 'group-list',
//         fields: [
//         {
//             name: 'tags',
//             component: 'tagElement',
//             parentName: "tagList"
//             label: 'Заголовок',
//         },
//     }
