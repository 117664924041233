import { AxiosError } from 'axios';
import clsx from 'clsx';
import React, { useRef, useState } from 'react';
import { TinaCMS } from 'tinacms';

import { LoadingDots } from '@/components/UI/icons/tina/LoadingDots';
import { MoreActionsIcon } from '@/components/UI/icons/tina/MoreActionsIcon';
import TinaButton from '@/components/UI/TinaButton';
import useOnClickOutside from '@/hooks/useOnClickOutside';
import { nextApiRequest } from '@/utils/request';

import cn from './style.module.sass';

interface IClearAllCacheProps {
    path: string;
    cms?: TinaCMS;
}

const ClearAllCacheComponent: React.FC<IClearAllCacheProps> = ({ path, cms }) => {
    const [isRequestGoing, setIsRequestGoing] = useState(false);
    const [actionMenuVisibility, setActionMenuVisibility] = useState(false);

    const ref = useRef<HTMLDivElement>();

    const onClick = async () => {
        setIsRequestGoing(true);

        try {
            const response: {
                data: { message: string };
            } = await nextApiRequest.get('/cache/delete/auth', { params: { path, type: 'all' } });

            cms?.alerts.success(response.data.message);
        } catch (error) {
            const err = error as AxiosError<{ message: string }>;
            cms?.alerts.error(err.response.data.message);
        }

        setIsRequestGoing(false);
    };

    useOnClickOutside(ref, () => setActionMenuVisibility(false));

    return (
        <div className={cn.selectWrapper} ref={ref}>
            <button
                type="button"
                className={clsx(cn.burger, actionMenuVisibility && cn.open)}
                onClick={() => setActionMenuVisibility(p => !p)}
            >
                <MoreActionsIcon />
            </button>
            <div className={clsx(cn.buttonWrapper, actionMenuVisibility && cn.open)}>
                <TinaButton
                    onClick={onClick}
                    label={isRequestGoing ? <LoadingDots customClass="primaryDots" /> : 'Очистить весь кэш'}
                    disabled={isRequestGoing}
                    primary
                    customClass={clsx(cn.buttonFull, isRequestGoing && cn.disabled)}
                />
            </div>
        </div>
    );
};

export class ClearAllCacheWidget {
    __type = 'toolbar:widget';

    name = 'clearAllCache';

    weight = 7;

    public path: string;

    public cms: TinaCMS;

    constructor(path: string, cms: TinaCMS) {
        this.path = path;
        this.cms = cms;
    }

    component: React.FC = () => <ClearAllCacheComponent path={this.path} cms={this.cms} />;
}
