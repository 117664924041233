import { Form } from '@tinacms/forms';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import Select, { StylesConfig } from 'react-select';
import { TinaCMS } from 'tinacms/build/tina-cms';

import { useAppStore } from '@/context/AppStoreContext';
import { useGeoStore } from '@/context/GeoStoreContext';
import { updatePageValues } from '@/tina/utils/updatePageValues';
import { IData, IResponse } from '@/types/data';
import { IGeoGroups } from '@/types/geoGroups';
import { IGeoList } from '@/types/geoList';
import { IBXResponse } from '@/types/requests';
import { IColorSelectElement, ISelectElement } from '@/types/select';
import { apiRequest, nextApiRequest } from '@/utils/request';

import cn from './style.module.sass';

interface ICityWidgetComponentProps {
    form: Form;
    geoList: IGeoList[];
    initialData: IData;
    cms: TinaCMS;
}

interface ColourOption {
    readonly value: string;
    readonly label: string;
    readonly isExists?: boolean;
}

const CityWidgetComponent: React.FC<ICityWidgetComponentProps> = observer(
    ({ geoList = [], form, initialData, cms }) => {
        const [path] = useAppStore(store => store.path);
        const { geoGroups, setGeoGroups } = useGeoStore();

        const [existsGeoGroups, setExistsGeoGroups] = useState<string[]>([]);

        const [options, setOptions] = useState<IColorSelectElement<number | string>[]>([
            {
                value: 'default',
                label: 'Без привязки',
                isExists: false,
            },
        ]);

        const [selectValue, setSelectValue] = useState<ISelectElement<number | string>>(options[0]);

        useEffect(() => {
            const fetchInitialGeoGroups = async () => {
                try {
                    const response: IBXResponse<IGeoGroups[]> = await apiRequest(`/page/geo/list?q=${path}`);

                    setGeoGroups(response.data.data);
                } catch (e) {
                    console.error(e);
                }
            };

            fetchInitialGeoGroups().catch(console.error);
        }, []);

        useEffect(() => {
            const fetchExistsGeoGroups = async () => {
                try {
                    const response: IBXResponse<string[]> = await apiRequest(`/geopagelist?path=${path}`);

                    setExistsGeoGroups(response?.data?.data);
                } catch (e) {
                    console.error(e);
                }
            };

            fetchExistsGeoGroups().catch(console.error);
        }, []);

        useEffect(() => {
            if (geoGroups.length > 0) {
                setOptions([
                    {
                        value: 'default',
                        label: 'Без привязки',
                        isExists: false,
                    },
                    ...geoGroups.map(item => ({
                        value: item.id,
                        label: item.name,
                        isExists: existsGeoGroups?.filter(elem => elem === item.xmlId).length > 0,
                    })),
                ]);
            }
        }, [geoGroups, existsGeoGroups]);

        const colourStyles: StylesConfig<ColourOption, boolean> = {
            option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
                ...styles,
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,no-nested-ternary
                backgroundColor: isDisabled
                    ? undefined
                    : // eslint-disable-next-line no-nested-ternary
                    isSelected
                    ? 'rgb(34, 150, 254)'
                    : // eslint-disable-next-line no-nested-ternary
                    isFocused
                    ? '#BBD6F6'
                    : // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                    data.isExists
                    ? 'rgba(0, 184, 217, 0.1)'
                    : undefined,

                color: '#001E45',

                borderBottom: '1px solid #E5E8EC',

                cursor: isDisabled ? 'not-allowed' : 'pointer',
            }),
        };

        const onChange = async (item: ISelectElement<number | string>) => {
            const geoPath = item.value === 'default' ? '' : `&geoGroup=${item.value}`;
            const updateGeoPath = item.value === 'default' ? '' : item.value;

            try {
                const response: IResponse = await nextApiRequest(`/page?path=${path}&isAdmin=true${geoPath}`);

                const {
                    data: { data: fetchedData },
                } = response;

                if (response.data.errors.length || response.data.data.notFound) {
                    throw new Error();
                }

                form.updateValues(fetchedData);
                // eslint-disable-next-line no-param-reassign
                form.onSubmit = async (values: IData) => {
                    await updatePageValues(values, path, cms, fetchedData, updateGeoPath.toString());
                };
                setSelectValue(item);
            } catch (e) {
                cms.alerts.error('Произошла ошибка при получении данных, перезагрузите страницу и попробуйте снова');
            }
        };

        useEffect(() => {
            // Подписка на ресет формы
            form.finalForm.subscribe(
                ({ pristine }) => {
                    if (pristine) {
                        setSelectValue(options[0]);
                        // eslint-disable-next-line no-param-reassign
                        form.onSubmit = async (values: IData) => {
                            await updatePageValues(values, path, cms, initialData);
                        };
                    }
                },
                { pristine: true }
            );
        }, []);

        return (
            <div className={cn.selectWrapper}>
                <Select
                    options={options}
                    value={selectValue}
                    styles={colourStyles}
                    className={cn.select}
                    classNamePrefix="react-select"
                    noOptionsMessage={() => 'Нет доступных элементов'}
                    onChange={onChange}
                    placeholder="Выберите элемент..."
                />
            </div>
        );
    }
);

export class CityWidget {
    __type = 'toolbar:widget';

    name = 'cityWidget';

    weight = 5;

    public form: Form;

    public geoList: IGeoList[] = [];

    public initialData: IData;

    public cms: TinaCMS;

    addGeoList = (geoList: IGeoList[] = []) => {
        this.geoList = [...this.geoList, ...geoList];
    };

    changeInitialData = (newData: IData) => {
        this.initialData = newData;
    };

    constructor(form: Form, geoList: IGeoList[], initialData: IData, cms: TinaCMS) {
        this.form = form;
        this.initialData = initialData;
        this.cms = cms;
    }

    component: React.FC = () => (
        <CityWidgetComponent form={this.form} geoList={this.geoList} initialData={this.initialData} cms={this.cms} />
    );
}
