import { Form } from '@tinacms/forms';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import dynamic from 'next/dynamic';
import React, { useEffect, useMemo, useState } from 'react';

import TinaBreadcrumbs from '@/components/Breadcrumbs/index.tina';
import TinaFooter from '@/components/layout/Footer/Footer/index.tina';
import Header from '@/components/layout/Header/Header';
import SectionTitle from '@/components/layout/SectionTitle';
import ProductPanel from '@/components/ProductPanel';
import { useAppStore } from '@/context/AppStoreContext';
import { useBreadcrumbs } from '@/context/BreadcrumbsProvider';
import InlineFormContext from '@/context/InlineFormContext';
import MetricsStore from '@/domain/metrics/Store';
import { useResize } from '@/hooks/useResize';
import { useTinaLibStore } from '@/hooks/useTina';
import { tabsBlock } from '@/tina/blocks/tabs';
import { tinaBlocksConfig } from '@/tina/config/index.tina.config';
import { sidebarBlocksConfigTina } from '@/tina/config/sidebar/sidebar.tina.config';
import { CityCreatorPlugin } from '@/tina/plugins/CityCreatorPlugin';
import { CityWidget } from '@/tina/plugins/CityWidget';
import { getBlocks } from '@/tina/utils/getBlocks';
import setTinaConfig from '@/tina/utils/setTinaConfig';
import { IData } from '@/types/data';
import { TABLET_WIDTH } from '@/utils/constants';

interface IProps {
    data: IData;
}

const PWAMenu = dynamic(() => import('@/components/PWAMenu/PWAMenu'));

export const InlineLayout: React.FC<IProps> = observer(({ data }) => {
    const { Tina, TinaInline } = useTinaLibStore();
    const [path] = useAppStore(store => store.path);
    const { hydrate: breadcrumbsHydrate } = useBreadcrumbs();
    const width = useResize();
    const [isPWAMenuActive, setIsPWAMenuActive] = useState(false);

    const { InlineForm, InlineBlocks } = TinaInline;
    const { useForm, usePlugin, useCMS } = Tina;

    const cms = useCMS();
    const config = setTinaConfig('Главная страница', data, path, cms);
    const [formData, form] = useForm<IData>(config);
    usePlugin(form);

    const currentPageBlocks = useMemo(() => getBlocks({ ...tinaBlocksConfig, tabs: tabsBlock }), []);
    const sidebarBlocks = useMemo(() => getBlocks(sidebarBlocksConfigTina), []);

    // Виджет с быбором геолокацией. Toolbar.
    const CityWidgetInstance = useMemo(() => new CityWidget(form as unknown as Form, formData?.geoList, data, cms), []);
    cms.plugins.add(CityWidgetInstance);

    // Виджет с возможностью добавления геолокаций. Sidebar.
    const CityCreatorPluginInstance = useMemo(
        () => new CityCreatorPlugin(form as unknown as Form, formData?.geoList, cms),
        []
    );
    cms.plugins.add(CityCreatorPluginInstance);

    useEffect(() => {
        const newGeoList = formData?.geoList?.filter(
            item => !CityWidgetInstance?.geoList.find(widgetItem => widgetItem.id === item.id)
        );

        CityWidgetInstance.addGeoList(newGeoList);
        CityCreatorPluginInstance.updateGeoList(CityWidgetInstance.geoList);
    }, [formData?.geoList]);

    useEffect(() => {
        if (width) setIsPWAMenuActive(width <= TABLET_WIDTH);
    }, [width]);

    useEffect(() => {
        breadcrumbsHydrate(formData.breadcrumbs);
    }, [breadcrumbsHydrate, formData.breadcrumbs]);

    useEffect(() => {
        MetricsStore.hydrate(formData.gaParams);
    }, [formData.gaParams]);

    return (
        <InlineFormContext.Provider value={form as unknown as Form}>
            <InlineForm form={form}>
                {formData.header && (
                    <Header
                        {...formData.header}
                        {...formData.pageParams?.header}
                        isBusinessPage={formData?.pageParams?.isBusinessPage}
                        liteHeaderDesktop={formData?.pageParams?.header?.liteHeaderDesktop}
                        liteHeaderMobile={formData?.pageParams?.header?.liteHeaderMobile}
                        isLiteHeader={formData?.pageParams?.header?.isLiteHeader}
                    />
                )}
                <div className={clsx('layoutMiddle', formData?.sidebar?.isActive && 'sidebarView')}>
                    {formData.breadcrumbs && <TinaBreadcrumbs {...formData.breadcrumbs} />}
                    <SectionTitle meta={formData.meta} />
                    <div className="layoutInner">
                        <div className="layoutContent">
                            <InlineBlocks name="blocks" blocks={currentPageBlocks} />
                        </div>
                        {formData?.sidebar?.isActive && (
                            <div className="layoutSidebar">
                                <InlineBlocks name="sidebar.blocks" blocks={sidebarBlocks} />
                            </div>
                        )}
                    </div>
                </div>
                {formData.pageParams?.productPanel && (
                    <ProductPanel
                        {...formData.pageParams.productPanel}
                        isHeaderStatic={formData.pageParams?.header?.isStatic}
                        isHeaderDisabled={formData.pageParams?.header?.isDisabled}
                    />
                )}
                {isPWAMenuActive && (
                    <PWAMenu
                        data={formData.header?.pwaMenu}
                        gtag={formData.header?.gtag}
                        isPWAHidden={formData.pageParams?.isPWAHidden}
                        isFlocktory={formData.pageParams?.isFlocktory}
                    />
                )}
                {formData.footer && (
                    <TinaFooter
                        {...formData.footer}
                        isDisabled={formData.pageParams?.footer?.isDisabled}
                        liteActiveCode={formData.pageParams?.footer?.liteActiveCode}
                        isFlocktory={formData.pageParams?.isFlocktory}
                    />
                )}
            </InlineForm>
        </InlineFormContext.Provider>
    );
});
