import { makeAutoObservable } from 'mobx';

import { IMediaItem, IMediaUploadOptions } from '@/types/tina/media/item';
import { IMediaList, IMediaListOptions } from '@/types/tina/media/list';
import { IMediaStore } from '@/types/tina/media/store';
import { nextApiRequest } from '@/utils/request';

interface INavigation {
    currentPage: number;
    pageCount: number;
    recordCount: number;
}

interface IFetchedData {
    list: IMediaItem[];
    navigation: INavigation;
}

class MediaStore implements IMediaStore {
    events: IMediaStore['events'] = null;

    persist = async (files: IMediaUploadOptions[]): Promise<IMediaItem[]> => {
        try {
            this.events.dispatch({ type: 'customMedia:upload:start' });
            const formData = new FormData();

            files.reduce((acc, file) => {
                formData.append('file[]', file.file);
                return acc;
            }, formData);

            const { directory } = files[0];

            const formedDirectory = directory !== '/' ? `${directory}/` : directory;

            await nextApiRequest.post(`/store/file/add?path=${formedDirectory}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            this.events.dispatch({
                type: 'customMedia:upload:success',
            });

            return null;
        } catch (e) {
            this.events.dispatch({
                type: 'customMedia:upload:failure',
            });

            return null;
        }
    };

    previewSrc = (src: string) => src;

    getCorrectPath = (directory: string) => {
        if (!directory) return '/';
        if (directory.slice(0, 1) !== '/') return `/${directory}/`;
        return `${directory}/`;
    };

    search = async (value: string, options: Exclude<IMediaListOptions, 'directory'>): Promise<IMediaList> => {
        try {
            const DEFAULT_LIMIT = 10;
            const offset: number = options?.offset ?? 0;
            const limit: number = DEFAULT_LIMIT;
            const nextOffset: number = offset + limit;
            const currentPage: number = Math.floor(offset / limit) + 1;

            this.events.dispatch({ type: 'customMedia:list:start' });

            const {
                data: { data: fetchedData },
            }: {
                data: {
                    data: IFetchedData;
                };
            } = await nextApiRequest.get(`/store/search`, {
                params: {
                    q: value,
                    page: currentPage,
                    extension: options?.extension,
                },
            });

            this.events.dispatch({ type: 'customMedia:list:success' });

            return {
                items: fetchedData?.list,
                limit,
                offset,
                totalCount: fetchedData?.navigation?.recordCount,
                nextOffset: nextOffset < fetchedData?.navigation?.recordCount ? nextOffset : undefined,
            };
        } catch (e) {
            this.events.dispatch({ type: 'customMedia:list:failure', ...options });
            return null;
        }
    };

    list = async (options: IMediaListOptions): Promise<IMediaList> => {
        try {
            this.events.dispatch({ type: 'customMedia:list:start', ...options });
            const DEFAULT_LIMIT = 10;
            const offset: number = options?.offset ?? 0;
            const limit: number = DEFAULT_LIMIT;
            const nextOffset: number = offset + limit;
            const directory = this.getCorrectPath(options.directory);
            const currentPage: number = Math.floor(offset / limit) + 1;

            const {
                data: { data: fetchedData },
            }: {
                data: {
                    data: IFetchedData;
                };
            } = await nextApiRequest.get('/store/list', {
                params: { directory, page: currentPage, extension: options?.extension },
            });

            this.events.dispatch({ type: 'customMedia:list:success', ...options });

            return {
                items: fetchedData?.list,
                limit,
                offset,
                totalCount: fetchedData?.navigation?.recordCount,
                nextOffset: nextOffset < fetchedData?.navigation?.recordCount ? nextOffset : undefined,
            };
        } catch (e) {
            this.events.dispatch({ type: 'customMedia:list:failure', ...options });
            return null;
        }
    };

    delete = async (media: IMediaItem, searchEnable?: boolean) => {
        const eventName = searchEnable ? 'customMedia:search:delete' : 'customMedia:delete';

        try {
            this.events.dispatch({ type: `${eventName}:start` });
            const { id, type } = media;

            const part = type === 'dir' ? 'directory' : 'file';

            await nextApiRequest.delete(`/store/${part}/delete`, {
                params: {
                    id,
                },
            });

            this.events.dispatch({
                type: `${eventName}:success`,
            });
        } catch (e) {
            this.events.dispatch({
                type: `${eventName}:failure`,
            });
            console.error(e);
        }
    };

    constructor(events: IMediaStore['events']) {
        this.events = events;
        makeAutoObservable(this);
    }
}

export default MediaStore;
