import { mockMainPrizes } from '@/components/blocks/draw/main-prizes/mock';
import { mockParticipationList } from '@/components/blocks/draw/participation-list/mock';
import { mockAboutPBBlockTinaData } from '@/data/blocks/aboutPB';
import { mockActionPensionsBlockDataTina } from '@/data/blocks/actionPensions';
import { mockAdvantagesBlockTinaData, mockSpecialAdvantagesBlockData } from '@/data/blocks/advantages';
import { mockAllExchangeRatesBlockDataTina } from '@/data/blocks/allExchangeRates';
import { mockAnimatedPromoBlockTinaData } from '@/data/blocks/animatedPromo';
import { mockApplicationAppsBlockDataTina } from '@/data/blocks/applicationApps';
import { mockArticlesDetailBlockTinaData } from '@/data/blocks/articlesDetail';
import { mockArticlesListForDetail } from '@/data/blocks/articlesListForDetail';
import { mockBankAdvantagesBlockDataTina } from '@/data/blocks/bankAdvantages';
import { mockBankTextBlockDataTina } from '@/data/blocks/bankText';
import { mockBiographyBlockDataTina } from '@/data/blocks/biogpraphy';
import { buildingCalculator } from '@/data/blocks/calculators/building';
import { buildingCalculatorWithPicture } from '@/data/blocks/calculators/buildingCalculatorWithPicture';
import { mockCashCreditCalculatorData } from '@/data/blocks/calculators/cashcredit';
import { mockCashCreditCalculatorV2Data } from '@/data/blocks/calculators/cashcredit_v2';
import { mockCreditRealEstateCalculatorData } from '@/data/blocks/calculators/creditRealEstate';
import { guaranteeCalculator } from '@/data/blocks/calculators/guarantee';
import { moneyBoxCalculatorData } from '@/data/blocks/calculators/moneyBox';
import { moneyBoxAccountCalculatorData } from '@/data/blocks/calculators/moneyBoxAccount';
import {
    profitabilityCalculatorCapital,
    profitabilityCalculatorCumulative,
    profitabilityCalculatorHotSeasons,
    profitabilityCalculatorHotSeasonsNewYear,
    profitabilityCalculatorPension,
    profitabilityCalculatorProfit,
} from '@/data/blocks/calculators/profiltability';
import { mockCampaignBlockBlockTinaData } from '@/data/blocks/campaignBlock';
import { mockCardsBlockDataTina } from '@/data/blocks/cards';
import { mockCashbackBlockTinaData } from '@/data/blocks/cashback';
import { mockColoredBannerBlockDataTina } from '@/data/blocks/coloredBanner';
import { mockCorgisBlockData } from '@/data/blocks/corgis';
import { mockCreditGiftBlockBlockDataTina } from '@/data/blocks/creditGiftBlock';
import { mockCreditSliderBlockDataTina } from '@/data/blocks/creditSlider';
import { mockCrmOffersLinkBlockTinaData } from '@/data/blocks/crmOffers';
import { mockDarkBannerBlockDataTina } from '@/data/blocks/darkBanner';
import { mockDiagramBlockTinaData } from '@/data/blocks/diagram';
import { mockDictionaryBlockDataTina } from '@/data/blocks/dictionary';
import { mockExampleBlockDataTina } from '@/data/blocks/example';
import { mockFaqBlockDataTina } from '@/data/blocks/faq';
import { mockFeedbackFormBlockDataTina } from '@/data/blocks/feedbackForm';
import { mockTariffBlockDataTina } from '@/data/blocks/groupedTariffs';
import { mockGuaranteedPrizesBlockDataTina } from '@/data/blocks/guaranteedPrizes';
import { mockHeadManagementBlockDataTina } from '@/data/blocks/headManagement';
import { mockHrFaqBlockDataTina } from '@/data/blocks/hrFaq';
import { mockIndicationBlockDataTina } from '@/data/blocks/indication';
import { mockInfoBlockTinaData } from '@/data/blocks/info';
import { mockInfoBgBlockBlockDataTina } from '@/data/blocks/infoBgBlock';
import { mockInfoBlockPensiyaBlockDataTina } from '@/data/blocks/infoBlockPensiya';
import { mockInfoCardBlockTina } from '@/data/blocks/infoCard';
import { mockInformationCenterBlockDataTina } from '@/data/blocks/informationCenter';
import { mockInfoTransitionBlockTinaData } from '@/data/blocks/infoTransitionBlock';
import { mockInstructionBlockTinaData } from '@/data/blocks/instruction';
import { mockInstructionInfoGraphicBlockTinaData } from '@/data/blocks/instructionInfoGraphic';
import { mockInsuranceBlockDataTina } from '@/data/blocks/insurance';
import { mockIntroPromoDataTina } from '@/data/blocks/introPromo';
import { mockLightBannerBlockDataTina } from '@/data/blocks/lightBanner';
import { mockLinkingListBlockDataTina } from '@/data/blocks/linkingList';
import { mockLinksBlockTinaData } from '@/data/blocks/links';
import { mockMainArticlesSliderBlockTinaData } from '@/data/blocks/mainArticlesSlider';
import { mockMainSliderBlockTinaData } from '@/data/blocks/mainSlider';
import { mockManagementMockData } from '@/data/blocks/management';
import { mockManagementInfoBlockTinaData } from '@/data/blocks/managementInfo';
import { mockMediaPublicationsDataTina } from '@/data/blocks/mediaPublicatons';
import { mockMobileAppBlockTinaData } from '@/data/blocks/mobileApp';
import { mockNewsBlockDataTina } from '@/data/blocks/news';
import { mockOffersCreditsBlockDataTina } from '@/data/blocks/offersCredits';
import { mockOurTargetsMockData } from '@/data/blocks/ourTargets';
import { mockParametersBondsBlockTinaData } from '@/data/blocks/parametersBonds';
import { mockPartnersBlockDataTina } from '@/data/blocks/partners';
import { mockPartnersMapDataTina } from '@/data/blocks/partnersMap';
import { mockPayPlatformsDataTina } from '@/data/blocks/payPlatforms';
import { mockPboFaqDetailTinaData } from '@/data/blocks/pboFaqDetail';
import { mockPeriodInfoBlockTinaData } from '@/data/blocks/periodInfo';
import { mockPersonsBlockDataTina } from '@/data/blocks/persons';
import { mockPhotoDateBlockBlockDataTina } from '@/data/blocks/photoDateBlock';
import { mockPhotoSliderDataTina } from '@/data/blocks/photoSlider';
import { mockPopularExchangeRatesBlockDataTina } from '@/data/blocks/popularExchangeRates';
import { mockPossibilitiesBlockTinaData } from '@/data/blocks/possibilities';
import { mockPromoBlockDataTina } from '@/data/blocks/promo';
import { mockPromoPensionBlockData } from '@/data/blocks/promoPension';
import { mockPromoSuperBlockData } from '@/data/blocks/promoSuper';
import { mockPromoV2DataTina } from '@/data/blocks/promov2';
import { mockPurchasesBlockDataTina } from '@/data/blocks/purchases';
import { mockQuestionsBlockDataTina } from '@/data/blocks/questions';
import { mockQuoteHrBlockDataTina } from '@/data/blocks/quoteHr';
import { mockQuotesCarouselMockData } from '@/data/blocks/quotesCarousel';
import { mockRequisitesBlockDataTina } from '@/data/blocks/requisites';
import { mockRightButtonTextBlockDataTina } from '@/data/blocks/rightButtonText';
import { mockSafelyBlockTinaData } from '@/data/blocks/safely';
import { mockSelectableDataTina } from '@/data/blocks/selectableWithValue';
import { mockServicesBlockTinaData } from '@/data/blocks/services';
import { mockSidebarDocsBlockDataTina } from '@/data/blocks/sidebar/sidebarDocs';
import { mockSingleDocumentsBlockDataTina } from '@/data/blocks/singleDocuments';
import { mockSocialsBlockDataTina } from '@/data/blocks/socials';
import { mockStepSelectionBlockDataTina } from '@/data/blocks/stepSelection';
import { mockStretchBlockTinaData } from '@/data/blocks/stretch';
import { mockSummaryNumbersMockData } from '@/data/blocks/summaryNumbers';
import { mockTableBlockTina } from '@/data/blocks/table';
import { mockTariffCompareBlockData } from '@/data/blocks/tariffCompare';
import { mockTariffDocumentsArchiveTina } from '@/data/blocks/tariffDocumentsArchive';
import { mockTariffInfoBlockDataTina } from '@/data/blocks/tariffInfo';
import { mockTariffTabsTinaData } from '@/data/blocks/tariffTabs';
import { mockDocumentsBlockDataTina } from '@/data/blocks/tariffTabs/documents';
import { mockHelpBlockDataTina } from '@/data/blocks/tariffTabs/help';
import { mockTabTariffBlockDataTina } from '@/data/blocks/tariffTabs/tariff';
import { mockTextBlockDataTina } from '@/data/blocks/textBlock';
import { mockThreeInARowBlockDataTina } from '@/data/blocks/threeInARow';
import { mockUniversalDetailBlockDataTina } from '@/data/blocks/universalDetail';
import { mockVacancyBlockDataTina } from '@/data/blocks/vacancy';
import { mockYoutubeBlockDataTina } from '@/data/blocks/youtube';
import { mockYouTubeVideosBlockDataTina } from '@/data/blocks/youTubeVideos';
import { mockInstructionSliderBlockDataTina } from '@/data/instructionSlider';
import { mockTariffsRestrictionsBlockTinaData } from '@/data/tariffsRestrictions';

// Конфиг используется для блока section, чтобы брать дефолтные варианты при создании новых секций

export const mockDataConfig = {
    animatedPromo: mockAnimatedPromoBlockTinaData,
    stretch: mockStretchBlockTinaData,
    mainSlider: mockMainSliderBlockTinaData,
    info: mockInfoBlockTinaData,
    services: mockServicesBlockTinaData,
    news: mockNewsBlockDataTina,
    links: mockLinksBlockTinaData,
    promo: mockPromoBlockDataTina,
    advantages: mockAdvantagesBlockTinaData,
    instruction: mockInstructionBlockTinaData,
    tariffTabs: mockTariffTabsTinaData,
    linkingList: mockLinkingListBlockDataTina,
    citiesList: mockLinkingListBlockDataTina,
    creditCardsList: mockCardsBlockDataTina,
    infoCard: mockInfoCardBlockTina,
    cashback: mockCashbackBlockTinaData,
    partners: mockPartnersBlockDataTina,
    periodInfo: mockPeriodInfoBlockTinaData,
    infoBgBlock: mockInfoBgBlockBlockDataTina,
    creditGiftBlock: mockCreditGiftBlockBlockDataTina,
    documents: mockDocumentsBlockDataTina,
    help: mockHelpBlockDataTina,
    tariffBlocks: mockTabTariffBlockDataTina,
    tariff: mockTabTariffBlockDataTina,
    safely: mockSafelyBlockTinaData,
    questions: mockQuestionsBlockDataTina,
    requisites: mockRequisitesBlockDataTina,
    groupedTariffs: mockTariffBlockDataTina,
    tariffInfo: mockTariffInfoBlockDataTina,
    offersCredits: mockOffersCreditsBlockDataTina,
    textBlock: mockTextBlockDataTina,
    faq: mockFaqBlockDataTina,
    popularExchangeRates: mockPopularExchangeRatesBlockDataTina,
    allExchangeRates: mockAllExchangeRatesBlockDataTina,
    possibilities: mockPossibilitiesBlockTinaData,
    managementInfo: mockManagementInfoBlockTinaData,
    singleDocuments: mockSingleDocumentsBlockDataTina,
    table: mockTableBlockTina,
    aboutPB: mockAboutPBBlockTinaData,
    parametersBonds: mockParametersBondsBlockTinaData,
    tariffsRestrictions: mockTariffsRestrictionsBlockTinaData,
    mobileApp: mockMobileAppBlockTinaData,
    feedbackForm: mockFeedbackFormBlockDataTina,
    example: mockExampleBlockDataTina,
    payPlatforms: mockPayPlatformsDataTina,
    introPromo: mockIntroPromoDataTina,
    ourTargets: mockOurTargetsMockData,
    management: mockManagementMockData,
    summaryNumbers: mockSummaryNumbersMockData,
    quotesCarousel: mockQuotesCarouselMockData,
    headManagement: mockHeadManagementBlockDataTina,
    informationCenter: mockInformationCenterBlockDataTina,
    insurance: mockInsuranceBlockDataTina,
    indication: mockIndicationBlockDataTina,
    bankAdvantages: mockBankAdvantagesBlockDataTina,
    applicationApps: mockApplicationAppsBlockDataTina,
    youtube: mockYoutubeBlockDataTina,
    photoSlider: mockPhotoSliderDataTina,
    quoteHr: mockQuoteHrBlockDataTina,
    vacancy: mockVacancyBlockDataTina,
    bankText: mockBankTextBlockDataTina,
    social: mockSocialsBlockDataTina,
    creditSlider: mockCreditSliderBlockDataTina,
    instructionSlider: mockInstructionSliderBlockDataTina,
    hrFaq: mockHrFaqBlockDataTina,
    threeInARow: mockThreeInARowBlockDataTina,
    // Составные части блока services
    mobileService: mockServicesBlockTinaData.mobile,
    bankService: mockServicesBlockTinaData.bank,
    text: { text: 'Текст' },
    tariffDocumentsArchive: mockTariffDocumentsArchiveTina,
    mediaPublications: mockMediaPublicationsDataTina,
    biography: mockBiographyBlockDataTina,
    dictionary: mockDictionaryBlockDataTina,
    purchases: mockPurchasesBlockDataTina,
    sidebarDocs: mockSidebarDocsBlockDataTina,
    youTubeVideos: mockYouTubeVideosBlockDataTina,
    universalDetail: mockUniversalDetailBlockDataTina,
    buildingCalculatorWithPicture,
    buildingCalculator,
    partnersMap: mockPartnersMapDataTina,
    articlesDetail: mockArticlesDetailBlockTinaData,
    mainArticlesSlider: mockMainArticlesSliderBlockTinaData,
    articlesListForDetail: mockArticlesListForDetail,
    crmOffersLinkBlock: mockCrmOffersLinkBlockTinaData,
    tariffCompare: mockTariffCompareBlockData,
    selectableWithValue: mockSelectableDataTina,
    diagram: mockDiagramBlockTinaData,
    lightBanner: mockLightBannerBlockDataTina,
    darkBanner: mockDarkBannerBlockDataTina,
    stepSelection: mockStepSelectionBlockDataTina,
    cashCalculator: mockCashCreditCalculatorData,
    cashCalculatorV2: mockCashCreditCalculatorV2Data,
    coloredBanner: mockColoredBannerBlockDataTina,
    promov2: mockPromoV2DataTina,
    creditRealEstateCalculator: mockCreditRealEstateCalculatorData,
    moneyBoxCalculator: moneyBoxCalculatorData,
    moneyBoxAccountCalculator: moneyBoxAccountCalculatorData,
    promoPension: mockPromoPensionBlockData,
    specialAdvantages: mockSpecialAdvantagesBlockData,
    actionPensions: mockActionPensionsBlockDataTina,
    infoBlockPensiya: mockInfoBlockPensiyaBlockDataTina,
    campaignBlock: mockCampaignBlockBlockTinaData,
    feedbackFormVTwo: mockFeedbackFormBlockDataTina,
    infoTransitionBlock: mockInfoTransitionBlockTinaData,
    instructionInfoGraphic: mockInstructionInfoGraphicBlockTinaData,
    rightButtonText: mockRightButtonTextBlockDataTina,
    pboFaqDetail: mockPboFaqDetailTinaData,
    persons: mockPersonsBlockDataTina,
    profitabilityCalculatorHotSeasons: profitabilityCalculatorHotSeasonsNewYear,
    profitabilityCalculatorCapital,
    profitabilityCalculatorProfit,
    profitabilityCalculatorPension,
    profitabilityCalculatorCumulative,
    profitabilityMonthCalculator: profitabilityCalculatorHotSeasons,
    photoDateBlock: mockPhotoDateBlockBlockDataTina,
    promoSuper: mockPromoSuperBlockData,
    guaranteedPrizes: mockGuaranteedPrizesBlockDataTina,
    guaranteeCalculator,
    mainPrizes: mockMainPrizes,
    corgis: mockCorgisBlockData,
    drawParticipationList: mockParticipationList,
};
