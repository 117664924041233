import { mockPageParamsData } from '@/data/pageParams';
import { gtagSingleItem } from '@/tina/utils/getGTAGConfig';

export const pageParamsConfig = {
    name: 'pageParams',
    label: 'Дополнительные параметры',
    component: 'group',
    fields: [
        {
            component: 'toggle',
            name: 'isBusinessPage',
            label: 'Бизнес страница',
        },
        {
            component: 'toggle',
            name: 'isPWAHidden',
            label: 'Скрытое PWA меню',
        },
        {
            component: 'toggle',
            name: 'isFlocktory',
            label: 'Блок Flocktory',
        },
        {
            name: 'preloaderPage',
            label: 'Прелоадер страницы',
            component: 'group',
            fields: [
                {
                    component: 'toggle',
                    name: 'isActive',
                    label: 'Включить прелоадер',
                },
                {
                    component: 'number',
                    name: 'timeout',
                    label: 'Время задержки (мс.) Минимальное время 2000мс',
                    description: 'Если поле пустое, значение по умолчанию - 2000мс',
                },
            ],
        },
        {
            component: 'group',
            name: 'darkTheme',
            label: 'Настройки темной темы',
            fields: [
                {
                    component: 'toggle',
                    name: 'isDarkThemeEnabled',
                    label: 'Включить темную тему по времени',
                },
                {
                    component: 'timePicker',
                    name: 'darkThemeStart',
                    label: 'Время включения темной темы',
                    ui: {
                        timeFormat: 'HH:mm',
                    },
                },
                {
                    component: 'timePicker',
                    name: 'darkThemeEnd',
                    label: 'Время выключения темной темы',
                    ui: {
                        timeFormat: 'HH:mm',
                    },
                },
            ],
        },
        {
            name: 'productPanel',
            label: 'Продуктовая панель',
            component: 'group',
            fields: [
                {
                    component: 'toggle',
                    name: 'isActive',
                    label: 'Включить продуктовую панель',
                },
                {
                    label: 'Список элементов',
                    name: 'menuTop',
                    component: 'group-list',
                    itemProps: (item: { name: string }, index: number) => ({
                        key: index,
                        label: item.name,
                    }),
                    defaultItem: () => mockPageParamsData.productPanel.menuTop[0],
                    fields: [
                        {
                            component: 'text',
                            name: 'name',
                            label: 'Название',
                        },
                        {
                            component: 'text',
                            name: 'link',
                            label: 'Ссылка для привязки к блоку на странице',
                        },
                        { ...gtagSingleItem },
                    ],
                },
                {
                    name: 'button',
                    label: 'Кнопка',
                    component: 'group',
                    fields: [
                        {
                            component: 'text',
                            name: 'name',
                            label: 'Название',
                        },
                        {
                            component: 'text',
                            name: 'link',
                            label: 'Ссылка',
                        },
                    ],
                },
            ],
        },
        {
            name: 'antiFlicker',
            label: 'Антифликер',
            component: 'group',
            fields: [
                {
                    component: 'toggle',
                    name: 'isActive',
                    label: 'Включить антифликер',
                },
                {
                    component: 'number',
                    name: 'timeout',
                    label: 'Время действия (мс.)',
                    description: 'Если поле пустое, значение по умолчанию - 1000мс',
                },
            ],
        },
        {
            name: 'header',
            label: 'Шапка. Текущая страница.',
            component: 'group',
            fields: [
                {
                    name: 'isStatic',
                    component: 'toggle',
                    label: 'Включить статичную шапку',
                },
                {
                    name: 'isDisabled',
                    component: 'toggle',
                    label: 'Выключить шапку',
                },
                {
                    name: 'liteHeaderDesktop',
                    component: 'toggle',
                    label: 'Включить лайт хедер на десктопе',
                },
                {
                    name: 'liteHeaderMobile',
                    component: 'toggle',
                    label: 'Включить лайт хедер на моб. версии',
                },
                {
                    name: 'withTopPanel',
                    component: 'toggle',
                    label: 'Добавить верхнюю панель (выбор города)',
                },
                {
                    name: 'liteHeaderButton',
                    component: 'group',
                    label: 'Кнопка для лайт версии',
                    fields: [
                        {
                            component: 'text',
                            name: 'title',
                            label: 'Текст кнопки',
                        },
                        {
                            component: 'text',
                            name: 'link',
                            label: 'Ссылка',
                        },
                    ],
                },
            ],
        },
        {
            name: 'footer',
            label: 'Футер. Текущая страница.',
            component: 'group',
            fields: [
                {
                    name: 'isDisabled',
                    component: 'toggle',
                    label: 'Выключить футер',
                },
                {
                    name: 'liteActiveCode',
                    component: 'text',
                    label: 'Привязка упрощенного подвала',
                },
            ],
        },
    ],
};
