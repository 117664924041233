import { tabsBlock } from '@/tina/blocks/tabs';
import { tinaBlocksConfig } from '@/tina/config/index.tina.config';
import { sidebarBlocksConfigTina } from '@/tina/config/sidebar/sidebar.tina.config';
import { breadcrumbsConfig } from '@/tina/groups/breadcrumbs/index.tina';
import { footerConfig } from '@/tina/groups/footer/index.tina';
import { gaParamsConfig } from '@/tina/groups/gaParams/index.tina';
import { headerConfig } from '@/tina/groups/header/index.tina';
import { metaConfig } from '@/tina/groups/meta/index.tina';
import { pageParamsConfig } from '@/tina/groups/pageParams/index.tina';
import { getTemplates } from '@/tina/utils/getTemplates';
import { updatePageValues } from '@/tina/utils/updatePageValues';
import { IData } from '@/types/data';

const setTinaConfig = (label: string, data: unknown, path?: string, cms?: unknown) => ({
    id: 'tina-main',
    label,
    initialValues: data,
    fields: [
        { ...headerConfig },
        { ...metaConfig },
        { ...breadcrumbsConfig },
        { ...pageParamsConfig },
        { ...gaParamsConfig },
        {
            label: 'Блоки страницы',
            name: 'blocks',
            component: 'blocks',
            templates: getTemplates({ ...tinaBlocksConfig, tabs: tabsBlock }),
        },
        {
            label: 'Сайдбар',
            name: 'sidebar',
            component: 'group',
            fields: [
                {
                    label: 'Выключить/Включить',
                    component: 'toggle',
                    name: 'isActive',
                },
                {
                    label: 'Блоки сайдбара',
                    name: 'blocks',
                    component: 'blocks',
                    templates: getTemplates(sidebarBlocksConfigTina),
                },
            ],
        },
        { ...footerConfig },
    ],
    onSubmit: async (values: IData) => {
        await updatePageValues(values, path, cms, data);
    },
});

export default setTinaConfig;
