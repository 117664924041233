/* eslint-disable */
// @ts-nocheck
// Пока что просто заготовка для полей с зависимостью, с типами разобраться позже

import React, { useMemo } from 'react';

import { useTinaLibStore } from '@/hooks/useTina';
import { FieldPlugin } from 'tinacms';

export const getParentField = (fieldName: string, form) => {
    const { values } = form.getState();
    const fieldNameParts = fieldName.split('.');

    fieldNameParts.splice(-1);
    return fieldNameParts.reduce((acc, part) => acc[part], values);
};

export const ConditionalField = ({ form, tinaForm, input, field }: any) => {
    const { Tina } = useTinaLibStore();

    const { FieldsBuilder } = Tina;

    const parentField = getParentField(input.name, form);
    const conditionalField = useMemo(() => {
        let fieldName = field.name;

        if (field.name && field.field?.name) {
            fieldName = [field.name, field.field.name].join('.');
        } else if (field.name.length <= 0 && field.field?.name) {
            return field.field.name;
        }

        return {
            ...field.field,
            name: fieldName,
        };
    }, [JSON.stringify(field)]);

    const shouldShowFields = field.condition(input.value, parentField, input, tinaForm);

    if (shouldShowFields) {
        return (
            <div style={{ margin: '-20px -20px 0 -20px' }}>
                <FieldsBuilder form={tinaForm} fields={[conditionalField]} />
            </div>
        );
    }

    return null;
};

export const ConditionalFieldPlugin: FieldPlugin = {
    __type: 'field',
    name: 'condition',
    Component: ConditionalField,
};

// Пример использования
//
// fields: [
//     {
//         name: 'title',
//         component: 'text',
//         label: 'Заголовок',
//     },
//     {
//         name: 'string',
//         label: 'string',
//         component: 'condition',
//         condition: (value, values) => !!values.title,
//         field: { component: 'text' },
//     },
// ];
