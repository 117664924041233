import { newsSubscriptionBlock } from '@/tina/blocks/sidebar/newsSubscription';
import { rssSubscriptionBlock } from '@/tina/blocks/sidebar/rssSubscription';
import { sidebarAnchorLinksBlock } from '@/tina/blocks/sidebar/sidebarAnchorLinks';
import { sidebarAuthorBlock } from '@/tina/blocks/sidebar/sidebarAuthor';
import { sidebarInfoBlock } from '@/tina/blocks/sidebar/sidebarInfo';
import { sidebarButtonBlock } from '@/tina/blocks/sidebar/sudebarButton';
import { sidebarDocsBlock } from '@/tina/blocks/sidebar/sudebarDocs';
import { ITinaBlocksConfig } from '@/types/tina/config';

export const sidebarBlocksConfigTina: ITinaBlocksConfig = {
    newsSubscription: newsSubscriptionBlock,
    rssSubscription: rssSubscriptionBlock,
    sidebarInfo: sidebarInfoBlock,
    sidebarButton: sidebarButtonBlock,
    sidebarDocs: sidebarDocsBlock,
    sidebarAuthor: sidebarAuthorBlock,
    sidebarAnchorLinks: sidebarAnchorLinksBlock,
};
