import React from 'react';

import { TinaSidebarInfo } from '@/components/blocks/sidebar/SidebarInfo/index.tina';
import { mockSidebarInfoBlockDataTina } from '@/data/blocks/sidebar/sidebarInfo';
import HtmlEditorFeatured from '@/tina/plugins/htmlEditorFeatured';

export const sidebarInfoBlock = {
    Component: props => <TinaSidebarInfo {...props} />,
    template: {
        label: 'Информационный блок',
        defaultItem: () => mockSidebarInfoBlockDataTina,
        fields: [
            {
                name: 'title',
                component: 'textarea',
                label: 'Заголовок',
            },
            {
                name: 'desc',
                component: HtmlEditorFeatured,
                label: 'Описание',
            },
            {
                name: 'phone',
                component: 'text',
                label: 'Телефон',
            },
            {
                name: 'phoneLink',
                component: 'text',
                label: 'Телефонная ссылка',
            },
            {
                name: 'mail',
                component: 'text',
                label: 'Эл. почта',
            },
            {
                name: 'annotation',
                component: 'textarea',
                label: 'Дополнительное описание',
            },
        ],
    },
};
