import React from 'react';

import { TinaTabsBlock } from '@/components/blocks/tabsBlock/TabsBlock/index.tina';
import { tinaBlocksConfig } from '@/tina/config/index.tina.config';
import HtmlEditorFeatured from '@/tina/plugins/htmlEditorFeatured';
import { gtagSingleItem } from '@/tina/utils/getGTAGConfig';
import { getTemplates } from '@/tina/utils/getTemplates';

export const tabsBlock = {
    Component: props => <TinaTabsBlock {...props} />,
    template: {
        label: 'Табы',
        defaultItem: () => ({ tabs: [], archive: { text: '', link: '' }, isCentered: false }),
        fields: [
            {
                label: 'Заголовок',
                component: HtmlEditorFeatured,
                name: 'title',
            },
            {
                label: 'Список табов',
                component: 'group-list',
                name: 'tabs',
                itemProps: (item: { tabTitle: string }, index: number) => ({
                    key: index,
                    label: item.tabTitle,
                }),
                fields: [
                    {
                        label: 'Заголовок в табе',
                        component: 'text',
                        name: 'tabTitle',
                    },
                    {
                        label: 'Название гет параметра для таба',
                        component: 'text',
                        name: 'getParamTitle',
                    },
                    {
                        label: 'Выключить/включить во всю ширину',
                        component: 'toggle',
                        name: 'isFullWidth',
                    },
                    {
                        ...gtagSingleItem,
                    },
                    {
                        label: 'Список табов',
                        component: 'blocks',
                        name: 'blocks',
                        templates: getTemplates(tinaBlocksConfig),
                    },
                ],
            },
            {
                component: 'select',
                name: 'type',
                label: 'Тип',
                options: [
                    {
                        value: 'main',
                        label: 'Основной',
                    },
                    {
                        value: 'secondary',
                        label: 'Дополнительный',
                    },
                ],
            },
            {
                name: 'isCentered',
                component: 'toggle',
                label: 'Отцентрировать табы',
            },
            {
                label: 'Архив',
                component: 'group',
                name: 'archive',
                fields: [
                    {
                        label: 'Заголовок',
                        component: 'text',
                        name: 'title',
                    },
                    {
                        label: 'Ссылка',
                        component: 'text',
                        name: 'link',
                    },
                    { ...gtagSingleItem },
                ],
            },
        ],
    },
};
