import clsx from 'clsx';
import React, { useContext } from 'react';

// eslint-disable-next-line import/no-cycle
import Block from '@/components/layout/Block';
import TabContext from '@/context/TabContext';
import { ITabGroup } from '@/types/tabsBlock';

import cn from './style.module.sass';

interface IProps {
    tabs: ITabGroup[];
}

const DefaultLayout: React.FC<IProps> = ({ tabs }) => {
    const { activeTab } = useContext(TabContext);

    return (
        <div className={cn.wrapper}>
            {tabs?.map((tab, index) => (
                <div key={`${index}-${tab.tabTitle}`} className={clsx(cn.block, activeTab !== index && cn.hide)}>
                    {tab?.blocks?.map((block, indexInner) => (
                        <Block block={block} key={block?.nanoId || indexInner} index={index} />
                    ))}
                </div>
            ))}
        </div>
    );
};

export default DefaultLayout;
