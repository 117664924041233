import { InputProps, wrapFieldsWithMeta } from '@tinacms/fields';
import * as React from 'react';
import { useContext, useState } from 'react';

import { LoadingDots } from '@/components/UI/icons/tina/LoadingDots';
import MediaStoreContext from '@/context/MediaStoreContext';
import { useIsomorphicEffect } from '@/hooks/useIsomorphicEffect';
import { useTinaLibStore } from '@/hooks/useTina';
import { useTinaPreviewSrc } from '@/hooks/useTinaPreviewSrc';
import { MediaFieldWrapper } from '@/tina/plugins/mediaManager/MediaFieldWrapper';
import { IMediaItem } from '@/types/tina/media/item';
import { IMediaStore } from '@/types/tina/media/store';

import cn from './style.module.sass';

interface IWebmUploadProps {
    onDrop: (acceptedFiles: any[]) => void;
    onClear?: () => void;
    onClick?: () => void;
    value?: string;
    previewSrc?: string;
    loading?: boolean;
}

export const WebmUpload: React.FC<IWebmUploadProps> = ({ onDrop, onClick, value, previewSrc, loading }) => {
    const [showVideo, setShowVideo] = useState(false);

    useIsomorphicEffect(() => {
        const obj = document.createElement('video');
        const canPlayWebm = obj.canPlayType('video/webm');
        setShowVideo(canPlayWebm === 'probably' || canPlayWebm === 'maybe');
    }, []);

    if (loading) {
        return (
            <MediaFieldWrapper onClick={onClick} onDrop={onDrop} accept="image/*">
                <div className={cn.styledImageContainer}>
                    <div className={cn.imgLoaderWrapper}>
                        <LoadingDots />
                    </div>
                </div>
            </MediaFieldWrapper>
        );
    }

    if (value) {
        return (
            <MediaFieldWrapper onClick={onClick} onDrop={onDrop} accept="video/webm">
                <div className={cn.styledImageContainer}>
                    {showVideo && (
                        <video
                            key={previewSrc}
                            muted
                            loop
                            autoPlay
                            controls={false}
                            crossOrigin="anonymous"
                            preload="none"
                            className="animatedPromoImageVideo"
                        >
                            <source src={previewSrc} />
                        </video>
                    )}
                </div>
            </MediaFieldWrapper>
        );
    }

    return (
        <MediaFieldWrapper onClick={onClick} onDrop={onDrop} accept="video/webm">
            <div className={cn.styledImageContainer}>
                <div className={cn.imgPlaceholder}>
                    Drag 'n' drop a file here,
                    <br />
                    or click to select a file
                </div>
            </div>
        </MediaFieldWrapper>
    );
};

interface IWebmField {
    path: string;
    previewSrc?: IMediaStore['previewSrc'];
    uploadDir?: string;
}

export const WebmField = wrapFieldsWithMeta<InputProps, IWebmField>(props => {
    const { persist } = useContext(MediaStoreContext);
    const { Tina } = useTinaLibStore();

    const { useCMS } = Tina;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const { form, field } = props;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    const { name, value } = props.input as { name: string; value: string };

    const cms = useCMS();

    const [isUploading, setIsUploading] = useState(false);

    const [src, srcIsLoading] = useTinaPreviewSrc(
        value,
        name,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
        form.getState().values,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        field.previewSrc
    );

    const onChange = (media?: IMediaItem | IMediaItem[]) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
        if (media) props.input.onChange(media);
    };

    const onDrop = async ([file]: File[]) => {
        setIsUploading(true);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
        const directory = props.field.uploadDir;
        const [media] = await persist([
            {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                directory,
                file,
            },
        ]);

        if (media) {
            try {
                onChange(media);
            } catch (error) {
                console.error('Ошибка при попытке загрузить изображение: ', error);
            } finally {
                setIsUploading(false);
            }
        }
    };

    const onClick = () => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
        const directory = props.field.uploadDir;

        cms.events.dispatch({
            type: 'customMedia:open',
            allowDelete: true,
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            directory,
            onSelect: onChange,
            extension: 'webm',
        });
    };

    return (
        <WebmUpload
            value={value}
            previewSrc={src}
            loading={isUploading || srcIsLoading}
            onClick={onClick}
            onDrop={onDrop}
        />
    );
});

export const WebmFieldPlugin = {
    __type: 'field',
    name: 'webm',
    Component: WebmField,
    parse: (value?: string) => value || '',
};
