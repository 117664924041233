/* eslint-disable react/jsx-props-no-spreading */
// eslint-disable-next-line import/no-extraneous-dependencies
import React from 'react';
import { FieldPlugin, wrapFieldsWithMeta } from 'tinacms';

import { CustomFieldConfig } from '@/types/tina/fields';

import cn from './style.module.sass';

const TimePicker = wrapFieldsWithMeta(({ field, input, meta }: CustomFieldConfig) => (
    <div className={cn.wrapper}>
        <input
            type="time"
            id={field.name}
            name={field.name}
            className={cn.input}
            min="00:00"
            max="24:00"
            required
            value={input.value as string}
            onChange={input.onChange}
        />
    </div>
));

export const TimeFieldPlugin: FieldPlugin = {
    __type: 'field',
    name: 'timePicker',
    Component: TimePicker,
};
